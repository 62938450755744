import React, { Component } from "react";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";

import ProjectsDescription from "./ProjectsDescription";

import { ProjectParticipant } from "./models";
import { connect } from "react-redux";

// CSS in JS styles
const styles = theme => ({
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    centerAlign: {
        textAlign: "center"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    }
});

const allProjectParticipants = ProjectParticipant.selectAll(PU => ({
    project_attributes: PU.project,
    ...PU.ref
}));

const columnData = [
    { id: "name", numeric: false, label: "Project", allowSort: true, width: "25%" },
    { id: "project_sponsor", numeric: false, label: "Sponsor", allowSort: true, width: "30%" },
    { id: "type_label", numeric: false, label: "Project Type", allowSort: true, width: "15%" },
    { id: "crops_label", numeric: false, label: "Crops Analyzed", allowSort: true, width: "20%" },
    { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "10%" }
];

class ProjectLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        /*this.props.projects.sort(
            (a, b) => (a["project_attributes"]["name"] < b["project_attributes"]["name"] ? -1 : 1)
        );*/
        this.state = {
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 10
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.projects.sort((a, b) =>
                  b["project_attributes"][orderBy] < a["project_attributes"][orderBy] ? -1 : 1
              )
            : this.props.projects.sort((a, b) =>
                  a["project_attributes"][orderBy] < b["project_attributes"][orderBy] ? -1 : 1
              );

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { projects, classes } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, projects.length - page * rowsPerPage);

        projects.map(function(pu) {
            if (!pu.project_attributes) return pu;
            pu.project_attributes.project_sponsor = "";
            pu.project_attributes.project_organization.forEach(function(po) {
                if (po.organization_role === "PS")
                    pu.project_attributes.project_sponsor += po.organization_label + ", ";
            });
            pu.project_attributes.project_sponsor = pu.project_attributes.project_sponsor.substring(
                0,
                pu.project_attributes.project_sponsor.length - 2
            );

            return pu;
        });

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {projects
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(({ project_attributes }) =>
                            !project_attributes ? null : (
                                <TableRow hover key={project_attributes.id}>
                                    <CustomTableCell>{project_attributes.name}</CustomTableCell>
                                    <CustomTableCell>{project_attributes.project_sponsor}</CustomTableCell>
                                    <CustomTableCell>{project_attributes.type_label}</CustomTableCell>
                                    <CustomTableCell>{project_attributes.crops_label.join(", ")}</CustomTableCell>
                                    <CustomTableCell>
                                        <Tooltip title="View Project Details">
                                            <Button
                                                component={Link}
                                                to={"/projectadmin/" + project_attributes.id}
                                                className={classes.buttonWidth}>
                                                <RemoveRedEyeIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                </TableRow>
                            )
                        )}
                    {emptyRows > 0 && projects.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={4} />
                        </TableRow>
                    )}
                    {projects.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                No Projects Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {projects.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={4}
                                count={projects.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

class AdminDashboard extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        var { classes, projects } = this.props;

        return (
            <AppContainer authenticated color="project" title="Projects">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <ProjectsDescription />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="display3">Projects</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectLibrary projects={projects} classes={classes} />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

AdminDashboard = connect(
    state => ({
        projects: allProjectParticipants(state)
    }),
    {}
)(AdminDashboard);

export default withStyles(styles)(AdminDashboard);
