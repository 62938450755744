import React, { Component } from "react";
import { Form } from "react-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "../../common/ButtonWrapper";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import Grid from "../../common/GridWrapper";
import IconButton from "@mui/material/IconButton";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import StepIcon from "@mui/material/StepIcon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "../../common/TypographyWrapper";
import Paper from "@mui/material/Paper";
import Close from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import withStyles from "@mui/styles/withStyles";

import { CROP_YEAR_COMPLETED } from "./models";
import WarningDialog from "../../common/WarningDialog";
import * as navActions from "../../common/actions";
import Select from "../../common/Select";
import TextField from "../../common/TextField";
import CheckboxGroup from "../../common/CheckboxGroup";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    gutterTop: {
        marginTop: ".6em"
    },
    listItem: {
        paddingTop: 20,
        paddingBottom: 20,
        borderTop: "1px solid #DDDDDD",
        borderBottom: "1px solid #DDDDDD",
        "& svg": {
            color: theme.palette.primary.main // + " !important",
        }
    },
    linkColor: {
        color: theme.palette.primary.main
    },
    inline: {
        display: "inline",
        textAlign: "left"
    },
    titleContainer: {
        backgroundColor: "#ff7d32",
        textAlign: "center"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    dialogMin: {
        minWidth: "400px"
    },
    questionTitle: {
        color: "#fff",
        fontWeight: 500,
        float: "left"
    },
    button: {
        float: "right",
        marginTop: 24,
        marginRight: 24
    },
    marginTopTwo: {
        marginTop: 24
    },
    cancelBtn: {
        border: "1px solid",
        marginRight: 24
    },
    paper: {
        padding: 12,
        paddingBottom: 24,
        marginLeft: 40
    },
    warningIcon: {
        float: "left",
        marginTop: -5,
        marginRight: 6
    }
});

class Review extends Component {
    state = {
        helpOpen: true,
        helpOpen2: false,
        moveDialogOpen: false,
        moveDialogOpen2: false
    };

    componentDidMount() {
        //this.props.onRef(this.saveOperations);
        window.scrollTo(0, 0);
    }

    /*componentWillUnmount() {
        this.props.onRef(undefined);
    }*/

    checkNone = (values, path, data) => {
        if (values.length === 1 && values[0] === "none") {
            //  do nothing
        } else if (values.length > 0 && values[0] === "none") {
            values.shift();
        } else if (values.length > 0 && values[values.length - 1] === "none") {
            values = ["none"];
        }

        this.form.setValue(path, values);
    };

    finalize(is_final) {
        const { id, year } = this.props.cropYear;

        // No future years can be finalized
        if (parseInt(year) > new Date().getFullYear()) {
            this.setState({ helpOpen2: true });
            return;
        }

        if (is_final) {
            this.props.ormCropYearUpdate({
                id: id,
                is_final: is_final
            });
            this.setState({ moveDialogOpen: true });
        } else {
            this.setState({ moveDialogOpen2: true });
        }
    }

    saveSurvey(values) {
        const { id, project } = this.props.cropYear;
        values = {
            project: {
                ...project,
                ...values.project
            }
        };
        this.props.ormCropYearUpdate({
            id: id,
            ...values
        });
    }

    render() {
        const { classes, cropYear, fieldId, navSelectPage, handleStep } = this.props;
        const { helpOpen, helpOpen2, moveDialogOpen, moveDialogOpen2 } = this.state;

        const m = getValue(cropYear, "extrainfo.missing");
        const isMissing = CROP_YEAR_COMPLETED(cropYear);
        const isFinal = CROP_YEAR_COMPLETED(cropYear, 3);

        const showMondelezQuestions =
                cropYear.project &&
                cropYear.project.project_has_survey &&
                cropYear.project.project_crop_ids &&
                cropYear.project.project_crop_ids.some(id => id === cropYear.crop),
            incompleteSurvey = showMondelezQuestions && !cropYear.project.survey;

        const seedVarietyOptions = [
            { label: "", value: "" },
            { label: "25W31", value: "25W31" },
            { label: "25W43", value: "25W43" },
            { label: "Ambassador", value: "Ambassador" },
            { label: "DG9242W", value: "DG9242W" },
            { label: "Jupiter", value: "Jupiter" },
            { label: "SY1062", value: "SY1062" },
            { label: "SY901", value: "SY901" },
            { label: "Whitetail", value: "Whitetail" },
            { label: "Other", value: "Other" }
        ];

        const variableRateOptions = [
            { label: "Seeding", value: "seeding" },
            { label: "Nitrogen Application", value: "nitrogen" },
            { label: "None", value: "none" }
        ];

        return !isMissing ? (
            <Grid container spacing={16}>
                <WarningDialog
                    noActions
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    title="Missing Data Required for Submission"
                    text={
                        <div>
                            <Typography gutterBottom>We're missing some data in the following sections:</Typography>
                            {m[0] > 0 && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(0, false)}>
                                    Rotation >
                                </Button>
                            )}
                            {m[1] > 0 && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}>
                                    Management >
                                </Button>
                            )}
                            {m[2] > 0 && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(2, false)}>
                                    Operations >
                                </Button>
                            )}
                            <Typography gutterBottom className={classes.gutterTop}>
                                Please review these sections and supply any missing information. Fields that are missing
                                data are marked with a red outline.
                            </Typography>
                            <Typography>
                                If you need any assistance, please email us at{" "}
                                <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                    support@fieldtomarket.org
                                </a>
                            </Typography>
                        </div>
                    }
                />
                <Grid item xs={8}>
                    <Typography gutterBottom variant="display3">
                        Review Missing Data
                    </Typography>
                    <Typography gutterBottom>
                        All required inputs must be entered before the Fieldprint Analysis can be generated. Please
                        review the sections below to identify the missing data inputs.
                    </Typography>
                    {m[0] > 0 && (
                        <ListItem
                            title="Rotation"
                            button
                            className={classes.listItem}
                            onClick={() => handleStep(0, false)}>
                            <ListItemIcon>
                                <StepIcon icon="1" />
                            </ListItemIcon>
                            <ListItemText inset disableTypography>
                                <Typography variant="title">
                                    Rotation <i>(Incomplete)</i>
                                </Typography>
                            </ListItemText>
                            <ChevronRightIcon />
                        </ListItem>
                    )}
                    {m[1] > 0 && (
                        <ListItem
                            title="Management"
                            button
                            className={classes.listItem}
                            onClick={() => handleStep(1, false)}>
                            <ListItemIcon>
                                <StepIcon icon="2" />
                            </ListItemIcon>
                            <ListItemText inset disableTypography>
                                <Typography variant="title">
                                    Management <i>(Incomplete)</i>
                                </Typography>
                            </ListItemText>
                            <ChevronRightIcon />
                        </ListItem>
                    )}
                    {m[2] > 0 && (
                        <ListItem
                            title="Operations"
                            button
                            className={classes.listItem}
                            onClick={() => handleStep(2, false)}>
                            <ListItemIcon>
                                <StepIcon icon="3" />
                            </ListItemIcon>
                            <ListItemText inset disableTypography>
                                <Typography variant="title">
                                    Operations <i>(Incomplete)</i>
                                </Typography>
                            </ListItemText>
                            <ChevronRightIcon />
                        </ListItem>
                    )}
                    <Typography gutterBottom variant="display3" className={classes.gutterTop}>
                        Next Steps
                    </Typography>
                    <Typography variant="title">Return to Field Dashboard</Typography>
                    <Typography gutterBottom>
                        Return to the dashboard for this field to manage other crop years.
                        <Button color="primary" component={Link} to={"/field/" + fieldId}>
                            Go Now >
                        </Button>
                    </Typography>
                    <Typography variant="title">Associate Field with a Project</Typography>
                    <Typography gutterBottom>
                        If you are already part of a Project or if you have been invited to join a Project, manage these
                        field associations from the Project page.
                        <Button color="primary" onClick={() => navSelectPage("project")} component={Link} to="/project">
                            Go Now >
                        </Button>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography gutterBottom variant="display3">
                        Help
                    </Typography>
                    <Typography gutterBottom>
                        <b>What if I am not ready to complete all data required data inputs? </b>
                        <br />
                        You can return to the data input pages for this crop year when you have the required data
                        inputs. Not that you cannot generate a Fieldprint Analysis until all required inputs are
                        entered.
                    </Typography>
                    <Typography gutterBottom>
                        <b>What if I don’t have specific required data inputs?</b>
                        <br />
                        It is important to provide accurate information needed to generate your Fieldprint Analysis. If
                        you do not have specific information, please &nbsp;
                        <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                            contact us
                        </a>
                        &nbsp; for assistance.
                    </Typography>
                </Grid>
            </Grid>
        ) : (
            <Grid container spacing={16}>
                <WarningDialog
                    noActions
                    open={helpOpen2}
                    onClose={() => this.setState({ helpOpen2: false })}
                    title="Future Crop Year"
                    text="You cannot finalize a future crop year."
                />
                <Dialog open={moveDialogOpen} classes={{ paper: classes.dialogMin }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Typography variant="title" className={classes.questionTitle}>
                            Generate Fieldprint Analysis?
                        </Typography>
                        <IconButton
                            onClick={() => this.setState({ moveDialogOpen: false })}
                            className={classes.closeIcon}
                            size="large">
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.marginTopTwo}>
                            <Typography gutterBottom>
                                Would you like to generate your Fieldprint Analysis? This will take you to the
                                Fieldprint Analysis page where you can view your spidergram and detailed metrics
                                results. You can do this now or return to the review page at a later time.
                            </Typography>
                            <Button
                                onClick={() => this.setState({ moveDialogOpen: false })}
                                color="primary"
                                className={classNames(classes.button, classes.cancelBtn)}>
                                Not Now
                            </Button>
                            <Button
                                component={Link}
                                to={"/cropyear/" + cropYear.id + "/analysis"}
                                variant="raised"
                                color="primary"
                                className={classes.button}>
                                Calculate Fieldprint Analysis
                            </Button>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={moveDialogOpen2} classes={{ paper: classes.dialogMin }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Typography variant="title" className={classes.questionTitle}>
                            Do you want to mark this crop year as provisional?
                        </Typography>
                        <IconButton
                            onClick={() => this.setState({ moveDialogOpen2: false })}
                            className={classes.closeIcon}
                            size="large">
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.marginTopTwo}>
                            <Typography gutterBottom>
                                Remember that only finalized crop years are included in Project and Field to Market
                                Program analysis and reporting. Are you sure you want to mark this crop year as
                                provisional?
                            </Typography>
                            <Button
                                onClick={() => this.setState({ moveDialogOpen2: false })}
                                color="primary"
                                className={classNames(classes.button, classes.cancelBtn)}>
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.ormCropYearUpdate({
                                        id: cropYear.id,
                                        is_final: false
                                    });
                                    this.setState({ moveDialogOpen2: false });
                                }}
                                variant="raised"
                                color="primary"
                                className={classes.button}>
                                Yes
                            </Button>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Grid item xs={8}>
                    <Typography gutterBottom variant="display3">
                        Review and Calculate
                    </Typography>
                    <Typography gutterBottom>
                        <b>1. Confirm Data Status</b>
                        <br />
                        By default, crop year data status is marked as provisional. When your data is complete and
                        finalized for the year, and you have reviewed the data for accuracy, mark the data as finalized.
                        This is important as only finalized data is included in Project and Field to Market Program
                        analysis and reporting.
                    </Typography>

                    {showMondelezQuestions && (
                        <Form
                            getApi={el => (this.form = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            defaultValues={
                                cropYear.project.survey
                                    ? {
                                          project: { survey: cropYear.project.survey }
                                      }
                                    : undefined
                            }
                            onSubmit={values => this.saveSurvey(values)}
                            validateError={validateSurvey}>
                            {formApi => (
                                <form className={classes.formRoot} onSubmit={formApi.submitForm}>
                                    <Paper className={classes.paper}>
                                        <WarningIcon className={classes.warningIcon} color={"primary"} />
                                        <Typography gutterBottom>
                                            <b>Additional Questions for {cropYear.project.project_name}</b>
                                        </Typography>

                                        <Typography gutterBottom>
                                            Before finalizing data for this crop year, please complete the following
                                            supplemental questions requested by the Project Administrator.
                                        </Typography>

                                        <Typography gutterBottom>
                                            <b>Seed Variety</b>
                                        </Typography>

                                        <Typography gutterBottom>
                                            In the Operations section you indicated the seeding rate for this year.
                                            Indicate which wheat seed varieties you planted and in what proportion. You
                                            can choose up to three varieties. Percent mix should total to 100%.
                                        </Typography>

                                        <Grid container spacing={8}>
                                            {[0, 1, 2].map(index => (
                                                <Grid item xs={12}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={12} sm={4} lg={3}>
                                                            <Select
                                                                field={`project.survey.mix[${index}].variety`}
                                                                label={"Seed variety " + (index + 1)}
                                                                SelectProps={{ native: true }}
                                                                native
                                                                options={seedVarietyOptions}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} lg={2}>
                                                            <TextField
                                                                type="number"
                                                                field={`project.survey.mix[${index}].amount`}
                                                                inputProps={{ step: 0.1 }}
                                                                units={"%"}
                                                                fullWidth
                                                                label="Amount"
                                                                margin="normal"
                                                            />
                                                        </Grid>

                                                        {getValue(
                                                            formApi.values,
                                                            `project.survey.mix[${index}].variety`
                                                        ) === "Other" && (
                                                            <Grid item xs={12} sm={4} lg={3}>
                                                                <TextField
                                                                    field={`project.survey.mix[${index}].variety_other`}
                                                                    fullWidth
                                                                    label="Other variety"
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {getValue(formApi.errors, "project.survey.amount_error") && (
                                                <Grid xs={12}>
                                                    <Typography
                                                        color="error"
                                                        style={{
                                                            marginLeft: 4,
                                                            marginBottom: 16,
                                                            fontSize: "0.75rem"
                                                        }}>
                                                        {formApi.errors.project.survey.amount_error}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Typography gutterBottom>
                                            <b>Use of variable-rate technology</b>
                                        </Typography>

                                        <Typography>
                                            Indicate where you used variable-rate technology in your operation this crop
                                            year. Select one or more.
                                        </Typography>

                                        <div style={{ marginTop: -16 }}>
                                            <CheckboxGroup
                                                field="project.survey.variablerate"
                                                row={true}
                                                options={variableRateOptions}
                                                eventHandle={e =>
                                                    this.checkNone(
                                                        e,
                                                        "project.survey.variablerate",
                                                        variableRateOptions
                                                    )
                                                }
                                            />
                                        </div>

                                        <Typography gutterBottom>
                                            <b>Optional Notes</b>
                                        </Typography>

                                        <Typography gutterBottom>
                                            Include any additional notes about conditions, circumstances or changes that
                                            potentially impacted your operations, management, or outcomes this crop
                                            year.
                                        </Typography>

                                        <TextField
                                            field="project.survey.notes"
                                            fullWidth
                                            multiline
                                            rows="4"
                                            maxRows="20"
                                        />
                                    </Paper>
                                    <div style={{ marginLeft: 40, marginBottom: 24 }}>
                                        <Button className={classes.gutterTop} type="submit" variant="raised">
                                            Save Supplemental Questions
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Form>
                    )}

                    {!isFinal && (
                        <div style={{ marginLeft: 40, marginBottom: 24 }}>
                            <Typography variant="title" gutterBottom className={classes.gutterTop}>
                                Current Data Status: Provisional
                            </Typography>
                            <Button
                                color="primary"
                                variant="raised"
                                disabled={incompleteSurvey}
                                onClick={() => this.finalize(true)}>
                                Click to Finalize Your Current Data
                            </Button>
                        </div>
                    )}
                    {isFinal && (
                        <div style={{ marginLeft: 40, marginBottom: 24 }}>
                            <Typography variant="title" gutterBottom className={classes.gutterTop}>
                                Current Data Status: Final
                            </Typography>
                            <Button color="primary" variant="raised" onClick={() => this.finalize(false)}>
                                Click to Make Your Data Provisional
                            </Button>
                        </div>
                    )}
                    <Typography gutterBottom>
                        <b>2. Calculate Fieldprint Analysis</b>
                        <br />
                        Now that your data is complete, you can generate your Fieldprint Analysis. You can return at any
                        time to edit the crop year data or change the data status.
                        <br />
                        <Button
                            color="primary"
                            variant="raised"
                            component={Link}
                            style={{ marginLeft: 40, marginTop: 16 }}
                            to={"/cropyear/" + cropYear.id + "/analysis"}>
                            Generate Your Fieldprint Analysis
                        </Button>
                    </Typography>
                    <Typography gutterBottom variant="display3" className={classes.gutterTop}>
                        Other Steps
                    </Typography>
                    <Typography variant="title">Return to Field Dashboard</Typography>
                    <Typography gutterBottom>
                        Return to the dashboard for this field to manage other crop years.
                        <Button color="primary" component={Link} to={"/field/" + fieldId}>
                            Go Now >
                        </Button>
                    </Typography>
                    <Typography variant="title">Associate Field with a Project</Typography>
                    <Typography gutterBottom>
                        If you are already part of a Project or if you have been invited to join a Project, manage these
                        field associations from the Project page.
                        <Button color="primary" onClick={() => navSelectPage("project")} component={Link} to="/project">
                            Go Now >
                        </Button>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography gutterBottom variant="display3">
                        Help
                    </Typography>
                    <Typography gutterBottom>
                        <b>When should I finalize my data?</b>
                        <br />
                        Data should be marked as finalized when the data entered for a crop year is final for year and
                        it has been validated or reviewed for accuracy. The goal is to improve data quality.
                    </Typography>
                    <Typography gutterBottom>
                        <b>What happens when I finalize my data?</b>
                        <br />
                        Data that is finalized will be included in Field to Market program and Project analysis and
                        reporting. Data marked provision is not included.
                    </Typography>
                    <Typography gutterBottom>
                        <b>Can I revert my data back to provisional status?</b>
                        <br />
                        You can set data back to provisional if needed. For example, if you realize data inputs need to
                        be changed but the data is not yet available, mark the data provisional. Once completed, you can
                        set the data back to finalized.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

Review = connect(null, {
    ...navActions
})(Review);

export default withStyles(styles)(Review);

function validateSurvey(values) {
    values = getValue(values, "project.survey") || {};
    const errors = {};

    const total = (values.mix || [])
        .filter(value => !!value)
        .map(value => parseFloat(value.amount))
        .reduce((a, b) => a + b, 0);
    if (total !== 100) {
        errors.amount_error = "Seed variety amounts must add up to exactly 100%.";
    }
    if (!values.variablerate || !values.variablerate.length > 0) {
        errors.variablerate = "Please select at least one option.";
    }

    var variety_other_errors = [];
    (values.mix || []).forEach((v, i) => {
        if (v.variety === "Other") {
            if (v.variety_other === undefined || v.variety_other.trim().length === 0) {
                variety_other_errors[i] = { variety_other: "Please enter variety name." };
            } else if (v.variety_other.trim().length >= 1 && v.variety_other.trim().length < 3) {
                variety_other_errors[i] = { variety_other: "Must be at least 3 characters." };
            }
        } else {
            variety_other_errors[i] = null;
        }
    });

    errors["mix"] = variety_other_errors;

    return { project: { survey: errors } };
}
