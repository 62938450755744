import React, { Component } from "react";
import { Form } from "react-form";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "../common/GridWrapper";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Button from "../common/ButtonWrapper";
import { MAKE_OPTIONS } from "../../api/constants";
import CheckboxGroup from "../common/CheckboxGroup";

const styles = theme => ({});

class ImportTemplateDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_projects: []
        };
    }

    handleProjectChange = event => {
        if (event.target.checked) {
            let selected_projects = this.state.selected_projects;
            selected_projects.push(this.props.projects.filter(p => p.id === event.target.value));
            this.setState({ selected_projects: selected_projects });
        } else {
            let selected_projects = this.state.selected_projects.filter(p => p.id === event.target.value);
            this.setState({ selected_projects: selected_projects });
        }
    };

    getDefaultValues() {
        let projects = this.props.selected_projects.map(p => p.id);
        return { projects: projects };
    }

    errorValidator = values => {
        return { projects: null };
    };

    updateProjectSelection = values => {
        this.props.handleProjectDialogOk(values["projects"]);
    };

    componentDidMount() {
        this.setState({
            selected_projects: this.props.selected_projects
        });
    }

    render() {
        const defaultValues = this.getDefaultValues();

        return (
            <Dialog
                open={this.props.open}
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Select Projects</DialogTitle>
                <DialogContent>
                    <Form
                        getApi={el => (this.form = el)}
                        dontValidateOnMount="false"
                        validateOnSubmit="true"
                        defaultValues={defaultValues}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.updateProjectSelection(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <CheckboxGroup
                                            field="projects"
                                            row={false}
                                            options={MAKE_OPTIONS(this.props.projects)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            onClick={this.props.handleProjectDialogCancel}
                                            color="primary"
                                            fullWidth>
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button type="submit" variant="raised" color="primary" fullWidth>
                                            Ok
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(ImportTemplateDialog));
