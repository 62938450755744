import React, { Component } from "react";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import classNames from "classnames";

import Button from "../../../common/ButtonWrapper";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Grid from "../../../common/GridWrapper";
import Typography from "../../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import CheckboxGroup from "../../../common/CheckboxGroup";
import RadioGroup from "../../../common/RadioGroup";
import Select from "../../../common/Select";
import TextField from "../../../common/TextField";
import HelpLabel from "../../../common/HelpLabel";
import SubquestionIcon from "../../../common/icons/Subquestion";
import { YES_NO_OPTIONS, MAKE_OPTIONS, SURFACEWATER } from "../../../../api/constants";
import { getValue, setValue } from "../../../../api/utils";

import { CoverCrop } from "../models";
import { FarmHpiSurvey } from "../../models";

import {
    TillageClasses,
    PredominantRotationPractices,
    TileDrainage,
    WildlifeHabitat,
    IntegratedPestManagement,
    LandConversionTypes,
    Nutrient4RS
} from "../../fieldactivities/models";

import { WaterSources } from "../../fieldactivities/activities/models";

import { FarmHpiCultivatedLandQuestionnaire } from "../../models";

const allCoverCrop = CoverCrop.selectAll();
const allTileDrainage = TileDrainage.selectAll();
const allWildlifeHabitats = WildlifeHabitat.selectAll();
const allLandConversionTypes = LandConversionTypes.selectAll();
const allPredominantRotationPractices = PredominantRotationPractices.selectAll();
const allTillageClasses = TillageClasses.selectAll();
const allNutrient4RS = Nutrient4RS.selectAll();
const activeIntegratedPestManagement = IntegratedPestManagement.selectAll();
const allWaterSources = WaterSources.selectAll();

const styles = theme => ({
    button: {
        marginRight: theme.spacing(1)
    },
    gutterTop: {
        marginTop: ".6em"
    },
    cancelBorder: {
        border: "1px solid"
    },
    titleContainer: {
        backgroundColor: "#f15d22"
    },
    flex: {
        display: "inline-block",
        color: "#eee"
    },
    closeIcon: {
        float: "right",
        color: "#eee",
        marginTop: -8,
        marginBottom: -8
    }
});

class LandQuestionsDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            cq: null,
            water_source: null,
            surface_water: false,
            submitClicked: false
        };
    }

    landConversionChange = value => {
        console.log("--> landConversionChange");
        var cq = this.state.cq;
        cq["conversion"] = value;
        this.setState({ cq: cq });
    };

    irrigatedChange = value => {
        console.log("--> irrigatedChange");
        var cq = this.state.cq;
        cq["majority_irrigated"] = value;
        this.setState({ cq: cq });
    };

    update_irrigation_source = value => {
        console.log("update_irrigation_source");
        console.log(value);
        this.setState({
            surface_water: SURFACEWATER.includes(value)
        });
    };

    errorValidator = (values, saving) => {
        if (this.state.submitClicked === false && values["required"] === 0 && values["missing"] === 0) {
            return {};
        }

        var required = 0;
        var missing = 0;

        const validateArea = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                missing += 1;
                return;
            }
            const number = parseFloat(val);
            if (number <= 0) {
                setValue(valObj, path, "Must be greater than 0 ac.");
                return;
            }
            setValue(valObj, path, null);
        };

        const validateAreaConverted = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                missing += 1;
                return;
            }
            const number = parseFloat(val);
            if (number <= 0) {
                setValue(valObj, path, "Must be greater than or equal to 0 ac.");
                return;
            }
            setValue(valObj, path, null);
        };

        const validateTrueFalse = path => {
            required += 1;
            let val = getValue(values, path);
            if (val === null) {
                missing += 1;
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, null);
        };

        const isRequired = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                missing += 1;
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, null);
        };

        var valObj = {};

        validateArea("plantable_area");
        validateTrueFalse("conversion");

        if (getValue(values, "conversion") === true) {
            isRequired("previous_land_cover");
            validateAreaConverted("field_area_converted");
        }

        isRequired("rotation_practice");
        isRequired("tillage_practice");
        isRequired("cover_crop");
        //  TODO: nutrient 4 rs
        validateTrueFalse("nutrient_management_plan");
        isRequired("pest_management");
        isRequired("tile_drainage");
        validateTrueFalse("majority_irrigated");

        if (getValue(values, "majority_irrigated") === true) {
            isRequired("water_source");

            if (SURFACEWATER.includes(values.water_source) === true) {
                validateTrueFalse("irrigation_conservation");
            } else {
                setValue(valObj, "irrigation_conservation", null);
            }
        } else {
            setValue(valObj, "water_source", null);
            setValue(valObj, "irrigation_conservation", null);
        }

        if (saving === true) {
            return {
                required: required,
                missing: missing
            };
        }

        return valObj;
    };

    handleCancelClick = () => {
        this.props.onCancelClick();
    };

    saveAnswers = values => {
        const isComplete = this.errorValidator(values, true);

        const cq = {
            plantable_area: values.plantable_area,
            conversion: values.conversion,
            field_area_converted: values.field_area_converted,
            wildlife_habitat: values.wildlife_habitat.join("") === "" ? null : values.wildlife_habitat.join(""),
            rotation_practice: values.rotation_practice,
            tillage_practice: values.tillage_practice,
            cover_crop: values.cover_crop,
            nutrient_management_plan: values.nutrient_management_plan,
            nutrient_4_rs: values.nutrient_4_rs.join(",") === "" ? null : values.nutrient_4_rs.join(","),
            pest_management: values.pest_management,
            tile_drainage: values.tile_drainage,
            majority_irrigated: values.majority_irrigated,
            completed: isComplete.required > 0 && isComplete.missing === 0 ? true : false,
            missing: isComplete.missing,
            required: isComplete.required
        };

        if (getValue(values, "conversion") === true) {
            cq["previous_land_cover"] = values.previous_land_cover;
            cq["field_area_converted"] = values.field_area_converted;
        } else {
            cq["previous_land_cover"] = null;
            cq["field_area_converted"] = null;
        }

        if (getValue(values, "majority_irrigated") === true) {
            cq["water_source"] = values.water_source;

            if (SURFACEWATER.includes(values.water_source) === true) {
                cq["irrigation_conservation"] = values.irrigation_conservation;
            } else {
                cq["irrigation_conservation"] = null;
            }
        } else {
            cq["water_source"] = null;
            cq["irrigation_conservation"] = null;
        }

        var promises = [];

        promises.push(this.props.ormFarmHpiSurveyUpdate({ id: this.props.farmHpiSurvey.id, results: null }));
        promises.push(this.props.ormFarmHpiCultivatedLandQuestionnaireUpdate({ id: this.props.cq.id, ...cq }));

        Promise.all(promises).then(results => {
            this.props.onSaveClick();
        });
    };

    getDefaultValues = cq => {
        return {
            plantable_area: cq.plantable_area,
            conversion: cq.conversion,
            previous_land_cover: cq.previous_land_cover,
            field_area_converted: cq.field_area_converted,
            wildlife_habitat: cq.wildlife_habitat === null ? [] : cq.wildlife_habitat.split(","),
            rotation_practice: cq.rotation_practice,
            tillage_practice: cq.tillage_practice,
            cover_crop: cq.cover_crop,
            nutrient_management_plan: cq.nutrient_management_plan,
            nutrient_4_rs: cq.nutrient_4_rs === null ? [] : cq.nutrient_4_rs.split(","),
            pest_management: cq.pest_management,
            tile_drainage: cq.tile_drainage,
            majority_irrigated: cq.majority_irrigated,
            water_source: cq.water_source,
            irrigation_conservation: cq.irrigation_conservation,
            completed: cq.completed,
            missing: cq.missing,
            required: cq.required
        };
    };

    componentWillMount() {
        const cq = this.props.cq;
        const crop_id = cq.crop_id;
        const crop_name = this.props.allCrops.filter(c => c.id === cq.crop_id)[0]["name"];
        const surface_water = SURFACEWATER.includes(cq.water_source);
        console.log(cq);
        this.setState({
            cq: cq,
            crop_id: crop_id,
            crop_name: crop_name,
            surface_water: surface_water
        });
    }

    render() {
        const {
            classes,
            open,
            coverCrop,
            wildlifeHabitat,
            landConversionTypes,
            predominantRotationPractices,
            tileDrainage,
            tillageClasses,
            nutrient4RS,
            integratedPestManagement,
            waterSources
        } = this.props;

        const { cq, crop_id, crop_name, surface_water } = this.state;

        var wildlifeHabitatOpt = wildlifeHabitat.filter(wH => wH.crop_id === crop_id);
        if (wildlifeHabitatOpt.length === 0) wildlifeHabitatOpt = wildlifeHabitat.filter(wH => !wH.crop_id);

        var predominantRotationPracticesOpt =
            crop_id === "8"
                ? predominantRotationPractices.filter(cq => cq.crop_id === "8")
                : predominantRotationPractices.filter(cq => cq.crop_id !== "8");
        const default_values = this.getDefaultValues(cq);

        return (
            <Dialog open={open}>
                <DialogTitle className={classes.titleContainer}>
                    <Typography variant="headline" className={classes.flex}>
                        Cultivated Land Questions for {crop_name}
                    </Typography>
                    <IconButton onClick={() => this.handleCancelClick()} className={classes.closeIcon} size="large">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Form
                        getApi={el => (this.formApi = el)}
                        dontValidateOnMount={false}
                        validateOnSubmit={true}
                        validateError={values => this.errorValidator(values, false)}
                        defaultValues={default_values}
                        onSubmit={values => this.saveAnswers(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={8} style={{ marginTop: "8px" }}>
                                    <Grid item xs={12}>
                                        <Typography style={{ textAlign: "left" }}>
                                            Enter the total plantable {crop_name.toLowerCase()} acres planted on the
                                            farm in the survey year. For example, if you have three 400 acre fields of{" "}
                                            {crop_name.toLowerCase()} in 2019, your total plantable acres here is 1,200
                                            acres.
                                        </Typography>

                                        <TextField
                                            style={{ float: "right" }}
                                            type="number"
                                            inputProps={{ step: 0.1 }}
                                            field="plantable_area"
                                            label={""}
                                            units="ac"
                                            margin="none"
                                        />

                                        <Grid item xs={12} />
                                        <RadioGroup
                                            eventHandle={value => this.landConversionChange(value)}
                                            alignment={true}
                                            field="conversion"
                                            name="landconversion"
                                            options={YES_NO_OPTIONS}
                                            fullWidth
                                            label={
                                                <HelpLabel
                                                    inputLabel={
                                                        "Was any part of your " +
                                                        crop_name.toLowerCase() +
                                                        " fields converted from a use other than cropland in the past 5 years?"
                                                    }
                                                    helpText="Conversion of land use from non-cultivated (e.g. pasture, range, natural habitat) to cultivated cropland in the past five (5) years. You should answer Yes to this question for Crop Years that fall within the five-year window of land use conversion. For example, if land was converted in 2014, and you enter crop years in the Platform for 2017 and 2018, you should answer Yes in both crop years, as both years fall within the five-year window."
                                                />
                                            }
                                        />
                                        {cq.conversion && (
                                            <Grid container wrap="nowrap">
                                                <Grid item xs={1} />
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginRight: 8 }}>
                                                    <Select
                                                        field="previous_land_cover"
                                                        label="Type of Land Conversion"
                                                        options={MAKE_OPTIONS(landConversionTypes)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        type="number"
                                                        field="field_area_converted"
                                                        label="Area"
                                                        units="ac"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        <CheckboxGroup
                                            field="wildlife_habitat"
                                            label={
                                                <HelpLabel
                                                    inputLabel={
                                                        <div style={{ display: "inline" }}>
                                                            Do you practice any season enhancements for wildlife habitat
                                                            on a <b>majority</b> of your {crop_name.toLowerCase()}{" "}
                                                            fields? (Check all that apply)
                                                        </div>
                                                    }
                                                    helpText="Practices that increase the amount of food, breeding or nesting habitat available to wildlife."
                                                />
                                            }
                                            row={false}
                                            options={MAKE_OPTIONS(wildlifeHabitatOpt)}
                                        />

                                        <Select
                                            field="rotation_practice"
                                            label={
                                                <Typography>
                                                    Predominant crop rotation practice used on the <b>majority</b> of
                                                    your {crop_name.toLowerCase()} fields:
                                                </Typography>
                                            }
                                            help={
                                                <div>
                                                    <b>Crop to fallow:</b> commodity crop followed by a year where the
                                                    land left unplanted
                                                    <br />
                                                    <br />
                                                    <b>Conventional crop rotation:</b> commodity crop followed by
                                                    another, but different, type of commodity crop the following year
                                                    <br />
                                                    <br />
                                                    <b>Continuous single crop:</b> planting the same commodity crop more
                                                    than one year in a row
                                                </div>
                                            }
                                            options={MAKE_OPTIONS(predominantRotationPracticesOpt)}
                                            fullWidth
                                            margin="normal"
                                        />

                                        <Select
                                            field="tillage_practice"
                                            label={
                                                <Typography>
                                                    Predominant tillage practice used on the <b>majority</b> of your{" "}
                                                    {crop_name.toLowerCase()} fields:
                                                </Typography>
                                            }
                                            help={
                                                <div>
                                                    <b>Conventional or intensive tillage:</b> plowing and harrowing to
                                                    produce a fine seedbed as well as the burial of most (>85%) of the
                                                    surface residue from the previous year. This may include the use of
                                                    a chisel or moldboard plow and multiple field passes
                                                    <br />
                                                    <br />
                                                    <b>Reduced tillage:</b> may use the same equipment as conventional
                                                    or intensive tillage practices but may employ fewer field passes
                                                    such that the burial of surface residue is not greater than 70%
                                                    <br />
                                                    <br />
                                                    <b>No-till, strip-till, or conservation tillage:</b> not disturbing
                                                    the soil during planting with any equipment, tilling narrow strips
                                                    (6 - 12 inches wide) between rows which are then injected with
                                                    fertilizer and seed, or any method that leaves the >30% of surface
                                                    residue intact and exposed
                                                </div>
                                            }
                                            options={MAKE_OPTIONS(tillageClasses)}
                                            fullWidth
                                            margin="normal"
                                        />

                                        <Select
                                            field="cover_crop"
                                            label={
                                                <Typography>
                                                    Do you use a cover crop on the <b>majority</b> of your{" "}
                                                    {crop_name.toLowerCase()} fields?
                                                </Typography>
                                            }
                                            options={MAKE_OPTIONS(coverCrop)}
                                            fullWidth
                                            margin="normal"
                                            help={
                                                <div>
                                                    Vegetation planted or left on the field (planted or naturally
                                                    established) with the intention of reducing soil erosion,
                                                    controlling disease or pests, and/or improving soil health.
                                                </div>
                                            }
                                        />

                                        <RadioGroup
                                            field="nutrient_management_plan"
                                            name="nmp"
                                            options={YES_NO_OPTIONS}
                                            alignment={true}
                                            label={
                                                <HelpLabel
                                                    inputLabel={
                                                        <div style={{ display: "inline" }}>
                                                            Are you implementing an approved Nutrient Management Plan on
                                                            a <b>majority</b> of your {crop_name.toLowerCase()} fields?
                                                        </div>
                                                    }
                                                    helpText={
                                                        "Application of a Nutrient Management Plan that addresses the nutrient budget, supply, and conservation of nutrients for crop production. This includes the management of all 4R's: Right Source, Right Rate, Right Time, and Right Place."
                                                    }
                                                />
                                            }
                                        />

                                        <CheckboxGroup
                                            field="nutrient_4_rs"
                                            label={
                                                <HelpLabel
                                                    inputLabel={
                                                        <div style={{ display: "inline" }}>
                                                            Do you apply nutrients according to the 4 R's of nutrient
                                                            stewardship to a <b>majority</b> of your{" "}
                                                            {crop_name.toLowerCase()} fields?
                                                        </div>
                                                    }
                                                    helpText={
                                                        <div>
                                                            The 4R nutrient stewardship principles are the same
                                                            globally, but how they are used locally varies depending on
                                                            field and site-specific characteristics such as soil,
                                                            cropping system, management techniques and climate. The
                                                            scientific principles of the 4R framework include:
                                                            <br />
                                                            <br />
                                                            RIGHT SOURCE – Ensure a balanced supply of essential
                                                            nutrients, considering both naturally available sources and
                                                            the characteristics of specific products, in plant available
                                                            forms.
                                                            <br />
                                                            <br />
                                                            RIGHT RATE – Assess and make decisions based on soil
                                                            nutrient supply and plant demand.
                                                            <br />
                                                            <br />
                                                            RIGHT TIME – Assess and make decisions based on the dynamics
                                                            of crop uptake, soil supply, nutrient loss risks, and field
                                                            operation logistics.
                                                            <br />
                                                            <br />
                                                            RIGHT PLACE – Address root-soil dynamics and nutrient
                                                            movement and manage spatial variability within the field to
                                                            meet site-specific crop needs and limit potential losses
                                                            from the field.
                                                        </div>
                                                    }
                                                />
                                            }
                                            row={true}
                                            options={MAKE_OPTIONS(nutrient4RS)}
                                        />

                                        <Select
                                            fullWidth
                                            field="pest_management"
                                            label={
                                                <Typography>
                                                    Integrated Pest Management for the <b>majority</b> of your{" "}
                                                    {crop_name.toLowerCase()} fields.
                                                </Typography>
                                            }
                                            help={
                                                <div>
                                                    Activities such as the use of cultural, mechanical, biological and
                                                    chemical control methods that reduce or eliminate a pest population
                                                    or its impacts while minimizing risks to non-target organisms.
                                                    <br />
                                                    <br />
                                                    Select IPM (including chemical control OR primarily without use of
                                                    chemical control) if you have and implement a comprehensive IPM plan
                                                    incorporating prevention, avoidance, monitoring and suppression
                                                    strategies.
                                                    <br />
                                                    <br />
                                                    Select "pest managed primarily using chemical control and additional
                                                    site-specific techniques to reduce environmental risks of the
                                                    pesticides" if you do NOT have and implement an IPM plan, as defined
                                                    above, but you do use one or more of the following site-specific
                                                    techniques (techniques for reducing pesticide environmental risks
                                                    include but are not limited to the following):
                                                    <br />
                                                    <br />
                                                    <b>
                                                        Pest monitoring and use of economic thresholds to trigger
                                                        application
                                                    </b>
                                                    <br />
                                                    <b>Partial treatment (&lt; 50%) of field</b>
                                                    <br />
                                                    <b>Delay application when significant rainfall is likely</b>
                                                    <br />
                                                    <b>
                                                        Variable rate or section controls are utilized on application
                                                        equipment
                                                    </b>
                                                    <br />
                                                    <b>Pesticides are incorporated</b>
                                                    <br />
                                                    <b>Field setbacks utilized (&gt; 30ft)</b>
                                                    <br />
                                                    <b>Use formulations and/or drift retardants</b>
                                                    <br />
                                                    <b>
                                                        Use appropriate nozzles and pressure on a well maintained and
                                                        frequently calibrated applicator
                                                    </b>
                                                    <br />
                                                    <b>Delay applications when wind speed is not optimal</b>
                                                    <br />
                                                    <b>Spray when high humidity reduces evaporation</b>
                                                    <br />
                                                    <b>
                                                        Applications made during cooler temperatures (early in the
                                                        morning or late evening)
                                                    </b>
                                                    <br />
                                                    <b>Genetic control for insects</b>
                                                    <br />
                                                    <b>Seed treatment</b>
                                                    <br />
                                                    <b>
                                                        Precision AG- Variable Rate Applications of Pesticides or
                                                        Fertilizers
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Source: Summarized from Table 1 of NRCS Agronomy Technical Note No.
                                                    5 (Feb 2011).
                                                </div>
                                            }
                                            options={MAKE_OPTIONS(integratedPestManagement)}
                                            margin="normal"
                                        />

                                        <Select
                                            fullWidth
                                            label={
                                                <Typography>
                                                    Tile Drainage System Type for the <b>majority</b> of the your{" "}
                                                    {crop_name.toLowerCase()} fields.
                                                </Typography>
                                            }
                                            field="tile_drainage"
                                            options={MAKE_OPTIONS(tileDrainage)}
                                            help={
                                                <div>
                                                    A tile drainage system is a system of sub-surface pipes that drain
                                                    gravitational (excess) water not held within the soil pores.
                                                    <br />
                                                    <br />
                                                    Standard density tiles are typically spaced 60-100 feet apart. High
                                                    density tile drainage is typically less than 60 feet spacing between
                                                    tiles.
                                                </div>
                                            }
                                            margin="normal"
                                        />

                                        <RadioGroup
                                            fullWidth
                                            eventHandle={value => this.irrigatedChange(value)}
                                            field="majority_irrigated"
                                            name="majority_irrigated"
                                            options={YES_NO_OPTIONS}
                                            alignment={true}
                                            label={
                                                <HelpLabel
                                                    inputLabel={
                                                        <div style={{ display: "inline" }}>
                                                            Are a <b>majority</b> of your {crop_name.toLowerCase()}{" "}
                                                            fields irrigated?
                                                        </div>
                                                    }
                                                    helpText="The application of water to supplement precipitation to meet crop requirements."
                                                />
                                            }
                                        />
                                        {cq.majority_irrigated && (
                                            <>
                                                <Grid container wrap="nowrap">
                                                    <Grid item xs={1} />
                                                    <Grid item xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid item xs={10} style={{ marginRight: 8 }}>
                                                        <Select
                                                            fullWidth
                                                            field="water_source"
                                                            label="Water Source"
                                                            help={
                                                                <div>
                                                                    Identify the source of your irrigation water:
                                                                    <br />
                                                                    <br />
                                                                    <b>Deep aquifer groundwater:</b> source is an
                                                                    aquifer that does not connect to a natural surface
                                                                    water body/stream
                                                                    <br />
                                                                    <br />
                                                                    <b>Alluvial groundwater:</b> source is a shallow
                                                                    aquifer that connects to a natural surface water
                                                                    body
                                                                    <br />
                                                                    <br />
                                                                    <b>Surface water:</b> source is a surface water body
                                                                    such as a stream, canal or pond
                                                                    <br />
                                                                    <br />
                                                                    If you use more than one of these water sources,
                                                                    please select "Both surface water and deep aquifer
                                                                    groundwater"
                                                                </div>
                                                            }
                                                            options={MAKE_OPTIONS(waterSources)}
                                                            eventHandle={value => this.update_irrigation_source(value)}
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {surface_water === true && (
                                                    <Grid container>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={1} className={classes.subQuestion}>
                                                            <SubquestionIcon />
                                                        </Grid>
                                                        <Grid item xs={9} style={{ marginRight: 8 }}>
                                                            <RadioGroup
                                                                field="irrigation_conservation"
                                                                name="conservationmethods"
                                                                options={YES_NO_OPTIONS}
                                                                alignment={true}
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Do you practice any water conservation methods?"
                                                                        helpText="Water conservation methods might include cover crops, irrigation schedules."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            color="primary"
                                            onClick={() => this.handleCancelClick()}
                                            className={classes.cancelBorder}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="raised"
                                            type="submit"
                                            color="primary"
                                            onClick={() => this.setState({ submitClicked: true })}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
            </Dialog>
        );
    }
}

LandQuestionsDialog = connect(
    (state, ownProps) => ({
        coverCrop: allCoverCrop(state),
        tileDrainage: allTileDrainage(state),
        wildlifeHabitat: allWildlifeHabitats(state),
        landConversionTypes: allLandConversionTypes(state),
        predominantRotationPractices: allPredominantRotationPractices(state),
        tillageClasses: allTillageClasses(state),
        nutrient4RS: allNutrient4RS(state),
        integratedPestManagement: activeIntegratedPestManagement(state),
        waterSources: allWaterSources(state)
    }),
    {
        ...FarmHpiSurvey.actions,
        ...FarmHpiCultivatedLandQuestionnaire.actions
    }
)(LandQuestionsDialog);

export default withStyles(styles)(withRouter(LandQuestionsDialog));
