import React, { Component } from "react";

import RotationSystemWarningDialog from "./RotationSystemWarningDialog";
import { RotationSystem } from "../rotation/models";
import { connect } from "react-redux";

class RotationSystemWarningDialogWrapper extends Component {
    state = {
        open: true
    };

    deleteRotationSystem = id => {
        this.props.ormRotationSystemDelete(id);
    };

    updateRotationName = (id, name) => {
        this.props.ormRotationSystemUpdate({
            id: id,
            name: name
        });
    };

    componentWillReceiveProps() {
        this.setState({ open: true });
    }

    render() {
        const { callback, message } = this.props;
        const { open } = this.state;

        const id = message.split("@#$%^&")[0];
        const name = message.split("@#$%^&")[1];
        const messageText = message.split("@#$%^&")[2];

        return (
            <RotationSystemWarningDialog
                store={this.props.store}
                open={open}
                cancelAction={() => {
                    callback(true);
                    this.deleteRotationSystem(id);
                    this.setState({ open: false });
                }}
                confirmAction={() => {
                    callback(true);
                    this.updateRotationName(id, name);
                    this.setState({ open: false });
                }}
                confirmText="Keep the Rotation System"
                cancelText="Delete the Rotation System"
                title="Rotation system without events!"
                text={messageText}
            />
        );
    }
}

RotationSystemWarningDialogWrapper = connect((state, ownProps) => ({}), {
    ...RotationSystem.actions
})(RotationSystemWarningDialogWrapper);

export default RotationSystemWarningDialogWrapper;
