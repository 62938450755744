import orm, { ReferenceData, InternalReferenceData } from "../../common/orm";

export class CoverCrop extends ReferenceData {
    static get modelName() {
        return "CoverCrop";
    }
}
orm.register(CoverCrop);

export class NoncultivatedLandTypes extends InternalReferenceData {
    static get modelName() {
        return "NoncultivatedLandTypes";
    }
}
orm.register(NoncultivatedLandTypes);

export class RiparianVegetation extends InternalReferenceData {
    static get modelName() {
        return "RiparianVegetation";
    }
}
orm.register(RiparianVegetation);

export class RiparianManagement extends InternalReferenceData {
    static get modelName() {
        return "RiparianManagement";
    }
}
orm.register(RiparianManagement);

export class ForestConfiguration extends InternalReferenceData {
    static get modelName() {
        return "ForestConfiguration";
    }
}
orm.register(ForestConfiguration);

export class ForestManagement extends InternalReferenceData {
    static get modelName() {
        return "ForestManagement";
    }
}
orm.register(ForestManagement);

export class GrasslandConfiguration extends InternalReferenceData {
    static get modelName() {
        return "GrasslandConfiguration";
    }
}
orm.register(GrasslandConfiguration);

export class GrazingIntensity extends InternalReferenceData {
    static get modelName() {
        return "GrazingIntensity";
    }
}
orm.register(GrazingIntensity);

export class GrasslandManagement extends InternalReferenceData {
    static get modelName() {
        return "GrasslandManagement";
    }
}
orm.register(GrasslandManagement);

export class NaturalWetlandRegime extends InternalReferenceData {
    static get modelName() {
        return "NaturalWetlandRegime";
    }
}
orm.register(NaturalWetlandRegime);

export class WetlandManagement extends InternalReferenceData {
    static get modelName() {
        return "WetlandManagement";
    }
}
orm.register(WetlandManagement);

export class SurfaceWaterBufferPercentage extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterBufferPercentage";
    }
}
orm.register(SurfaceWaterBufferPercentage);

export class SurfaceWaterRiparianVegetation extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterRiparianVegetation";
    }
}
orm.register(SurfaceWaterRiparianVegetation);

export class BankStability extends InternalReferenceData {
    static get modelName() {
        return "BankStability";
    }
}
orm.register(BankStability);

export class BankManagement extends InternalReferenceData {
    static get modelName() {
        return "BankManagement";
    }
}
orm.register(BankManagement);

export class SurfaceWaterChannelization extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterChannelization";
    }
}
orm.register(SurfaceWaterChannelization);

export class SurfaceWaterInstreamHabitat extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterInstreamHabitat";
    }
}
orm.register(SurfaceWaterInstreamHabitat);

export class SurfaceWaterPredominantUse extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterPredominantUse";
    }
}
orm.register(SurfaceWaterPredominantUse);

export class SurfaceWaterAverageDepth extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterAverageDepth";
    }
}
orm.register(SurfaceWaterAverageDepth);

export class SurfaceWaterManagement extends InternalReferenceData {
    static get modelName() {
        return "SurfaceWaterManagement";
    }
}
orm.register(SurfaceWaterManagement);
