import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Paper from "@mui/material/Paper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import Select from "../common/Select";
import Checkbox from "../common/Checkbox";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 500
    },
    centerAlign: {
        textAlign: "center"
    },
    filterMargin: {
        marginTop: 0,
        marginBottom: 0
    },
    metricNotMatch: {
        color: "red"
    }
});

const columnData = [
    { id: "farm", numeric: false, label: "Farm", allowSort: true },
    { id: "field", numeric: false, label: "Field", allowSort: true },
    { id: "year", numeric: false, label: "Year", allowSort: true },
    { id: "crop", numeric: false, label: "Crop", allowSort: true },
    { id: "data_status", numeric: false, label: "Data Status", allowSort: true },
    { id: "metric_status", numeric: false, label: "Metric Status", allowSort: true },
    { id: "metric_version", numeric: false, label: "Metric Version", allowSort: true },
    { id: "project", numeric: false, label: "Project", allowSort: true },
    { id: "select", numeric: false, label: "Select", allowSort: false }
];

class ExportDataTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "farm",
            page: 0,
            rowsPerPage: 10,
            newDialogOpen: false,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            farmFilter: null,
            fieldFilter: null,
            yearFilter: null,
            cropFilter: null,
            dataStatusFilter: null,
            metricStatusFilter: null,
            metricVersionFilter: null,
            projectFilter: null
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.cropyears.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.cropyears.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSetFilter = (filter, value) => {
        this.props.onSetFilter(filter, value);
        this.handleChangePage(null, 0);
    };

    render() {
        const {
            classes,
            cropyears,
            onSelectAllCropyears,
            onSelectCropyear,
            farmOptions,
            fieldOptions,
            yearOptions,
            cropOptions,
            dataStatusOptions,
            metricStatusOptions,
            metricVersionOptions,
            projectOptions,
            allSelected,
            hasCropyears,
            hasFilteredCropyears
        } = this.props;

        const { order, orderBy, rowsPerPage, page } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, cropyears.length - page * rowsPerPage);

        return (
            <Form dontValidateOnMount="true" validateOnSubmit="true">
                {formApi => (
                    <form>
                        <Paper className={classes.root}>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    columnData={columnData}
                                />
                                <TableBody>
                                    <TableRow key="filters">
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("farmFilter", value)}
                                                field="farm"
                                                className={classes.filterMargin}
                                                options={farmOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("fieldFilter", value)}
                                                field="field"
                                                className={classes.filterMargin}
                                                options={fieldOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("yearFilter", value)}
                                                field="year"
                                                className={classes.filterMargin}
                                                options={yearOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("cropFilter", value)}
                                                field="crop"
                                                className={classes.filterMargin}
                                                options={cropOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("dataStatusFilter", value)}
                                                field="data_status"
                                                className={classes.filterMargin}
                                                options={dataStatusOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("metricStatusFilter", value)}
                                                field="metric_status"
                                                className={classes.filterMargin}
                                                options={metricStatusOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value =>
                                                    this.handleSetFilter("metricVersionFilter", value)
                                                }
                                                field="metric_version"
                                                className={classes.filterMargin}
                                                options={metricVersionOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Select
                                                eventHandle={value => this.handleSetFilter("projectFilter", value)}
                                                field="project"
                                                className={classes.filterMargin}
                                                options={projectOptions}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {allSelected ? (
                                                <Checkbox
                                                    field="allCropyearsSelected"
                                                    checked={true}
                                                    disableRipple={true}
                                                    onChange={() => onSelectAllCropyears()}
                                                    color="primary"
                                                />
                                            ) : (
                                                <Checkbox
                                                    field="allCropyearsSelected"
                                                    checked={false}
                                                    disableRipple={true}
                                                    onChange={() => onSelectAllCropyears()}
                                                    color="primary"
                                                />
                                            )}
                                        </CustomTableCell>
                                    </TableRow>
                                    {cropyears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(c => (
                                        <TableRow hover key={c.farm + c.field + c.year}>
                                            <CustomTableCell>{c.farm}</CustomTableCell>
                                            <CustomTableCell>{c.field}</CustomTableCell>
                                            <CustomTableCell>{c.year}</CustomTableCell>
                                            <CustomTableCell>{c.crop}</CustomTableCell>
                                            <CustomTableCell>{c.data_status}</CustomTableCell>
                                            <CustomTableCell>{c.metric_status}</CustomTableCell>
                                            <CustomTableCell
                                                className={c.metric_version_match ? "" : classes.metricNotMatch}>
                                                {c.metric_version}
                                            </CustomTableCell>
                                            <CustomTableCell>{c.project}</CustomTableCell>
                                            <CustomTableCell>
                                                <Checkbox
                                                    field={[c.cropyear_id, "selected"].join("_")}
                                                    checked={c.selected}
                                                    disableRipple={true}
                                                    onChange={() => onSelectCropyear(c.cropyear_id)}
                                                    value={c.cropyear_id}
                                                    color="primary"
                                                />
                                            </CustomTableCell>
                                        </TableRow>
                                    ))}{" "}
                                    {emptyRows > 0 && cropyears.length > 10 && (
                                        <TableRow style={{ height: 48 * emptyRows }}>
                                            <CustomTableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                    {hasCropyears === false && (
                                        <TableRow>
                                            <CustomTableCell colSpan={9} className={classes.centerAlign}>
                                                No Crop Years Entered
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                    {hasCropyears === true && hasFilteredCropyears === false && (
                                        <TableRow>
                                            <CustomTableCell colSpan={9} className={classes.centerAlign}>
                                                No Matching Crop Years Found
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {cropyears.length > 10 && (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={5}
                                                count={cropyears.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                classes={{
                                                    caption: classes.body1
                                                }}
                                                onPageChange={this.handleChangePage}
                                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActionsWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </Paper>
                    </form>
                )}
            </Form>
        );
    }
}

ExportDataTable = connect((state, ownProps) => ({}))(ExportDataTable);

export default withStyles(styles)(ExportDataTable);
