import React, { Component } from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
// import EyeIcon from "../../common/icons/EyeIcon";
// import ExpandLess from "@mui/icons-material/ExpandLess";

// import Divider from "@mui/material/Divider";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

const styles = theme => ({
    factsContainer: {
        padding: "0 !important",
        margin: "0 -20px",
        flexBasis: "auto",
        width: "100vw",
        maxWidth: "105%"
    },
    hidden: {
        opacity: 0,
        overflow: "hidden",
        padding: "0 !important",
        height: "0 !important"
    },
    panelText: {
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#ff7d32",
        marginLeft: "auto",
        marginRight: "auto",
        width: "155px",
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: "0 16px 0 8px"
    },
    panelContainer: {
        padding: "20px",
        width: "100%",
        // height: "130px",
        transition: theme.transitions.create(["height", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    button: {
        height: "20px",
        width: "20px",
        color: "#fff"
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: "#ff7d32"
    },
    floatRightFacts: {
        float: "right",
        marginRight: theme.spacing(1)
    },
    underlineBottom: {
        borderBottom: "1px dotted",
        marginBottom: theme.spacing(2),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    greenText: {
        color: "#45812B"
    },
    factBoxContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    factBox: {
        minHeight: 70,
        minWidth: 70,
        border: "1px solid lightgrey",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 12,
        margin: 12
    }
});

function FactBox(props) {
    return (
        <div className={props.classes.factBox}>
            <Typography variant="display1" align="center" style={{ fontWeight: 500 }}>
                {props.value}
            </Typography>
            <Typography
                className={props.classes.greenText}
                align="center"
                style={{ marginTop: "8px", fontWeight: 600 }}>
                {props.label}
            </Typography>
        </div>
    );
}

class ProjectFacts extends Component {
    state = {
        expanded: true
    };

    getFarmCount = project_data => {
        var unique_list = [];

        project_data.forEach(d => {
            const val = [d.farm_name, d.crop_user_id].join("_");
            if (unique_list.indexOf(val) === -1) {
                unique_list.push(val);
            }
        });

        return unique_list.length;
    };

    getFieldCount = project_data => {
        var unique_list = [];

        project_data.forEach(d => {
            const val = [d.farm_name, d.crop_user_id, d.field_label].join("_");
            if (unique_list.indexOf(val) === -1) {
                unique_list.push(val);
            }
        });

        return unique_list.length;
    };

    render() {
        const { classes, project } = this.props;
        const { expanded } = this.state;

        if (!project || !project.crops_label) return "Unknown";

        // var user_field = 0;
        var user_acres = 0;
        var user_ids = [];
        project.user_data.forEach(function(pd) {
            // user_field++;
            user_acres = user_acres + pd.field_acres;
            if (user_ids.indexOf(pd.crop_user_id) === -1) user_ids.push(pd.crop_user_id);
        });

        const farm_count = this.getFarmCount(project.project_data);
        const field_count = this.getFieldCount(project.project_data);

        var farm_names = [];
        var fields = [];
        // var acres = 0;
        var cropyears = [];
        project.project_data.forEach(function(pd) {
            if (user_ids.indexOf(pd.crop_user_id) === -1) user_ids.push(pd.crop_user_id);
            if (farm_names.indexOf(pd.farm_name) === -1) farm_names.push(pd.farm_name);

            const fld = [pd.farm_name, pd.field_label].join("_");
            if (fields.indexOf(fld) === -1) {
                fields.push(fld);
                // acres += pd.field_acres;
            }
            const cy = [pd.farm_name, pd.crop_user_id, pd.field_label, pd.year].join("_");
            if (cropyears.indexOf(cy) === -1) {
                cropyears.push(cy);
            }
        });

        return (
            <Grid item xs={12} className={classes.factsContainer}>
                <Grid
                    container
                    spacing={24}
                    className={classNames(classes.panelContainer, !expanded && classes.hidden)}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Typography style={{ display: "inline-block" }}>
                            <b>Project Type:</b> <span className={classes.greenText}>{project.type_label}</span>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </Typography>
                        <Typography style={{ display: "inline-block" }}>
                            <b>Crops Analyzed:</b>{" "}
                            <span className={classes.greenText}>{project.crops_label.join(", ")}</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.factBoxContainer}>
                            <FactBox classes={classes} value={user_ids.length} label={"FARMERS"} />
                            <FactBox classes={classes} value={farm_count} label={"FARMS"} />
                            <FactBox classes={classes} value={field_count} label={"FIELDS"} />
                            <FactBox classes={classes} value={cropyears.length} label={"CROP YEARS"} />
                            <FactBox classes={classes} value={project.field_acres.toFixed()} label={"ENTERED ACRES"} />
                        </div>
                    </Grid>
                </Grid>
                {/* {expanded && (
                    <div>
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: false })} className={classes.panelText}>
                            <ExpandLess className={classes.button} />
                            <span className={classes.floatRightFacts}>Hide Quick Facts</span>
                        </Typography>
                    </div>
                )}
                {!expanded && (
                    <div className={classes.marginTop}>
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: true })} className={classes.panelText}>
                            <EyeIcon className={classes.button} />
                            <span className={classes.floatRightFacts}>View Quick Facts</span>
                        </Typography>
                    </div>
                )} */}
            </Grid>
        );
    }
}

export default withStyles(styles)(ProjectFacts);
