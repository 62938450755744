import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    passwordError: {
        color: "red",
        "text-align": "center",
        "font-size": 16
    }
};

class AccountConfirmation extends Component {
    componentDidMount() {
        this.props.authAccountConfirm({
            uid: this.props.match.params["uid"],
            token: this.props.match.params["token"]
        });
    }

    render() {
        const { classes, accountConfirm } = this.props;
        return (
            <AppContainer>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <Typography variant="title" gutterBottom>
                            Account Activation
                        </Typography>
                        <Typography gutterBottom>
                            {accountConfirm.pending && "Activating account..."}
                            {accountConfirm.success && "Your account has been activated!"}
                            {accountConfirm.error && (
                                <div className={classes.passwordError}>{accountConfirm.error}</div>
                            )}
                        </Typography>
                        {(accountConfirm.success || !accountConfirm.error) && (
                            <Typography gutterBottom>
                                <Link style={{ color: "#808080" }} to="/">
                                    Return to Home Page
                                </Link>{" "}
                                to log in.
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

AccountConfirmation = connect(
    state => ({ accountConfirm: (state.auth && state.auth.accountConfirm) || {} }),
    authActions
)(AccountConfirmation);

export default withStyles(styles)(withRouter(AccountConfirmation));
