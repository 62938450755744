import React from "react";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-form";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DeleteIcon from "@mui/icons-material/Close";

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: "relative"
    },
    optionContainer: {
        display: "flex"
    },
    suggestion: {
        display: "block"
    },
    option: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionLabel: {
        marginRight: "10px",
        marginTop: "8px"
    },
    optionLabelError: {
        marginRight: "10px",
        marginTop: "8px",
        color: "red"
    },
    root: {
        backgroundColor: theme.palette.primary.main
    },
    rootText: {
        color: "#fff"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noPadding: {
        paddingBottom: 0 + " !important"
    },
    minHeight: {
        minWidth: 600
    },
    expansionResults: {
        minHeight: 250,
        maxHeight: 250,
        width: "100%",
        overflowY: "auto"
    },
    indentGrid: {
        paddingLeft: 10
    },
    textField: {
        marginTop: 0,
        marginBottom: 0
    },
    button: {},
    leftIcon: {
        marginRight: theme.spacing(1)
    }
});

class cropOptionsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cropOptions: [],
            selectedCrops: [],
            filterString: "",
            showFTMCropsFirst: false,
            cropContains: "0"
        };
    }

    onCropContainsChange = e => {
        this.setState({ cropContains: e.target.value });
    };

    clearSelectedCrops = () => {
        this.setState({
            selectedCrops: []
        });
    };

    onShowFTMCropsFirstClick = e => {
        this.setState({ showFTMCropsFirst: !this.state.showFTMCropsFirst });
    };

    updateFilterString = event => {
        this.setState({ filterString: event.target.value.toLowerCase() });
    };

    filterCrops = () => {
        let cropOptions = this.state.cropOptions;
        let compareValues = this.state.filterString.trim().split(" ");

        if (compareValues.length === 0) {
            cropOptions.forEach(cropOption => {
                cropOption["show"] = true;
            });
        } else {
            cropOptions.forEach(cropOption => {
                let found = true;
                compareValues.forEach(compareValue => {
                    if (cropOption.name.toLowerCase().includes(compareValue) === false) {
                        found = false;
                    }
                });
                cropOption["show"] = found;
            });
        }
        return cropOptions.filter(option => {
            return option["show"] === true;
        });
    };

    handleCropsSelectChange = event => {
        let selectedCropIds = this.state.selectedCrops;
        let index = selectedCropIds.indexOf(event.target.value);

        if (event.target.checked === true) {
            if (index === -1) {
                selectedCropIds.push(event.target.value);
            }
        } else {
            selectedCropIds = selectedCropIds.filter(cropId => {
                return cropId !== event.target.value;
            });
        }

        this.setState({ selectedCrops: selectedCropIds });
    };

    onSaveClick = a => {
        this.props.onSaveClick({
            selectedCrops: this.state.selectedCrops,
            showFTMCropsFirst: this.state.showFTMCropsFirst,
            cropContains: this.state.cropContains
        });
    };

    componentDidMount() {
        this.setState({
            cropContains: this.props.cropContains,
            cropOptions: this.props.cropOptions,
            showFTMCropsFirst: this.props.showFTMCropsFirst,
            selectedCrops: this.props.alreadySelectedCrops
        });
    }

    render() {
        const { classes } = this.props;

        const filteredCrops = this.filterCrops();
        const cropsToShow = filteredCrops;

        const ftmCropsToShow = this.state.showFTMCropsFirst
            ? filteredCrops.filter(crop => {
                  return !!crop.fpp_crop_id;
              })
            : [];
        const nonFTMCropsToShow = this.state.showFTMCropsFirst
            ? filteredCrops.filter(crop => {
                  return !crop.fpp_crop_id;
              })
            : [];

        return (
            <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.minHeight }}>
                <DialogTitle className={classes.root}>
                    <Typography variant="title" className={classes.rootText}>
                        Select Crops (required)
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogHeight}>
                    <Form dontValidateOnMount="true" validateOnSubmit="true">
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Typography gutterBottom>
                                    Select from the list below one or more crops that should be contained in the crop
                                    rotation template. By default, the search finds templates that contain any one or
                                    more of the crops you select. You can check "Contains ONLY these crops" to find
                                    templates that contains only the selected crops.
                                </Typography>
                                <Typography>
                                    You should also select from the list below any cover crops if you are searching for
                                    templates that include cover crops. Click on Find Crops to quickly locate a crop in
                                    the list.
                                </Typography>

                                <RadioGroup
                                    value={this.state.cropContains}
                                    onChange={this.onCropContainsChange}
                                    style={{ display: "flex", flexDirection: "row" }}>
                                    {this.props.cropContainsOptions.map(option => (
                                        <FormControlLabel
                                            value={option.id}
                                            control={<Radio color="primary" disableRipple={true} />}
                                            label={option.label}
                                            labelPlacement="start"
                                        />
                                    ))}
                                </RadioGroup>

                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="crop-search"
                                            label="Find Crops"
                                            type="search"
                                            className={classes.textField}
                                            margin="normal"
                                            variant="filled"
                                            onChange={e => this.updateFilterString(e)}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            disabled={this.state.selectedCrops.length === 0 ? true : false}
                                            className={classes.button}
                                            variant="raised"
                                            color="primary"
                                            onClick={this.clearSelectedCrops}>
                                            <DeleteIcon className={classes.leftIcon} />
                                            Clear Selected Crops
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            control={
                                                <Switch
                                                    checked={this.state.showFTMCropsFirst}
                                                    onChange={this.onShowFTMCropsFirstClick}
                                                    // value="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label="List Field to Market Crops first"
                                        />
                                    </Grid>
                                </Grid>

                                <div style={{ "max-height": "250px", "min-height": "250px", "overflow-y": "auto" }}>
                                    {this.state.showFTMCropsFirst === true && (
                                        <Grid item xs={12}>
                                            <Typography>Field to Market crops:</Typography>
                                        </Grid>
                                    )}

                                    {this.state.showFTMCropsFirst === true &&
                                        ftmCropsToShow.map(option =>
                                            option["show"] === true ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.indentGrid}
                                                    title={option["cropNotes"]}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disableRipple={true}
                                                                checked={
                                                                    this.state.selectedCrops.filter(crop => {
                                                                        return crop === option.id;
                                                                    }).length > 0
                                                                }
                                                                onChange={this.handleCropsSelectChange}
                                                                value={option.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={option.name}
                                                    />
                                                </Grid>
                                            ) : (
                                                <div />
                                            )
                                        )}

                                    {this.state.showFTMCropsFirst === true && ftmCropsToShow.length === 0 && (
                                        <Grid item xs={12}>
                                            <Typography gutterBottom>&nbsp; No crops found.</Typography>
                                        </Grid>
                                    )}

                                    {this.state.showFTMCropsFirst === true && (
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    )}

                                    {this.state.showFTMCropsFirst === true && (
                                        <Grid item xs={12}>
                                            <Typography className={classes.gutterTop}>
                                                Non Field to market Crops:
                                            </Typography>
                                        </Grid>
                                    )}

                                    {this.state.showFTMCropsFirst === true &&
                                        nonFTMCropsToShow.map(option =>
                                            option["show"] === true ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.indentGrid}
                                                    title={option["cropNotes"]}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disableRipple={true}
                                                                checked={
                                                                    this.state.selectedCrops.filter(crop => {
                                                                        return crop === option.id;
                                                                    }).length > 0
                                                                }
                                                                onChange={this.handleCropsSelectChange}
                                                                value={option.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={option.name}
                                                    />
                                                </Grid>
                                            ) : (
                                                <div />
                                            )
                                        )}

                                    {this.state.showFTMCropsFirst === true && nonFTMCropsToShow.length === 0 && (
                                        <Grid item xs={12}>
                                            <Typography>&nbsp; No crops found.</Typography>
                                        </Grid>
                                    )}

                                    {this.state.showFTMCropsFirst === false &&
                                        cropsToShow.map(option =>
                                            option["show"] === true ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.indentGrid}
                                                    title={option["cropNotes"]}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disableRipple={true}
                                                                checked={
                                                                    this.state.selectedCrops.filter(crop => {
                                                                        return crop === option.id;
                                                                    }).length > 0
                                                                }
                                                                onChange={this.handleCropsSelectChange}
                                                                value={option.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={option.name}
                                                    />
                                                </Grid>
                                            ) : (
                                                <div />
                                            )
                                        )}
                                    {this.state.showFTMCropsFirst === false && cropsToShow.length === 0 && (
                                        <Grid item xs={12}>
                                            No crops found.
                                        </Grid>
                                    )}
                                </div>
                            </form>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.props.onCancelClick()}
                        color="primary"
                        className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={this.onSaveClick}
                        className={classes.buttonMargin}
                        variant="raised"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(cropOptionsDialog);
