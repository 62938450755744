import React, { Component } from "react";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

import LinearProgress from "@mui/material/LinearProgress";
import Button from "../../common/ButtonWrapper";
import { Card, CardHeader, CardContent, CardActions } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PageHeader from "../../common/PageHeader";
import AppContainer from "../../common/AppContainer";
import Facts from "../Facts";

import { CropYear } from "../fieldactivities/models";
import { Metric } from "../../../api/models";
import { connect } from "react-redux";

import { convert } from "./payload_conversion";

// Grab an instance of the cropYear through the ID in the URL
const getCropYear = CropYear.selectByUrlId(cropYear => ({
    crop_name: cropYear.crop.name,
    farm_name: cropYear.field.farm.name,
    field_name: cropYear.field._fields.name,
    field_id: cropYear.field._fields.id,
    fieldObject: cropYear.field._fields,
    ...cropYear.ref
}));

const getMetrics = Metric.selectAll();

const RenderJSON = ({ value }) => {
    return (
        <pre>
            <code>{JSON.stringify(value, null, 2)}</code>
        </pre>
    );
};

const QDMPPayload = ({ results, qdmpPayload, onButtonClick }) => (
    <Card>
        <CardHeader title={"QDMP Payload"} />
        <CardContent>
            {!results["Calculator"] && <div>Run The calculator first to get the UI Payload.</div>}
            {!!results["Calculator"] && (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">UI Payload</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RenderJSON value={results["Calculator"]["payload"]} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {qdmpPayload !== null && (
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="title">Result</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RenderJSON value={qdmpPayload} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            )}
        </CardContent>
        <CardActions>
            <Button onClick={onButtonClick} color="primary" variant="raised" disabled={!results["Calculator"]}>
                Make QDMP Payload
            </Button>
        </CardActions>
    </Card>
);

const MetricResult = ({ name, result, onButtonClick }) => (
    <Card>
        <CardHeader title={name} />

        <CardContent>
            <Grid container spacing={24}>
                {!result && (
                    <Grid item xs={12}>
                        <p>Not Run Yet</p>
                    </Grid>
                )}

                {result && result.payload && (
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">Payload</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RenderJSON value={result.payload} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {result && result.pending && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}

                {result && result.success && (
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">Result</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RenderJSON value={result.result} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {result && result.error && (
                    <Grid item xs={12}>
                        <p>Error: {result.error}</p>
                    </Grid>
                )}
            </Grid>
        </CardContent>
        <CardActions>
            <Button onClick={onButtonClick} color="primary" variant="raised">
                {result ? "Recalculate" : "Calculate"}
            </Button>
        </CardActions>
    </Card>
);

class MetricTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qdmpPayload: null
        };
    }

    runMetric(metricId) {
        const { cropYear, runMetric } = this.props;
        runMetric(metricId, cropYear.id);
    }

    makeQDMPPayload(result) {
        console.log("--> makeQDMPPayload");
        let qdmpPayload = "blah";
        if (result["Calculator"] && result["Calculator"]["payload"]) {
            qdmpPayload = convert(result);
        } else {
            qdmpPayload = null;
        }
        this.setState({ qdmpPayload: qdmpPayload });
    }

    render() {
        const { cropYear, metrics } = this.props;
        const results = cropYear.metrics || {};
        const { qdmpPayload } = this.state;

        // Hide debug APIs that only work with QDMP payloads
        const isInternal = metric => metric.inputs.indexOf("json.wkt") === -1;

        return (
            <AppContainer
                authenticated
                header={
                    <>
                        <PageHeader
                            color="field"
                            title={
                                (cropYear.field_name || cropYear.field_id) + " on " + (cropYear.farm_name || "New Farm")
                            }
                        />
                        <Facts field={cropYear.fieldObject} />
                    </>
                }>
                <Grid container spacing={24}>
                    <Grid container xs={12} spacing={16}>
                        <Grid item xs={12}>
                            <QDMPPayload
                                results={results}
                                qdmpPayload={qdmpPayload}
                                onButtonClick={() => this.makeQDMPPayload(results)}
                            />
                        </Grid>

                        {metrics.map(
                            metric =>
                                isInternal(metric) && (
                                    <Grid item xs={12}>
                                        <MetricResult
                                            name={metric.id}
                                            result={results[metric.id]}
                                            onButtonClick={() => this.runMetric(metric.id)}
                                        />
                                    </Grid>
                                )
                        )}
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

MetricTest = connect(
    (state, ownProps) => ({
        cropYear: getCropYear(state, ownProps),
        metrics: getMetrics(state, ownProps)
    }),
    {
        ...Metric.actions
    }
)(MetricTest);

export default MetricTest;
