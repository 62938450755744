import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Toolbar from "@mui/material/Toolbar";

import * as navActions from "../common/actions";
import * as scenarioActions from "./actions";

const styles = {
    flex: {
        flex: 1
    },
    termDialog: {
        width: 1000,
        maxWidth: "80%"
    },
    marginLeft: {
        marginLeft: 24
    },
    linkColor: {
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline"
    },
    toolbar: {
        backgroundColor: "#00a2e5",
        "& h1": {
            color: "#fff"
        }
    },
    acceptButton: {
        backgroundColor: "#00a2e5",
        color: "#fff",
        border: "1.5px solid #00a2e5",
        boxShadow: "none",
        float: "right",
        marginRight: 10
    },
    cancelButton: {
        backgroundColor: "#fff",
        color: "#606060",
        border: "1.5px solid #606060",
        boxShadow: "none",
        float: "left",
        "&:hover": {
            backgroundColor: "#606060",
            color: "#fff",
            border: "1.5px solid #fff"
        }
    },
    marginTop: {
        marginTop: 5
    },
    title: {
        color: "#000"
    },
    box: {
        backgroundColor: "#e4e4e4",
        borderRadius: 8,
        padding: 16,
        margin: 16
    }
};

class ScenarioTermsDialog extends Component {
    accept = () => {
        this.props.scenarioAcceptTerms();
    };

    cancel = () => {
        this.props.history.push("/dashboard");
    };

    render() {
        const { scenarioState, classes } = this.props;

        const open = !scenarioState.acceptedTerms;

        return (
            <Dialog open={open} classes={{ paper: classes.termDialog }}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="headline" className={classes.flex}>
                        Terms of Use
                    </Typography>
                </Toolbar>
                <DialogContent>
                    <DialogContentText className={classes.marginTop}>
                        <Typography gutterBottom>
                            A Memorandum of Understanding (MOU) between the United States Department of Agriculture
                            (USDA), Natural Resources Conservation Service (NRCS) and Field to Market establishes roles
                            and responsibilities governing the use by Field to Market of NRCS models to ensure
                            appropriate citation, maintenance, updating, and application within the Fieldprint Platform.
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Data:</strong> The COMET-Planner data provided through this implementation in the
                            Fieldprint Platform were developed by the COMET-Planner team with public and private
                            foundation funds and may be freely used. Note the COMET-Planner dataset will be periodically
                            updated by the COMET-Planner team, and Field to Market will update the data when available.
                        </Typography>
                        <Typography>
                            <strong>Citation:</strong> When referring to COMET-Planner data, the source must be cited as
                            follows:
                        </Typography>
                        <div className={classes.box}>
                            <Typography>
                                Amy Swan, Mark Easter, Adam Chambers, Kevin Brown, Stephen A. Williams, Jeff Creque,
                                John Wick, and Keith Paustian. COMET-Planner: Carbon and Greenhouse Gas Evaluation for
                                NRCS Conservation Practice Planning. Accessed through the Field to Market Fieldprint
                                Platform implementation of COMET-Planner with data revised on September 26, 2022 at
                                http://comet-planner.com/ (COMET Planner Version 3.0).
                            </Typography>
                        </div>
                        <Typography gutterBottom>
                            <strong>Copyright and Ownership:</strong> Colorado State University and the USDA Natural
                            Resources Conservation Service retain rights to ownership of COMET-Planner data and
                            information. Copyright by 2020 Colorado State University. All COMET-Planner data and
                            information presented through the Field to Market Fieldprint Platform COMET-Planner
                            implementation are protected by United States copyright laws and applicable international
                            copyright treaties.
                        </Typography>
                        <Typography gutterBottom>
                            <strong>No Warranty.</strong> The COMET-Planner data and information is provided “as is,”
                            without warranty of any kind, either express or implied.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <Button onClick={this.cancel} className={classes.cancelButton}>
                        Return to Dashboard
                    </Button>
                    <Button onClick={this.accept} className={classes.acceptButton}>
                        Acknowledge Terms and Continue
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }
}

ScenarioTermsDialog = connect(
    state => ({
        scenarioState: state.scenario
    }),
    {
        ...navActions,
        ...scenarioActions
    }
)(ScenarioTermsDialog);

export default withStyles(styles)(withRouter(ScenarioTermsDialog));
