import React from "react";

const CloseIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} fill="none" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="white" />
        <path d="M7 17L16.8995 7.10051" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 7.00001L16.8995 16.8995" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default CloseIcon;
