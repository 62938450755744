import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import classNames from "classnames";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";

import { connect } from "react-redux";
import { Delegation } from "./models";
import { reloadAll } from "../common/orm";
import { authStartImpersonate } from "./actions";
import { dbFetch } from "../../api/fetch";

const allDelegations = Delegation.selectAll();

const styles = {
    centerAlign: {
        textAlign: "center !important"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    gutterBottom: {
        marginBottom: ".6em"
    },
    progress: {
        color: "#4CAF50",
        position: "absolute",
        marginLeft: 50,
        marginTop: -30
    },
    progress2: {
        color: "#4CAF50",
        position: "absolute",
        marginLeft: 50,
        marginTop: -40
    },
    error: {
        color: "red",
        marginTop: ".6em"
    },
    confirmBorder: {
        backgroundColor: "#DDDDDD",
        border: "1px solid",
        padding: 16
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    checkboxWidth: {
        minWidth: "44px",
        width: "44px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    bold: {
        fontWeight: 900
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    filterDiv: {
        backgroundColor: "#eeeeee",
        marginBottom: 16,
        marginRight: 12,
        marginLeft: -12
    }
};

const delegateFromData = [
    { id: "delegate_to_name", numeric: false, label: "Name", allowSort: true },
    { id: "delegate_to_label", numeric: false, label: "Email", allowSort: true },
    { id: "requested", numeric: false, label: "Date Requested", allowSort: true },
    { id: "accepted", numeric: false, label: "Date Accepted", allowSort: true },
    { id: "current_status", numeric: false, label: "Status", allowSort: true },
    { id: "actions", numeric: false, label: "Actions", allowSort: false }
];

const delegateToData = [
    { id: "delegate_from_id", numeric: false, label: "", allowSort: false },
    { id: "delegate_from_name", numeric: false, label: "Name", allowSort: true },
    { id: "delegate_from_label", numeric: false, label: "Email", allowSort: true },
    { id: "requested", numeric: false, label: "Date Requested", allowSort: true },
    { id: "accepted", numeric: false, label: "Date Accepted", allowSort: true },
    { id: "current_status", numeric: false, label: "Status", allowSort: true },
    { id: "actions", numeric: false, label: "Actions", allowSort: false }
];

class DelegateFromTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "delegate_to_name",
            page: 0,
            rowsPerPage: 10,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null
        };
    }

    resendRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doResendRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to resend the validation email?"
        });
    };

    doResendRequest = obj => {
        dbFetch("/api/db/delegations/" + obj.id + "/resend/" + obj.token, {
            method: "POST"
        }).then(() => {
            this.setState({ deleteDialogOpen: false });
        });
    };

    cancelRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doCancelRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to cancel the request?"
        });
    };

    doCancelRequest = obj => {
        const { ormDelegationReload } = this.props;
        dbFetch("/api/db/delegations/" + obj.id + "/cancel/" + obj.token, {
            method: "POST"
        }).then(() => {
            ormDelegationReload();
            this.setState({ deleteDialogOpen: false });
        });
    };

    removeRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doRemoveRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to remove access to the user?"
        });
    };

    doRemoveRequest = obj => {
        const { ormDelegationReload } = this.props;
        dbFetch("/api/db/delegations/" + obj.id + "/revoke/" + obj.token, {
            method: "POST"
        }).then(() => {
            ormDelegationReload();
            this.setState({ deleteDialogOpen: false });
        });
    };

    actionHandler = obj => {
        switch (obj.action) {
            case "doRemoveRequest":
                this.doRemoveRequest(obj);
                break;
            case "doCancelRequest":
                this.doCancelRequest(obj);
                break;
            case "doResendRequest":
                this.doResendRequest(obj);
                break;
            default:
                break;
        }
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.delegates.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.delegates.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString + "T00:00:00");
        return d.toLocaleDateString();
    }

    render() {
        const { delegates, classes } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            deleteDialogOpen,
            deleteDialogText,
            deleteDialogConfirmAction
        } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, delegates.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={delegateFromData}
                />
                <TableBody>
                    {delegates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(d => (
                        <TableRow hover key={d.id}>
                            <CustomTableCell>{d.delegate_to_name}</CustomTableCell>
                            <CustomTableCell>{d.delegate_to_label}</CustomTableCell>
                            <CustomTableCell>{this.formatDate(d.requested)}</CustomTableCell>
                            <CustomTableCell>{this.formatDate(d.accepted)}</CustomTableCell>
                            <CustomTableCell>{d.label}</CustomTableCell>
                            <CustomTableCell>
                                {d.current_status === "Pending" && (
                                    <div>
                                        <Tooltip title="Resend Request">
                                            <Button
                                                onClick={() => this.resendRequest(d.id, d.token)}
                                                className={classes.buttonWidth}>
                                                <EmailIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Cancel Request">
                                            <Button
                                                onClick={() => this.cancelRequest(d.id, d.token)}
                                                className={classes.buttonWidth}>
                                                <CancelIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                                {d.current_status === "Active" && (
                                    <Tooltip title="Remove Access">
                                        <Button
                                            onClick={() => this.removeRequest(d.id, d.token)}
                                            className={classes.buttonWidth}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                )}
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && delegates.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={7} />
                        </TableRow>
                    )}
                    {delegates.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                There are no delegate users at this time.
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {delegates.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={7}
                                count={delegates.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
                <WarningDialog
                    confirmAction={() => this.actionHandler(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Are you sure?"
                    text={deleteDialogText}
                    confirmText="Ok"
                    cancelText="Cancel"
                />
            </Table>
        );
    }
}

class DelegateToTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "delegate_from_name",
            page: 0,
            rowsPerPage: 10,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            selectedUser: null
        };
    }

    acceptRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doAcceptRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to accept the request?"
        });
    };

    doAcceptRequest = obj => {
        const { ormDelegationReload } = this.props;
        dbFetch("/api/db/delegations/" + obj.id + "/accept/" + obj.token).then(() => {
            ormDelegationReload();
            this.setState({ deleteDialogOpen: false });
        });
    };

    rejectRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doRejectRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to reject the request?"
        });
    };

    doRejectRequest = obj => {
        const { ormDelegationReload } = this.props;
        dbFetch("/api/db/delegations/" + obj.id + "/reject/" + obj.token).then(() => {
            ormDelegationReload();
            this.setState({ deleteDialogOpen: false });
        });
    };

    deleteRequest = (id, token) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: { action: "doDeleteRequest", id: id, token: token },
            deleteDialogText: "Are you sure you wish to remove access to the user?"
        });
    };

    doDeleteRequest = obj => {
        const { ormDelegationReload } = this.props;
        dbFetch("/api/db/delegations/" + obj.id + "/revoke/" + obj.token, {
            method: "POST"
        }).then(() => {
            ormDelegationReload();
            this.setState({ deleteDialogOpen: false });
        });
    };

    actionHandler = obj => {
        switch (obj.action) {
            case "doDeleteRequest":
                this.doDeleteRequest(obj);
                break;
            case "doRejectRequest":
                this.doRejectRequest(obj);
                break;
            case "doAcceptRequest":
                this.doAcceptRequest(obj);
                break;
            default:
                break;
        }
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.delegates.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.delegates.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString + "T00:00:00");
        return d.toLocaleDateString();
    }

    checkApplied = (checked, id) => {
        if (checked) {
            this.setState({ selectedUser: id });
        } else {
            this.setState({ selectedUser: null });
        }
    };

    render() {
        const { delegates, classes } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            deleteDialogOpen,
            deleteDialogText,
            deleteDialogConfirmAction,
            selectedUser
        } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, delegates.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={delegateToData}
                />
                <TableBody>
                    {delegates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(d => (
                        <TableRow hover key={d.id}>
                            <CustomTableCell>
                                {d.current_status === "Active" && (
                                    <Checkbox
                                        checked={d.id === selectedUser}
                                        color="secondary"
                                        eventHandle={checked => this.checkApplied(checked, d.id)}
                                        field={"FROM_" + d.id}
                                        label=""
                                        align={classes.checkboxWidth}
                                    />
                                )}
                            </CustomTableCell>
                            <CustomTableCell className={d.id === selectedUser && classes.bold}>
                                {d.delegate_from_name}
                            </CustomTableCell>
                            <CustomTableCell className={d.id === selectedUser && classes.bold}>
                                {d.delegate_from_label}
                            </CustomTableCell>
                            <CustomTableCell className={d.id === selectedUser && classes.bold}>
                                {this.formatDate(d.requested)}
                            </CustomTableCell>
                            <CustomTableCell className={d.id === selectedUser && classes.bold}>
                                {this.formatDate(d.accepted)}
                            </CustomTableCell>
                            <CustomTableCell className={d.id === selectedUser && classes.bold}>
                                {d.label}
                            </CustomTableCell>
                            <CustomTableCell>
                                {d.current_status === "Pending" && (
                                    <div>
                                        <Tooltip title="Accept">
                                            <Button
                                                onClick={() => this.acceptRequest(d.id, d.token)}
                                                className={classes.buttonWidth}>
                                                <DoneIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Reject">
                                            <Button
                                                onClick={() => this.rejectRequest(d.id, d.token)}
                                                className={classes.buttonWidth}>
                                                <CancelIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                                {d.current_status === "Active" && (
                                    <Tooltip title="Delete Access">
                                        <Button
                                            onClick={() => this.deleteRequest(d.id, d.token)}
                                            className={classes.buttonWidth}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                )}
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && delegates.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={7} />
                        </TableRow>
                    )}
                    {delegates.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                There are no delegate users at this time.
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {delegates.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={7}
                                count={delegates.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
                <WarningDialog
                    confirmAction={() => this.actionHandler(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Are you sure?"
                    text={deleteDialogText}
                    confirmText="Ok"
                    cancelText="Cancel"
                />
            </Table>
        );
    }
}

class Delegate extends Component {
    state = {
        loading: false,
        loading2: false,
        message: null,
        nameFilter: null
    };

    errorValidator = values => {
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validateConfirm = confirm => {
            return !confirm ? "Please confirm." : null;
        };
        return {
            email: validateEmail(values.email),
            confirm: validateConfirm(values.confirm)
        };
    };

    activateDelegateAccess = values => {
        const { ormDelegationReload } = this.props;
        this.setState({ loading: true, message: null });
        return dbFetch("/api/db/delegations/delegate", {
            method: "POST",
            body: JSON.stringify({
                delegate_to_id: values.email
            })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    this.setState({ message: data.message, loading: false });
                } else {
                    ormDelegationReload();
                    this.form.resetAll();
                    this.setState({
                        message:
                            "Your request was sent to the email address provided. You may want to contact the user to advise them the request was sent. Email requests such as this can be caught in spam filters and not seen by the user.",
                        loading: false
                    });
                }
            });
    };

    switchUser = values => {
        const { authState, delegations, reload, authStartImpersonate, history } = this.props;
        this.setState({ loading2: true });
        // Pull ID from checkbox field
        delete values.filter;
        const delegation_record_id = parseInt(Object.keys(values)[0].split("_")[1]);
        const delegate_from_id = delegations.filter(d => d.id === delegation_record_id)[0].delegate_from_id;

        authStartImpersonate();
        return dbFetch("/impersonate/" + delegate_from_id)
            .then(resp => resp.json())
            .then(data => {
                // Set flags on user object
                authState.user._impersonate = data._impersonate;
                authState.user._impersonateName = data._impersonateName;
                authState.user._impersonateEmail = data._impersonateEmail;
                // Reload all data
                reload();
                history.push("/dashboard");
            });
    };

    render() {
        const { classes, delegations, authState, ormDelegationReload } = this.props;
        const { loading, message, loading2, nameFilter } = this.state;

        var delegatesAccessToYou = delegations.filter(d => d.delegate_to_id === authState.user.id);

        if (nameFilter) {
            delegatesAccessToYou = delegatesAccessToYou.filter(
                d =>
                    d.delegate_from_label.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1 ||
                    d.delegate_from_name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
            );
        }

        return (
            <AppContainer authenticated color="support" title="Delegate Access">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            You can delegate to other Fieldprint Platform users access to your account. Users to which
                            you delegate access can perform certain functions on your behalf. For example, you may
                            engage with a Certified Crop Advisor that will enter data on your behalf. This includes
                            adding, modifying and deleting farms, fields, and crop years; entering, modifying and
                            deleting fieldlevel input data information for a crop year to include land, location,
                            agronomic and management information; create, modify, delete and apply a crop rotation
                            system to a crop year; and generate, export and access your Fieldprint Results and
                            fieldlevel input data. Delegated users will have full control of your account except that
                            they cannot access or modify your user profile or reassign delegate access. You can revoke
                            delegate access at any time.
                        </Typography>
                        <Typography variant="title" gutterBottom className={classes.gutterTop}>
                            Delegate Access
                        </Typography>
                        <Typography>
                            To delegate access, enter the Email of the person to which you want to delegate access. The
                            user must already have an account in the Fieldprint Platform. It is assumed you have engaged
                            the user and they are anticipating the request. Once requested, the user will recieve an
                            email asking that they accept or reject you request. Check the status of the request in the
                            Access Delegation List table below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8} xl={6}>
                        <Form
                            dontValidateOnMount="true"
                            getApi={el => (this.form = el)}
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            onSubmit={this.activateDelegateAccess}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            {message && <Typography className={classes.error}>{message}</Typography>}
                                            <TextField field="email" label="Email" fullWidth margin="normal" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Checkbox
                                                field="confirm"
                                                label="We ask that you check the box to fully acknowledge that you understand the data privacy implications of allowing delegate access to your account. Except for access to your User Profile, the user to which you are delegating access will have full control of your data in the Fieldprint Platform"
                                                fullWidth
                                                align={classNames(
                                                    classes.centerAlign,
                                                    classes.gutterBottom,
                                                    classes.confirmBorder
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="raised" type="submit" color="primary" disabled={loading}>
                                                Send Request
                                            </Button>
                                            {loading && (
                                                <div>
                                                    <CircularProgress size={24} className={classes.progress} />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="title" gutterBottom className={classes.gutterTop}>
                            Users with access to your account
                        </Typography>
                        <Typography gutterBottom>
                            The table below tracks the users to which you have delegated access along with the status.
                            An "Active" status indicates the user has current access to (full control of) your account.
                            A "Request Pending" status indicates the user to which you sent a request has not yet
                            accepted. You have the option of resending the original request. Finally, you have the
                            option to remove access from a user at any time.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DelegateFromTable
                            delegates={delegations.filter(d => d.delegate_from_id === authState.user.id)}
                            authState={authState}
                            ormDelegationReload={ormDelegationReload}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="title" gutterBottom className={classes.gutterTop}>
                            Users that have provided access to you
                        </Typography>
                        <Typography gutterBottom>
                            The table below tracks users that have delegated access to you. You should have receieved an
                            email request to accept or reject a particular request. If needed, you can accept or reject
                            pending request from this table. You can also delete your delegated access. To begin
                            entering data on behalf of another user, select the user in the table and then click "Switch
                            to User". To end entering data on behalf of the user, click on Restore Access from the User
                            Options menu or just log out and log back in with your own credentials.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Form dontValidateOnMount="true" validateOnSubmit="true" onSubmit={this.switchUser}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={4} md={3} lg={2} xl={1} style={{ marginTop: 24 }}>
                                            <Button
                                                variant="raised"
                                                type="submit"
                                                color="primary"
                                                disabled={loading2}
                                                className={classes.gutterBottom}>
                                                Switch User
                                            </Button>
                                            {loading2 && (
                                                <div>
                                                    <CircularProgress size={24} className={classes.progress2} />
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={8} md={9} lg={10} xl={11} className={classes.filterDiv}>
                                            <TextField
                                                eventHandle={value => this.setState({ nameFilter: value })}
                                                field="filter"
                                                label="Filter"
                                                className={classes.standardMargin}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <DelegateToTable
                                        delegates={delegatesAccessToYou}
                                        authState={authState}
                                        nameFilter={nameFilter}
                                        ormDelegationReload={ormDelegationReload}
                                        classes={classes}
                                    />
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Delegate = connect(
    (state, props) => ({
        authState: state.auth,
        delegations: allDelegations(state)
    }),
    {
        ...Delegation.actions,
        reload: reloadAll,
        authStartImpersonate
    }
)(Delegate);

export default withStyles(styles)(withRouter(Delegate));
