import React, { Component } from "react";
import Typography from "../../../common/TypographyWrapper";
import Grid from "../../../common/GridWrapper";

import {
    Bar,
    BarChart,
    CartesianGrid,
    YAxis,
    Tooltip,
    XAxis,
    Label,
    Rectangle,
    LabelList,
    ReferenceLine,
    ResponsiveContainer
} from "recharts";

const climateBoxes = {
    backgroundColor: "#7fbc41",
    height: "50px",
    width: "100px",
    margin: "auto",
    border: "solid black 1px",
    display: "grid",
    placeItems: "center"
};

const formatDate = dateString => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth();
    return `${months[month]}/${day}`;
};

const DayMonthAxisTick = props => {
    const { x, y, payload } = props;

    const value = formatDate(payload.value);

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {value}
            </text>
        </g>
    );
};

class SoilConservationInsights extends Component {
    state = {
        activeIndex: 1
    };

    render() {
        const { printable } = this.props;

        const data = [
            {
                date: "1/1/2023",
                val: 0.3
            },
            {
                date: "1/15/2023",
                val: 0.4
            },
            {
                date: "2/1/2023",
                val: 0.3
            },
            {
                date: "2/15/2023",
                val: 0.6
            },
            {
                date: "3/1/2023",
                val: 1.2
            },
            {
                date: "3/15/2023",
                val: 1.1
            },
            {
                date: "4/1/2023",
                val: 1.6
            },
            {
                date: "4/15/2023",
                val: 1.25
            },
            {
                date: "5/1/2023",
                val: 2.25
            },
            {
                date: "5/15/2023",
                val: 2.0
            },
            {
                date: "6/1/2023",
                val: 1.25
            },
            {
                date: "6/15/2023",
                val: 1.4
            },
            {
                date: "7/1/2023",
                val: 1.1
            },
            {
                date: "7/15/2023",
                val: 1.9
            },
            {
                date: "8/1/2023",
                val: 1.4
            },
            {
                date: "8/15/2023",
                val: 1.75
            },
            {
                date: "9/1/2023",
                val: 1.4
            },
            {
                date: "9/15/2023",
                val: 1.25
            },
            {
                date: "10/1/2023",
                val: 1.1
            },
            {
                date: "10/15/2023",
                val: 0.95
            },
            {
                date: "11/1/2023",
                val: 0.6
            },
            {
                date: "11/15/2023",
                val: 0.6
            },
            {
                date: "12/1/2023",
                val: 0.5
            },
            {
                date: "12/15/2023",
                val: 0.3
            }
        ];

        const avgCropInt = [
            {
                type: "Wheat, winter, grain [190 days, 1:1]",
                val: 0.07,
                fill: "#fee4c6"
            },
            {
                type: "Corn, grain seed [174 days, 1:2]",
                val: 0.45,
                fill: "#121ef9"
            },
            {
                type: "Soybean, grain [365 days, 1:3]",
                val: 1.92,
                fill: "#137f1a"
            }
        ];

        const erosionExt = [
            {
                type: "Minimum",
                val: 0.0,
                fill: "#38dac1",
                lblFill: "#66666"
            },
            {
                type: "Average",
                val: 1.2,
                fill: "#ffa500",
                lblFill: "#66666"
            },
            {
                type: "Maximum",
                val: 9.1,
                fill: "#a52a2a",
                lblFill: "#fff"
            }
        ];

        if (printable) {
            return null;
        }
        return (
            <>
                <Grid container justifyContent="center" spacing={printable ? 8 : 24} alignItems="center">
                    <Grid item xs={12}>
                        <div style={{ marginLeft: "44px", marginTop: "20px" }}>
                            <Typography>
                                <u>
                                    <b>Soils:</b>
                                </u>{" "}
                                The dominant soil used in the water and wind erosion model is Longford sility clay loam,
                                3 to 7 percent slopes. The dominant soil component is Longford.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ marginLeft: "44px" }}>
                            <Typography>
                                <u>
                                    <b>Climate:</b>
                                </u>{" "}
                                The water and wind erosion models use a 40 year climate dataset and simulate running it
                                for 100 years. Below are statistics from the climate stations used for this field.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        style={{
                            marginLeft: "24px"
                        }}>
                        <Grid item xs={6} sm={3}>
                            <div
                                style={{
                                    marginLeft: "24px",
                                    marginBottom: "0px",
                                    marginTop: "0px"
                                }}>
                                <table
                                    style={{
                                        marginLeft: "0px",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        borderSpacing: "10px"
                                    }}>
                                    <tr>
                                        <td>
                                            <div style={climateBoxes}>16.51 (in/yr)</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Driest Year Precipitation</td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div
                                style={{
                                    marginLeft: "24px",
                                    marginBottom: "0px",
                                    marginTop: "0px"
                                }}>
                                <table
                                    style={{
                                        marginLeft: "0px",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        borderSpacing: "10px"
                                    }}>
                                    <tr>
                                        <td>
                                            <div style={climateBoxes}>27.86 (in/yr)</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Average Annual Precipitation</td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div
                                style={{
                                    marginLeft: "24px",
                                    marginBottom: "0px",
                                    marginTop: "0px"
                                }}>
                                <table
                                    style={{
                                        marginLeft: "0px",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        borderSpacing: "10px"
                                    }}>
                                    <tr>
                                        <td>
                                            <div style={climateBoxes}>38.57 (in/yr)</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Wettest Year Precipitation</td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div
                                style={{
                                    marginLeft: "24px",
                                    marginBottom: "0px",
                                    marginTop: "0px"
                                }}>
                                <table
                                    style={{
                                        marginLeft: "0px",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        borderSpacing: "10px"
                                    }}>
                                    <tr>
                                        <td>
                                            <div style={climateBoxes}>3.92 (in/yr)</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Average Annual Runoff</td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ marginLeft: "44px", width: "100%", height: "300px" }}>
                        <ResponsiveContainer width={"95%"}>
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 25,
                                    right: 5,
                                    left: 5,
                                    bottom: 10
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tick={<DayMonthAxisTick />}>
                                    <Label
                                        value="2 week intervals"
                                        offset={-8}
                                        position="insideBottom"
                                        style={{
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            color: "#666666",
                                            fontStyle: "italic"
                                        }}
                                    />
                                </XAxis>
                                <YAxis>
                                    <Label
                                        value="Precipitation (in)"
                                        angle={270}
                                        position="insideLeft"
                                        offset={0}
                                        style={{
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            color: "#666666",
                                            fontStyle: "italic",
                                            textAnchor: "middle"
                                        }}
                                    />
                                </YAxis>
                                <Tooltip />
                                <Bar dataKey="val" fill="#add8e6" activeBar={<Rectangle fill="pink" stroke="blue" />} />

                                <text x={70} y={14} fill="#666666" textAnchor="left" dominantBaseline="central">
                                    <tspan fontSize="14" fontWeight={"Bold"}>
                                        Precipitation (in)
                                    </tspan>
                                </text>
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ marginLeft: "44px", marginBottom: "10px" }}>
                            <Typography>
                                Precipitation is based on the averages of 2 week intervals over the length of the
                                management rotation over the simulation length. For example, if the length of the
                                simulation was 100 years and the rotation length was 4 years then each Jun 1-15 from
                                years 1,5,9,13... is averaged. Within each 2 week period the daily values are summed for
                                that years entry.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ marginLeft: "44px", marginBottom: "10px" }}>
                            <Typography>
                                <u>
                                    <b>Modeled Soil Loss from Water Erosion</b>
                                </u>
                            </Typography>
                            <Typography>
                                The graphs below is additional information from the water erosion prediction project
                                (WEPP) model.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12} style={{ marginLeft: "44px", width: "100%", height: "300px" }}>
                            <ResponsiveContainer width={"95%"}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 25,
                                        right: 5,
                                        left: 25,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" tick={<DayMonthAxisTick />}>
                                        <Label
                                            value="2 week intervals"
                                            offset={-8}
                                            position="insideBottom"
                                            style={{
                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                color: "#666666",
                                                fontStyle: "italic"
                                            }}
                                        />
                                    </XAxis>
                                    <YAxis>
                                        <Label
                                            value="Average Soil Loss (ton/A)"
                                            angle={-90}
                                            position="insideLeft"
                                            style={{
                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                color: "#666666",
                                                fontStyle: "italic",
                                                textAnchor: "middle"
                                            }}
                                        />
                                    </YAxis>
                                    <Tooltip />
                                    <Bar
                                        dataKey="val"
                                        fill="#add8e6"
                                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                                    />
                                    <text x={90} y={14} fill="#666666" textAnchor="left" dominantBaseline="central">
                                        <tspan fontSize="14" fontWeight={"Bold"}>
                                            Average Soil Loss (ton/A)
                                        </tspan>
                                    </text>
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    marginLeft: "24px",
                                    marginBottom: "0px",
                                    marginTop: "0px"
                                }}>
                                <table
                                    style={{
                                        marginLeft: "10px",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        borderSpacing: "10px"
                                    }}>
                                    <tr>
                                        <td>
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    padding: "15px",
                                                    margin: "auto",
                                                    border: "solid black 1px",
                                                    display: "grid",
                                                    placeItems: "center"
                                                }}>
                                                This chart gives a better understanding of the critical times of soil
                                                erosion and where management can be applied to reduce erosion.
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ marginLeft: "44px", marginTop: "10px" }}>
                            <Typography>
                                Soil loss is based on the averages of 2 week intervals over the length of the management
                                rotation over the simulation length. For example, if the length of the simulation was
                                100 years and the rotation length was 4 years then each Jun 1-15 from years 1,5,9,13...
                                is averaged. Within each 2 week period the daily values are summed for that years entry.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container xs={12} style={{ marginTop: "20px", marginLeft: "44px" }}>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ height: "350px", marginTop: "10px", minWidth: "275px" }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={400}
                                        height={300}
                                        data={avgCropInt}
                                        margin={{
                                            top: 25,
                                            right: 5,
                                            left: 5,
                                            bottom: 5
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="type"></XAxis>
                                        <YAxis>
                                            <Label
                                                value="tons/ac"
                                                angle={-90}
                                                position="insideLeft"
                                                style={{
                                                    fontFamily: "'Source Sans Pro',sans-serif",
                                                    color: "#666666",
                                                    fontStyle: "italic",
                                                    textAnchor: "middle"
                                                }}
                                            />
                                        </YAxis>
                                        <Tooltip />
                                        <Bar
                                            dataKey="val"
                                            fill={"fill"}
                                            activeBar={<Rectangle fill="pink" stroke="blue" />}>
                                            <LabelList dataKey="val" fill="#fff" position="insideTop" />
                                        </Bar>

                                        <text x={70} y={14} fill="#666666" textAnchor="left" dominantBaseline="central">
                                            <tspan fontSize="14" fontWeight={"Bold"}>
                                                Average Crop Interval Erosion (tons/ac)
                                            </tspan>
                                        </text>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid style={{ height: "170px", marginTop: "10px", marginLeft: "44px" }}>
                                <div>
                                    <Typography>
                                        Graph represents the average annual erosion by crop interval represented in the
                                        management rotation modeled. The interval represents the days from the day after
                                        harvest of the previous crop to the day of harvest of the crop.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ height: "350px", marginTop: "10px", minWidth: "275px" }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={400}
                                        height={300}
                                        data={erosionExt}
                                        margin={{
                                            top: 25,
                                            right: 5,
                                            left: 5,
                                            bottom: 5
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="type"></XAxis>
                                        <YAxis>
                                            <Label
                                                value="tons/ac/yr"
                                                angle={-90}
                                                position="insideLeft"
                                                style={{
                                                    fontFamily: "'Source Sans Pro',sans-serif",
                                                    color: "#666666",
                                                    fontStyle: "italic",
                                                    textAnchor: "middle",
                                                    scaleToFit: "true"
                                                }}
                                            />
                                        </YAxis>
                                        <Tooltip />
                                        <Bar
                                            dataKey="val"
                                            fill={"fill"}
                                            activeBar={<Rectangle fill="pink" stroke="blue" />}>
                                            <LabelList dataKey="val" fill="#fff" position="insideTop" />
                                        </Bar>
                                        <ReferenceLine
                                            y={4.5}
                                            stroke={"red"}
                                            strokeWidth={2}
                                            label={
                                                <Label value="T=5" offset={0} fill="#666666" position="insideBottom" />
                                            }
                                        />
                                        <text x={70} y={14} fill="#666666" textAnchor="left" dominantBaseline="central">
                                            <tspan fontSize="14" fontWeight={"Bold"}>
                                                Erosion Extremes (tons/ac/yr)
                                            </tspan>
                                        </text>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid style={{ height: "100px", marginTop: "10px", marginLeft: "44px" }}>
                                <div>
                                    <Typography>
                                        Graph represents the extreme model erosion values from the driest climate event
                                        to extreme precipitation event.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default SoilConservationInsights;
