import React, { Component, useState } from "react";
import Typography from "../../../common/TypographyWrapper";
import { getValue } from "../../../../api/utils";
import { Bar, YAxis, Tooltip, ReferenceLine, BarChart, XAxis, LabelList } from "recharts";
import Grid from "../../../common/GridWrapper";
import { withTableStyles, TH, TD } from "../Table";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import InfoIcon from "@mui/icons-material/Info";

import { FormLabel } from "@mui/material";
import HelpLabel from "../../../common/HelpLabel";

const PATHWAYS = {
    surfaceP: "Surface Phosphorus",
    subsurfaceP: "Subsurface Phosphorus",
    surfaceN: "Surface Nitrogen",
    subsurfaceN: "Subsurface Nitrogen"
};

const risk_sensitivity = {
    riskMitigationScore: {
        FILL: "#fff",
        LBL: "Risk Mitigation Credits (RMC)",
        SHORTLBL: "RMC",
        DESC:
            "Risk Mitigation Credits are earned for implementing management practices that reduce nutrient losses. Higher scores are desirable.",
        HELP:
            "Scores from the categories below are totaled for each loss pathway. That sum is divided by the Field Sensitivity Score to calculate the Overall Score for each loss pathway.",
        IDX: 0,
        SUB: []
    },
    fieldSensitivityScore: {
        FILL: "red",
        LBL: "Field Sensitivity Score (FSS)",
        SHORTLBL: "FSS",
        DESC:
            "The Field Sensitivity Score is the potential for nutrient losses either by runoff beyond the edge of the field (surface loss) or leaching below the roots zone (subsurface loss). The Field Sensitivity Score is based on soil properties and local climate characteristics and therefore is not affected by your management practices. Lower scores will require less mitigation, higher scores will require more mitigation.",
        HELP: "Field Sensitivity Score be modified by producer. Determined by soil type, texture, structure, slope.",
        IDX: 1,
        SUB: []
    }
};

const credit_compnent = {
    residueCredit: {
        FILL: "#67001f",
        LBL: "Tillage Timing and Crop Residue",
        LBL2: "Credit for crop type and time interval between tillage and planting next crop.",
        SHORTLBL: "Tillage Timing",
        HELP:
            "Improve your score by including high residue crops in the rotation and shortening the interval between tillage and planting the next crop.",
        IDX: 2,
        SUB: []
    },
    coverCredit: {
        FILL: "#b2182b",
        LBL: "Cover Crop Practices",
        LBL2: "Credit for planting winter hardy cover crops  and timing of establishment.",
        SHORTLBL: "Cover Crop",
        HELP: "Improve score by planting winter-hardy cover crops and establishing cover crops early.",
        IDX: 3,
        SUB: []
    },
    ratioCredit: {
        FILL: "#ff6e3f",
        LBL: "Nutrient Application Efficiency",
        LBL2: "Credit  for applying the correct amount of N and P based on a soil test.",
        SHORTLBL: "Nutrient Efficiency",
        HELP:
            "Improve score by apply only the amount of N or P that will be used by the crop in that crop year, based on a soil test. Negative scores are possible and undesirable.",
        IDX: 4,
        SUB: []
    },
    timingMethod: {
        FILL: "#60defa",
        LBL: "Nutrient Application Timing and Method",
        LBL2: "Credit for applying N and P at the right time and right location.",
        SHORTLBL: "Nutrient Timing",
        HELP:
            "Improve your score by making nutrient applications at, or after planting and using split applications (timing) and incorporating nutrients or fertigating (method)",
        IDX: 5,
        SUB: []
    },
    creditAdjustment: {
        FILL: "#7fbc41",
        LBL: "Multi-Year Intended Effect Phosphorus Application Credit Adjustment",
        LBL2:
            "Penalty for applying phosphorus with the intention of carryover for subsequent crops for three or more years.",
        SHORTLBL: "Phosphorus Credit",
        HELP:
            "Penalty for applying phosphorus with the intention of carryover for subsequent crops for three or more years.",
        IDX: 6,
        SUB: []
    },
    mgmtTechCredit: {
        FILL: "#4393c3",
        LBL: "Nutrient Application Rate and Type",
        LBL2: "Credit for applying N and P at the right rate and in the right form.",
        SHORTLBL: "Nutrient Application",
        HELP:
            "Improve score by only applying P for current crop year, adjusting N applications based on carryover from previous year, amount of  N from organic (including manure) and inorganic sources applied.",
        IDX: 7,
        SUB: []
    },
    conservationPractice: {
        FILL: "#053061",
        LBL: "NRCS Conservation Practices",
        LBL2: "Credit for installed/applied  conservation practices approved by the NRCS.",
        SHORTLBL: "Conservation Practices",
        HELP:
            "Improve score by installing edge-of-field practices known to intercept run-off or drainage and remove excess nutrients prior to return to waterways.",
        IDX: 8,
        SUB: []
    }
};

const styles = theme => ({
    label: {},
    totalRow: {
        borderBottomWidth: "3px solid #ccc"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#f44336"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    vals: {
        textAlign: "center"
    }
});

export function BarNameLbl(props) {
    const { y, lbl } = props;

    return (
        <text x={0} y={y} dy={18} fill={"8884d8"} fontSize={10} textAnchor="right" fontWeight={"bold"}>
            {lbl}
        </text>
    );
}

export function BarLbl(props) {
    const { x, y, width, value } = props;
    const val = Math.round(value * 10) / 10;

    if (val <= 0 || val >= 50) {
        return (
            <text
                x={x + width / 2}
                dx={-8}
                y={y}
                dy={15}
                fill={"white"}
                fontSize={10}
                textAnchor="center"
                fontWeight={"bold"}>
                {val}
            </text>
        );
    } else {
        return (
            <text
                x={x + width}
                dx={5}
                y={y}
                dy={15}
                fill={"#666666"}
                fontSize={10}
                textAnchor="right"
                fontWeight={"bold"}>
                {val}
            </text>
        );
    }
}

const waterQualitySquareSVG = {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
    marginTop: "10px",
    marginBottom: "10px"
};

const CreditCompLbl = {
    float: "left",
    marginRight: "5px",
    verticalAlign: "middle",
    marginLeft: "30px"
};

const CreditCompTopLbl = {
    float: "left",
    marginRight: "5px",
    verticalAlign: "middle"
};

const CreditCompExpLbl = {
    float: "left",
    marginRight: "5px",
    maxWidth: "90%",
    marginLeft: "30px"
};

const waterQualityTable = {
    marginLeft: "15px",
    marginTop: "15px"
};

function lossPathwayKeys() {
    var retval = [];

    Object.keys(PATHWAYS).map((keyName, keyIndex) => {
        retval.push(keyName);
        return null;
    });

    return retval;
}

export function MitigationHeader(props) {
    const { cc, kn, gd } = props;

    const summaryData = [];

    lossPathwayKeys().map(i => {
        summaryData[i] = 0;

        Object.keys(risk_sensitivity).map((key, ki) => {
            if (gd[i].hasOwnProperty(key)) {
                summaryData[i] += gd[i][key];
            }
            return null;
        });
        return null;
    });

    return (
        <>
            <tr style={{ backgroundColor: "#f3eff5" }}>
                <TD colspan="2">
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>{cc.LBL}</td>
                        <td style={{ verticalAlign: "middle", paddingBottom: "7px" }}>
                            <FormLabel component="legend" classes={{ error: styles.error }}>
                                <HelpLabel inputLabel={""} helpText={cc.HELP} />
                            </FormLabel>
                        </td>
                    </tr>
                </TD>
                {kn === "total"
                    ? lossPathwayKeys().map(i => (
                          <TD style={{ textAlign: "center" }}>
                              <Typography style={{ textAlign: "center" }}>{summaryData[i]}</Typography>
                          </TD>
                      ))
                    : lossPathwayKeys().map(i => (
                          <TD style={{ textAlign: "center" }}>
                              <Typography style={{ textAlign: "center" }}>{gd[i][kn]}</Typography>
                          </TD>
                      ))}
            </tr>
            <tr>
                <TD colspan="6">
                    <Typography style={{ textAlign: "left" }} color={"#969696"} size={9}>
                        {cc.DESC}
                    </Typography>
                </TD>
            </tr>
        </>
    );
}

export function Submetric(props) {
    const { cc, kn, gd, onMouseEnter } = props;

    const summaryData = [];

    lossPathwayKeys().map(i => {
        summaryData[i] = 0;

        Object.keys(credit_compnent).map((key, ki) => {
            if (gd[i].hasOwnProperty(key) && key !== "fieldSensitivityScore") {
                summaryData[i] += gd[i][key];
            }
            return null;
        });
        return null;
    });

    if (cc.SUB !== undefined && cc.SUB.length > 0) {
        return <ExpandableSubmetric cc={cc} kn={kn} gd={gd} onMouseEnter={onMouseEnter} />;
    }
    return (
        <>
            <tr
                className="highlightRow"
                onMouseEnter={onMouseEnter}
                data-index={cc.IDX}
                style={{ borderBottom: kn === "total" ? "3px solid #ccc" : "" }}>
                <TD>
                    <svg style={waterQualitySquareSVG}>
                        {kn === "fieldSensitivityScore" ? (
                            <>
                                <rect x="0" y="10" width={10} height={4} fill={"red"} />
                                <rect x="10" y="10" width={10} height={4} fill={"green"} />
                            </>
                        ) : (
                            <rect width={20} height={20} fill={cc.FILL} />
                        )}
                    </svg>
                </TD>
                <TD>
                    <tr>
                        <td style={kn === "fieldSensitivityScore" || kn === "total" ? CreditCompTopLbl : CreditCompLbl}>
                            <b>{cc.LBL}</b> - {cc.LBL2}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <FormLabel component="legend" classes={{ error: styles.error }}>
                                <HelpLabel inputLabel={""} helpText={cc.HELP} />
                            </FormLabel>
                        </td>
                    </tr>
                </TD>
                {kn === "total"
                    ? lossPathwayKeys().map(i => (
                          <TD>
                              <span>{summaryData[i] === null ? "N/A" : summaryData[i]}</span>
                          </TD>
                      ))
                    : lossPathwayKeys().map(i => (
                          <TD>
                              <span>{gd[i][kn] === null ? "N/A" : gd[i][kn]}</span>
                          </TD>
                      ))}
            </tr>
        </>
    );
}

function ExpandableSubmetric(props) {
    const { cc, kn, gd, onMouseEnter } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <tr className="highlightRow" onMouseEnter={onMouseEnter} data-index={cc.IDX}>
                <TD>
                    <svg style={waterQualitySquareSVG}>
                        <rect width={20} height={20} fill={cc.FILL} />
                    </svg>
                </TD>
                <TD first toggle open={open} setOpen={setOpen}>
                    <tr style={CreditCompExpLbl}>
                        <td>
                            <b>{cc.LBL}</b> - {cc.LBL2}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            <FormLabel component="legend" classes={{ error: styles.error }} style={{ float: "left" }}>
                                <HelpLabel inputLabel={""} helpText={cc.HELP} />
                            </FormLabel>
                        </td>
                    </tr>
                </TD>
                {lossPathwayKeys().map(i => (
                    <TD>
                        <span>{gd[i][kn] === null ? "N/A" : gd[i][kn]}</span>
                    </TD>
                ))}
            </tr>

            {cc.SUB.map(scc => (
                <tr data-index={scc.IDX}>
                    <TD first collapsible open={open} subcomponent></TD>
                    <TD collapsible open={open} subcomponent>
                        {scc.LBL}
                    </TD>
                    {lossPathwayKeys().map(i => (
                        <TD collapsible open={open} subcomponent>
                            <span>{scc[i] === null ? "N/A" : scc[i]}</span>
                        </TD>
                    ))}
                </tr>
            ))}
        </>
    );
}

class WaterQuality extends Component {
    state = {
        activeIndex: null
    };

    render() {
        const { indicator, classes, printable } = this.props,
            indexName = getValue(indicator, "fieldprintResult.index"),
            lossPathway = getValue(indicator, "lossPathway"),
            conservation_practices = getValue(indicator, "derivedInputs.practices"),
            management_practices = getValue(indicator, "derivedInputs.techniques");

        let tooltip = "";

        buildPathwayData();

        function buildPathwayData() {
            Object.keys(PATHWAYS).map((keyName, keyIndex) => {
                lossPathway[keyName]["name"] = PATHWAYS[keyName];

                return null;
            });

            if (conservation_practices !== undefined) {
                credit_compnent.conservationPractice.SUB = [];
                credit_compnent.conservationPractice.SUB = conservation_practices.map(s => {
                    return {
                        FILL: "#ffffff",
                        LBL: s.name,
                        IDX: -1,
                        surfaceP: s.surfaceP,
                        subsurfaceP: s.subsurfaceP,
                        surfaceN: s.surfaceN,
                        subsurfaceN: s.subsurfaceN,
                        SUB: []
                    };
                });
            }

            if (management_practices !== undefined) {
                credit_compnent.mgmtTechCredit.SUB = [];
                credit_compnent.mgmtTechCredit.SUB = management_practices.map(s => {
                    return {
                        FILL: "#ffffff",
                        LBL: s.name,
                        IDX: -1,
                        surfaceP: s.surfaceP,
                        subsurfaceP: s.subsurfaceP,
                        surfaceN: s.surfaceN,
                        subsurfaceN: s.subsurfaceN,
                        SUB: []
                    };
                });
            }
        }

        let onTableLeave = e => {
            this.setState({
                activeIndex: null
            });
        };

        let onTableRowEnter = e => {
            var index = parseInt(e.target.parentNode.dataset.index);

            this.setState({
                activeIndex: index
            });
        };

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length && tooltip) {
                return (
                    <div className="custom-tooltip">
                        {payload
                            .filter(p => p.dataKey === tooltip)
                            .map((k, i) => (
                                <p className="label">
                                    {credit_compnent[k.dataKey].LBL} <br /> {k.value}
                                </p>
                            ))}
                    </div>
                );
            }

            return null;
        };

        if (indexName !== "STEP") {
            return (
                <Typography>
                    Error generating table: Expected to find STEP results, but found {indexName || "no"} results
                    instead. You may need to recompute the metrics.
                </Typography>
            );
        }

        return (
            <>
                <div style={{ marginLeft: "12px", marginBottom: "10px" }}>
                    <Typography variant="headline" gutterBottom>
                        Water Quality Insights
                    </Typography>
                    <Typography>
                        For each nutrient loss pathway, the Risk Management Score (RMS) is divided by the Field
                        Sensitivity Score (FSS). The bar charts represent the mitigation points for each component of
                        the mitigation scoring.
                    </Typography>
                </div>

                <div>
                    <Grid
                        container
                        xs={12}
                        style={{
                            "text-align": "center"
                        }}>
                        {lossPathwayKeys().map(i => (
                            <Grid key={i} xs={12} md={6} lg={3}>
                                <table style={{ width: "320px", marginTop: "5px" }}>
                                    <tr>
                                        <td style={{ width: "55px" }}></td>
                                        <td>
                                            <Typography>
                                                <b>{PATHWAYS[i]}</b>
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "55px" }}></td>
                                        <td style={{ paddingLeft: "85px" }}>
                                            <Typography
                                                color={"white"}
                                                size={10}
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    borderRadius: "4px",
                                                    backgroundColor: lossPathway[i].ratio >= 1 ? "darkgreen" : "red",
                                                    width: "80px"
                                                }}>
                                                {lossPathway[i].ratio >= 1 ? "Mitigated" : "Improve"}
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>

                                <BarChart
                                    width={265}
                                    height={280}
                                    data={[lossPathway[i]]}
                                    layout="vertical"
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: 50,
                                        bottom: 0
                                    }}
                                    style={{
                                        marginLeft: "45px",
                                        marginTop: "10px",
                                        outline: "1px dashed #d3d3d3",
                                        outlineOffset: "5px"
                                    }}>
                                    <XAxis
                                        type="number"
                                        domain={[-15, 60]}
                                        ticks={[-20, -10, 0, 10, 20, 30, 40, 50, 60]}
                                    />
                                    <YAxis dataKey="LBL" type="category" axisLine={false} tickLine={false} />
                                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "white" }} />

                                    {Object.keys(credit_compnent).map(
                                        (keyName, keyIndex) =>
                                            keyName !== "fieldSensitivityScore" && (
                                                <Bar
                                                    isAnimationActive={false}
                                                    dataKey={keyName}
                                                    key={keyName}
                                                    strokeWidth={1}
                                                    stroke={
                                                        this.state.activeIndex === null
                                                            ? null
                                                            : this.state.activeIndex === credit_compnent[keyName].IDX
                                                            ? "#bdbdbd"
                                                            : null
                                                    }
                                                    opacity={
                                                        this.state.activeIndex === null
                                                            ? 1
                                                            : this.state.activeIndex === credit_compnent[keyName].IDX
                                                            ? 1
                                                            : 0.3
                                                    }
                                                    fill={credit_compnent[keyName].FILL}
                                                    onMouseOver={() => (tooltip = keyName)}>
                                                    <LabelList
                                                        dataKey={keyName}
                                                        postition="insideLeft"
                                                        content={<BarLbl />}
                                                    />
                                                    <LabelList
                                                        dataKey={keyName}
                                                        content={<BarNameLbl lbl={credit_compnent[keyName].SHORTLBL} />}
                                                        postition="left"
                                                    />
                                                </Bar>
                                            )
                                    )}
                                    <ReferenceLine x={0} stroke="#666666" strokeWidth={1} />
                                </BarChart>
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <table className={classes.dataTable} style={waterQualityTable}>
                    <thead>
                        <tr>
                            <TH colspan="2"></TH>
                            <TH colspan="4">
                                <Typography style={{ textAlign: "center" }} color={"white"} size={12}>
                                    Loss Pathway
                                </Typography>
                            </TH>
                        </tr>
                        <tr style={{ textWrap: "wrap" }}>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center" }} color={"white"} size={12}>
                                    Legend
                                </Typography>
                            </TH>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center" }} color={"white"} size={12}>
                                    Scoring Components
                                </Typography>
                            </TH>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center", width: "90px" }} color={"white"} size={12}>
                                    Surface Phosphorus
                                </Typography>
                            </TH>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center", width: "90px" }} color={"white"} size={12}>
                                    Subsurface Phosphorus
                                </Typography>
                            </TH>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center", width: "90px" }} color={"white"} size={12}>
                                    Surface Nitrogen
                                </Typography>
                            </TH>
                            <TH printable={printable}>
                                <Typography style={{ textAlign: "center", width: "90px" }} color={"white"} size={12}>
                                    Subsurface Nitrogen
                                </Typography>
                            </TH>
                        </tr>
                        <tr style={{ borderBottom: "3px solid #454955" }}>
                            <TD>
                                <Typography style={{ textAlign: "center", color: "#f15d22" }}>
                                    <QueryStatsIcon />
                                </Typography>
                            </TD>
                            <TD colspan="5">
                                <Typography style={{ textAlign: "left", fontWeight: "bold" }} size={12}>
                                    Compare your risk mitigation credits to your field sensitivity score.
                                </Typography>
                            </TD>
                        </tr>
                        {Object.keys(risk_sensitivity).map((keyName, keyIndex) => (
                            <MitigationHeader cc={risk_sensitivity[keyName]} kn={keyName} gd={lossPathway} />
                        ))}

                        <tr
                            style={{
                                borderBottom: "2px solid #454955",
                                borderTop: "2px solid #454955"
                            }}>
                            <td colspan="2" printable={printable} style={{ backgroundColor: "#f3eff5" }}>
                                <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
                                    Are the pathways mitigated?
                                </Typography>
                                <Typography style={{ textAlign: "left" }}>
                                    For each pathway, if the sum of your Risk Mitigation Credits is greater than or
                                    equal to the Field Sensitivity Score, the pathway is mitigated. If the sum of your
                                    Risk Mitigation Credits is less than your Field Sensitivity Score, improvement is
                                    needed.
                                </Typography>
                            </td>
                            {lossPathwayKeys().map(i => (
                                <td
                                    printable={printable}
                                    style={{
                                        backgroundColor: lossPathway[i].ratio >= 1 ? "darkgreen" : "red",
                                        border: "1px solid white"
                                    }}>
                                    <Typography
                                        color={"white"}
                                        size={10}
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bold"
                                        }}>
                                        {lossPathway[i].ratio >= 1 ? "Mitigated" : "Improve"}
                                    </Typography>
                                    <Typography
                                        color={"white"}
                                        fontSize={11}
                                        style={{
                                            textAlign: "center"
                                        }}>
                                        ({lossPathway[i].riskMitigationScore} - {lossPathway[i].fieldSensitivityScore} ={" "}
                                        {lossPathway[i].riskMitigationScore - lossPathway[i].fieldSensitivityScore})
                                    </Typography>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <TD>
                                <Typography style={{ textAlign: "center", color: "#f15d22" }}>
                                    <InfoIcon />
                                </Typography>
                            </TD>
                            <TD colspan="5">
                                <Typography style={{ textAlign: "left", fontWeight: "bold" }} size={12}>
                                    Details on your Risk Mitigation Credits
                                </Typography>
                            </TD>
                        </tr>
                        <tr style={{ borderBottom: "2px solid #454955" }}>
                            <TD colspan="6">
                                <Typography style={{ textAlign: "left" }} color={"#969696"} size={9}>
                                    Below is a breakdown of your Risk Mitigation Credits. The credits are also shown in
                                    the charts above. This allows you to understand how your management practices
                                    influence the ability to mitigate risk to nutrient loss.
                                </Typography>
                            </TD>
                        </tr>
                    </thead>
                    <tbody onMouseLeave={onTableLeave}>
                        {Object.keys(credit_compnent).map((keyName, keyIndex) => (
                            <Submetric
                                cc={credit_compnent[keyName]}
                                kn={keyName}
                                gd={lossPathway}
                                printable={printable}
                                onMouseEnter={onTableRowEnter}
                            />
                        ))}
                        <tr>
                            <td colSpan={6} style={{ backgroundColor: "#454955" }}></td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default withTableStyles()(WaterQuality);
