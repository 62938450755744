import CRLMOD_DATA from "../../crlmod_data.json";
import REFERENCE_DATA from "../../reference_data.json";

var OperationsRequest = Promise.resolve(CRLMOD_DATA.Operation);
var CropsRequest = Promise.resolve(REFERENCE_DATA.CRCrops);
var ResiduesRequest = Promise.resolve(CRLMOD_DATA.Residue);

var GroupOperationData = function(inData) {
    //  if any operation does not have an 'opGroup1' value, set it to the 'name'
    inData.map(x => (x.opGroup1 = x.opGroup1 === undefined ? x.name : x.opGroup1));

    function groupBy(data, keys) {
        if (keys.length === 0) return data;

        // The current key to perform the grouping on:
        var key = keys[0];

        // Loop through the data and construct buckets for
        // all of the unique keys:
        var groups = {};
        for (var i = 0; i < data.length; i++) {
            var row = data[i];
            var groupValue = row[key];

            if (groups[groupValue] === undefined) {
                groups[groupValue] = [];
            }

            groups[groupValue].push(row);
        }

        // Remove the first element from the groups array:
        keys.reverse();
        keys.pop();
        keys.reverse();

        // If there are no more keys left, we're done:
        if (keys.length === 0) return groups;

        // Otherwise, handle further groupings:
        for (var group in groups) {
            groups[group] = groupBy(groups[group], keys.slice());
        }

        return groups;
    }

    let tempGroups = groupBy(inData, ["opGroup1"]);

    let finalGroups = Object.keys(tempGroups).map(key => {
        return { name: key, options: tempGroups[key] };
    });

    //  Drop 'Construction, other' and 'No Operation' groups
    finalGroups = finalGroups.filter(function(group) {
        return group.name !== "Construction, other" && group.name !== "No Operation";
    });

    return finalGroups;
};

var removeDuplicateManagements = function(managements) {
    var ids = [];

    var returnManagements = [];

    managements.forEach(m => {
        if (ids.indexOf(m.id) === -1) {
            returnManagements.push(m);
            ids.push(m.id);
        }
    });

    return returnManagements;
};

var UpdateManagementData = function(inData) {
    let managements = inData.map(x => x.managements[0]);

    //  remove Strip Managements
    managements = managements.filter(m => {
        return m.path !== "Strip Managements";
    });
    managements = managements.map(x => Object.assign({}, x, { cmz: x["path"].split("\\")[0].split(" ")[1] }));

    // managements.forEach(x => {
    //     console.log(x);
    //     try {
    //         Object.assign({}, x, { type: x["path"].split("\\")[1].charAt(0) === "a" ? "single-year" : "multi-year" })
    //     } catch (e) {
    //         console.log(x);
    //     }
    // });

    managements = managements.map(x =>
        Object.assign({}, x, { type: x["path"].split("\\")[1].charAt(0) === "a" ? "single-year" : "multi-year" })
    );
    return managements;
};

var FilterManagementsData = function(results, filter_parameters, crlmodCrops) {
    let filtered_results = [];
    //  Filter by selected selectedCrops.
    let crop_filtered_results = [];
    let cover_crop_filtered_results = [];

    if (filter_parameters.cropContains === "0") {
        // Results must contain only the selectedCrops.
        results.forEach(result => {
            let resultCropIds = [];
            result.events.forEach(event => {
                if (event.crop !== undefined) {
                    if (resultCropIds.indexOf(event.crop.id) === -1) {
                        resultCropIds.push(event.crop.id.toString());
                    }
                }
            });

            if (filter_parameters.selectedCrops.length === resultCropIds.length) {
                let found = true;
                filter_parameters.selectedCrops.forEach(selectedCropId => {
                    if (resultCropIds.indexOf(selectedCropId.toString()) === -1) {
                        found = false;
                    }
                });
                if (found === true) {
                    filtered_results.push(result);
                }
            } else {
                console.log(resultCropIds);
            }
        });
    } else if (filter_parameters.cropContains === "1") {
        // Results contain any of the selectedCrops.
        results.forEach(result => {
            let resultCropIds = [];
            result.events.forEach(event => {
                if (event.crop !== undefined) {
                    if (resultCropIds.indexOf(event.crop.id) === -1) {
                        resultCropIds.push(event.crop.id.toString());
                    }
                }
            });

            for (let i = 0; i < filter_parameters.selectedCrops.length; i++) {
                if (resultCropIds.indexOf(filter_parameters.selectedCrops[i]) > -1) {
                    crop_filtered_results.push(result);
                    break;
                }
            }
        });

        if (filter_parameters.includeCoverCrop === true) {
            filtered_results = [...cover_crop_filtered_results];
        } else {
            filtered_results = [...crop_filtered_results];
        }
    }

    return filtered_results;
};

var GetTodaysDate = function() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }

    var today_str = yyyy + "-" + mm + "-" + dd;
    return today_str;
};

var GetGUID = function() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

var updateRotationEventsWithNewRealDates = function(events, firstDate = null) {
    let years = [];
    events.forEach(e => {
        years.push(e["date"].split("-")[0]);
    });
    if (firstDate) {
        years.push(firstDate.split("-")[0]);
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    years = years.filter(onlyUnique);
    years.sort();

    let year_maps = {};

    if (years.length === 1) {
        events = events.map(function(e) {
            const date_parts = e["date"].split("-");
            e["date"] = ["0001", date_parts[1], date_parts[2]].join("-");
            return e;
        });
    } else {
        let years_int = years.map(y => parseInt(y));
        let new_years = [];
        let temp_year = years_int[0];
        let index_year = 1;
        while (temp_year <= years_int[years_int.length - 1]) {
            if (years_int.indexOf(temp_year) >= 0) {
                new_years.push(pad(index_year, 4));
            }
            temp_year++;
            index_year++;
        }
        for (var i = 0; i < years.length; i++) {
            year_maps[years[i]] = new_years[i];
        }

        events = events.map(function(e) {
            let date_parts = e["date"].split("-");
            let new_year = year_maps[date_parts[0]];
            let new_date = [new_year, date_parts[1], date_parts[2]].join("-");
            e["date"] = new_date;
            return e;
        });
    }

    return events;
};

var pad = function(num, size) {
    var s = "0000000000" + num;
    return s.substr(s.length - size);
};

var addDurationToSearchResults = function(results) {
    results.forEach(result => {
        if (result["type"] === "single-year") {
            result["duration"] = 1;
        } else {
            let years = [];
            result.events.forEach(event => {
                years.push(parseInt(event["date"].split("-")[0]));
            });

            const unique = (value, index, self) => {
                return self.indexOf(value) === index;
            };

            years = years.filter(unique);
            result["duration"] = years.length;
        }
    });
    return results;
};

var remapRotationEvents = function(events, operations) {
    const rotationEvents =
        events && events.length > 0 && operations && operations.length > 0
            ? events.map(event => ({
                  id: event["id"],
                  index: event["index"] === undefined ? GetGUID() : event["index"],
                  date: event["date"],
                  day: [event["date"].split("-")[1], event["date"].split("-")[2]].join("-"),
                  year: "Year " + parseInt(event["date"].split("-")[0]).toString(),
                  operationName: event.operation ? event.operation.name : "",
                  operationId: event.operation ? event.operation.id : "",
                  stir: event.operation ? event.operation.stir : "",
                  crop: event.crop ? event.crop.name : "",
                  yield: event.crop ? event.crop.defaultYield : "",
                  yieldUnit: event.crop ? event.crop.yieldUnit : "",
                  residue: event.residue ? event.residue.name : "",
                  residueAmount: event.operation.add_residue ? event.operation.resAdded : "",
                  opGroup1: event.operation.opGroup1,
                  operationTip: event.operation && operations ? getOperationTip(operations, event.operation.id) : ""
              }))
            : [];

    // sort by date
    rotationEvents.sort(function(a, b) {
        a = a.date.split("-").join("");
        b = b.date.split("-").join("");
        return a > b ? 1 : a < b ? -1 : 0;
    });

    return rotationEvents;
};

var getOperationTip = function(operations, id) {
    if (operations.length === 0) {
        return null;
    }

    let matches = operations.filter(operation => operation.id.toString() === id.toString());
    if (matches.length > 0) {
        return matches[0].opNotes;
    }
    return null;
};

// C.f. soils/rotation.py: rotation-mark-current-crop
var validateRotationSystemEvents = function(events, crops) {
    function isHarvest(e) {
        var harvestOp_1 = "Harvest, biomass, residue";
        var harvestOp_2 = "Harvest, crops";
        if (e.opGroup1 === harvestOp_1 || e.opGroup1 === harvestOp_2) {
            return true;
        }
    }

    if (events === undefined || events.length === 0) {
        return ["Rotation is empty."];
    }

    const crcrops = {};
    (crops || []).forEach(crinfo => {
        crcrops[crinfo.name] = crinfo;
    });

    const errors = new Set();
    const double_rotation = events.concat(events);

    let harvest = false,
        current_crop = null,
        allCrops = new Set(),
        fppCrops = new Set();

    double_rotation.forEach(e => {
        if (e.crop) {
            const crinfo = crcrops[e.crop];
            if (!crinfo) {
                errors.add(`Unknown CR-LMOD crop "${e.crop}".`);
            }
            allCrops.add(e.crop);
            if (crinfo.fpp_crop_id) {
                fppCrops.add(e.crop);
            }
            if (crinfo.fpp_type === "primary") {
                if (current_crop) {
                    errors.add(`"${e.crop}" planted while "${current_crop.name}" is still on field.`);
                }
                current_crop = crinfo;
            }
        }

        if (isHarvest(e)) {
            current_crop = null;
            harvest = true;
        }
    });

    if (!allCrops.size) {
        errors.add("No crops planted.");
    } else if (!fppCrops.size) {
        errors.add("No FPP crops planted.");
    }

    if (!harvest) {
        errors.add("There must be at least one Harvest operation.");
    }

    return Array.from(errors);
};

var createBlankRotationSystem = function(ormRotationSystemCreate, projectId) {
    var rotationSystemObj = {
        name: "Custom",
        template: "Custom",
        template_name: "Custom",
        project: projectId ? { project_id: projectId } : null
    };

    return ormRotationSystemCreate(rotationSystemObj);
};

var createRotationSystemAndEvents = function(parameters) {
    const {
        ormRotationSystemCreate,
        ormRotationSystemUpdate,
        ormRotationEventCreate,
        rotationSystem,
        projectIds,
        operations,
        crops
    } = parameters;

    const events = updateRotationEventsWithNewRealDates(parameters.events);

    var systemId = null;

    if (projectIds) {
        projectIds.forEach(project_id => {
            rotationSystem["project"] = { project_id: project_id };
            systemId = ormRotationSystemCreate(rotationSystem);
            createRotationEvents(ormRotationEventCreate, systemId, events);
            updateRotationValid(ormRotationSystemUpdate, events, systemId, operations, crops);
        });

        if (projectIds.length === 1) {
            return systemId;
        } else {
            return null;
        }
    } else {
        systemId = ormRotationSystemCreate(rotationSystem);
        createRotationEvents(ormRotationEventCreate, systemId, events);
        updateRotationValid(ormRotationSystemUpdate, events, systemId, operations, crops);
        return systemId;
    }
};

var createRotationEvents = function(ormRotationEventCreate, systemId, events) {
    events.forEach(event =>
        ormRotationEventCreate({
            system: systemId,
            ...event
        })
    );
};

var updateRotationValid = (ormRotationSystemUpdate, events, systemId, operations, crops) => {
    let remapEvents = remapRotationEvents(events, operations);
    let validation = validateRotationSystemEvents(remapEvents, crops);
    let valid = false;

    if (remapEvents.length === 0) {
        valid = false;
    } else if (validation.length === 0) {
        valid = true;
    } else {
        valid = false;
    }

    ormRotationSystemUpdate({
        id: systemId,
        is_valid: valid
    });
};

export {
    updateRotationEventsWithNewRealDates,
    addDurationToSearchResults,
    OperationsRequest,
    CropsRequest,
    ResiduesRequest,
    GroupOperationData,
    removeDuplicateManagements,
    UpdateManagementData,
    FilterManagementsData,
    GetTodaysDate,
    GetGUID,
    pad,
    validateRotationSystemEvents,
    remapRotationEvents,
    getOperationTip,
    createBlankRotationSystem,
    createRotationSystemAndEvents,
    updateRotationValid
};
