import apikey from "../apikey.json";

var store;

function getApiUrl() {
    const instance = window.location.hostname.split(".")[0];
    let api;
    if (instance === "calculator") {
        api = "api";
    } else if (instance === "localhost") {
        return "https://api-build.fieldtomarket.org";
    } else {
        api = "api-" + instance;
    }
    return `https://${api}.fieldtomarket.org`;
}

export function apiFetch(url, opts = {}) {
    if (!url.startsWith("/api")) {
        url = getApiUrl() + url;
    }
    opts = {
        ...opts,
        headers: {
            Authorization: "Bearer " + apikey.apikey,
            "Content-Type": "application/json",
            Accept: "application/json",
            ...opts.headers
        }
    };
    return fetch(url, opts);
}

export function dbEffect({ url, ...opts }) {
    let mode;
    if (window.location.hostname === "localhost") {
        url = "https://build.fieldtomarket.org" + url;
        mode = "cors";
    } else if (!opts.method) {
        mode = "no-cors";
    }
    const headers = {
        "content-type": "application/json",
        Accept: "application/json"
    };

    if (opts.method) {
        if (mode === "cors") {
            if (store) {
                const { auth } = store.getState(),
                    { user } = auth || {};
                if (user) {
                    headers["X-CSRFToken"] = user.csrftoken;
                }
            }
        } else {
            const match = document.cookie.match(/csrftoken=([^;]+)/);
            if (match) {
                headers["X-CSRFToken"] = match[1];
            }
        }
        if (!headers["X-CSRFToken"]) {
            console.warn(`Missing CSRF token for ${opts.method} request`);
        }
    }
    return {
        url,
        credentials: "include",
        mode,
        ...opts,
        headers: {
            ...headers,
            ...(opts.headers || {})
        }
    };
}

export function dbFetch(url, opts = {}) {
    const effect = dbEffect({
        url,
        ...opts
    });
    const { url: dbUrl, ...dbOpts } = effect;
    if (dbOpts.body instanceof FormData) {
        delete dbOpts.headers["content-type"];
    }
    return fetch(dbUrl, dbOpts);
}

export function csipFetch(url, opts = {}) {
    if (window.location.hostname === "localhost") {
        url = "https://build.fieldtomarket.org" + url;
    }
    opts = {
        method: "POST",
        ...opts,
        headers: {
            "Content-Type": "application/json",
            ...opts.headers
        }
    };
    return fetch(url, opts);
}

export function setStore(newStore) {
    store = newStore;
}
