import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { dbFetch } from "../../api/fetch";
import saveAs from "file-saver";
import apikey from "../../apikey.json";

import { withStyles } from "@mui/styles";
import withWidth from "@mui/material/Hidden/withWidth";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import GridOnIcon from "@mui/icons-material/GridOn";
import FolderIcon from "@mui/icons-material/Folder";

import Button from "../common/ButtonWrapper";
import IconButton from "@mui/material/IconButton";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";
import WarningDialog from "../common/WarningDialog";
import ActionButton from "../common/ActionButton";

import StepOneIcon from "./icons/StepOneIcon";
import StepTwoIcon from "./icons/StepTwoIcon";
import StepThreeIcon from "./icons/StepThreeIcon";
import CometPlanner from "../common/icons/CometPlanner";

import ScenarioTermsDialog from "./ScenarioTermsDialog";

import { Scenario } from "./models";
import { connect } from "react-redux";
import * as navActions from "../common/actions";

function SplitLocation(scenario) {
    var values = {
        county: "",
        state: ""
    };

    if (scenario.field && scenario.field.location && scenario.field.location.split(",").length === 2) {
        var arr = scenario.field.location.split(",");
        values.county = arr[0];
        values.state = arr[1];
    } else {
        values.county = scenario.county;
        values.state = scenario.state;
    }
    return values;
}

function within(year, startYear, endYear) {
    if (!startYear || startYear === "-") {
        return false;
    }
    if (!endYear) {
        endYear = startYear + 10;
    }
    if (year >= startYear && year < endYear) {
        return true;
    } else {
        return false;
    }
}

function getActiveYear(scenario) {
    const firstPractice = scenario.scenario_practice.orderBy("year").first(),
        firstYear = firstPractice && parseInt(firstPractice.year),
        currentYear = new Date().getFullYear();

    if (within(currentYear, firstYear)) {
        return currentYear;
    } else if (firstYear) {
        return firstYear;
    } else {
        return "-";
    }
}

function getPerAcre(scenario, key) {
    const activeYear = getActiveYear(scenario),
        pv = scenario.scenario_practice.filter(x => within(activeYear, parseInt(x.year))).toModelArray();
    return pv.map(x => (x[key] === -999 ? 0 : x[key])).reduce((a, b) => a + b, 0);
}

function formatPerAcre(scenario, key) {
    const perAcre = getPerAcre(scenario, key);
    return perAcre ? perAcre.toFixed(2) : "-";
}

function formatTotal(scenario, key) {
    const perAcre = getPerAcre(scenario, key),
        acres = scenario.field ? parseFloat(scenario.field.size.replace(" acre", "")) : scenario.acres;
    if (perAcre && acres) {
        return (perAcre * acres).toFixed(2);
    } else {
        return "-";
    }
}

const allScenarios = Scenario.selectAll(scenario => ({
    ...scenario.ref,
    farm_name: scenario.field ? scenario.field.farm.name : scenario.state,
    field_name: scenario.field ? scenario.field.name : scenario.county,
    field_acres: scenario.field ? scenario.field.size.replace(" acre", "") : scenario.acres,
    active: getActiveYear(scenario) !== "-",
    active_year: getActiveYear(scenario),
    num_practices: scenario.scenario_practice.count(),
    carbon: formatPerAcre(scenario, "carbon"),
    total_carbon: formatTotal(scenario, "carbon"),
    nitrous: formatPerAcre(scenario, "nitrous"),
    total_nitrous: formatTotal(scenario, "nitrous"),
    practices: scenario.scenario_practice.all().toRefArray(),
    ...SplitLocation(scenario)
}));

// CSS in JS styles
const styles = theme => ({
    floatRight: {
        float: "right"
    },
    floatLeft: {
        float: "left"
    },
    buttonWrapper: {
        display: "inline"
    },
    centerAlign: {
        textAlign: "center"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    summaryRoot: {
        minHeight: theme.spacing(8),
        border: "1px solid lightgrey",
        backgroundColor: "#353435"
    },
    expansionDetails: {
        display: "block"
    },
    expandMargin: {
        margin: "12px 0 !important"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    iconStepSize: {
        flexShrink: 0,
        width: "5em",
        height: "5em",
        fontSize: "24px",
        display: "block",
        margin: "auto 20px auto auto"
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    paddingLeft: {
        paddingLeft: 5
    },
    whiteText: {
        color: "#ffffff"
    },
    description: {
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    stepText: {
        maxWidth: 150,
        color: "black",
        textAlign: "center",
        fontWeight: "bold"
    },
    stepDiv: {
        marginRight: "3em"
    },
    table: {
        "& th span": {
            whiteSpace: "pre-wrap"
        }
    },
    actions: {
        whiteSpace: "nowrap !important"
    }
});

const allColumns = [
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "15%", priority: 1 },
        { id: "name", numeric: false, label: "Scenario", allowSort: true, priority: 1 },
        { id: "field_name", numeric: false, label: "Source", allowSort: true, priority: 1 },
        { id: "field_name", numeric: false, label: "Field", allowSort: true, priority: 1 },
        { id: "county", numeric: false, label: "County, State", allowSort: true, priority: 1 },
        { id: "field_acres", numeric: false, label: "Field Acres", allowSort: true, priority: 3 },
        { id: "active_year", numeric: false, label: "Active Year", allowSort: true, priority: 3 },
        { id: "num_practices", numeric: false, label: "Number of Practices", allowSort: true, priority: 3 },
        { id: "total_carbon", numeric: false, label: "Total Soil Carbon per year", allowSort: true, priority: 2 },
        { id: "carbon", numeric: false, label: "Soil Carbon per ac per year", allowSort: true, priority: 4 },
        { id: "total_nitrous", numeric: false, label: "Total N20 per year", allowSort: true, priority: 2 },
        { id: "nitrous", numeric: false, label: "N20 per ac per year", allowSort: true, priority: 4 }
    ],
    columns = {
        xs: allColumns.filter(column => column.priority <= 1),
        sm: allColumns.filter(column => column.priority <= 2),
        md: allColumns.filter(column => column.priority <= 3),
        lg: allColumns.filter(column => column.priority <= 4),
        xl: allColumns.filter(column => column.priority <= 5)
    };

class ScenarioLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "",
            page: 0,
            rowsPerPage: 10,
            deleteScenarioId: null,
            expanded: true,
            pdfLoading: {},
            excelLoading: {},
            zipLoading: false
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.scenarios.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.scenarios.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleExpansionChange = (event, expanded) => {
        this.setState({ expanded });
    };

    okDeleteScenario = () => {
        this.props.ormScenarioDelete(this.state.deleteScenarioId);
        this.setState({ deleteScenarioId: null });
    };

    cancelDeleteScenario = () => {
        this.setState({ deleteScenarioId: null });
    };

    fieldLabel = properties => {
        return (
            <React.Fragment>
                <Typography style={{ color: "white" }}>{properties.field_name}</Typography>
                <Typography style={{ color: "white" }}>{properties.farm_name}</Typography>
                <Typography style={{ color: "white" }}>
                    {properties.county}, {properties.state}
                </Typography>
                <Typography style={{ color: "white" }}>{properties.field_acres} ac</Typography>
            </React.Fragment>
        );
    };

    reportYear = (arr, ay) => {
        const cy = new Date().getFullYear().toString();
        if (arr === 0) {
            return cy;
        } else {
            var allYears = [];
            arr.forEach(x => {
                const min = parseInt(x.year);
                const max = min + 10;
                for (var i = min; i < max; i++) {
                    allYears.push(i.toString());
                }
            });
            allYears = allYears.sort((a, b) => a - b);
            if (allYears.includes(ay.toString())) {
                return ay;
            } else {
                return allYears[0];
            }
        }
    };

    async download(scenario) {
        this.setState({ pdfLoading: { ...this.state.pdfLoading, [scenario.id]: true } });

        const { authState } = this.props;
        var year = this.reportYear(scenario.practices, scenario.active_year);

        var data = {
            scenario_id: scenario.id,
            name: scenario.name,
            farm_name: scenario.farm_name,
            field_name: scenario.field_name,
            size: parseFloat(scenario.field_acres),
            practices: scenario.practices.sort((a, b) => a.year - b.year),
            year: year
        };

        let response;
        if (authState.user && authState.user.guest) {
            data.user = "Guest Account";
            response = await fetch("/scenario/reports/pdf", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/pdf",
                    Authorization: "Bearer " + apikey.apikey,
                    "Content-Type": "application/json"
                }
            });
        } else {
            response = await dbFetch(`/scenario/reports/${scenario.id}/pdf?year=${year}`);
        }

        saveAs(await response.blob(), scenario.name + ".pdf");

        this.setState({ pdfLoading: { ...this.state.pdfLoading, [scenario.id]: false } });
    }

    async downloadExcel(scenario) {
        this.setState({ excelLoading: { ...this.state.excelLoading, [scenario.id]: true } });

        const { authState } = this.props;
        var year = this.reportYear(scenario.practices, scenario.active_year);

        var data = {
            scenario_id: scenario.id,
            name: scenario.name,
            farm_name: scenario.farm_name,
            field_name: scenario.field_name,
            size: parseFloat(scenario.field_acres),
            practices: scenario.practices.sort((a, b) => a.year - b.year),
            year: year
        };

        let response;
        if (authState.user && authState.user.guest) {
            data.user = "Guest Account";
            response = await fetch("/scenario/reports/excel", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Authorization: "Bearer " + apikey.apikey,
                    "Content-Type": "application/json"
                }
            });
        } else {
            response = await dbFetch(`/scenario/reports/${scenario.id}/excel?year=${year}`);
        }

        saveAs(await response.blob(), scenario.name + ".xlsx");

        this.setState({ excelLoading: { ...this.state.excelLoading, [scenario.id]: false } });
    }

    async downloadAll() {
        const { authState } = this.props;
        if (!authState.user || authState.user.guest) {
            return;
        }

        this.setState({ zipLoading: true });

        const response = await dbFetch("/scenario/reports/all");
        saveAs(await response.blob(), "Scenario-Export.zip");

        this.setState({ zipLoading: false });
    }

    render() {
        const { scenarios, classes, authState, width } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            deleteScenarioId,
            expanded,
            zipLoading,
            pdfLoading,
            excelLoading
        } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, scenarios.length - page * rowsPerPage);

        scenarios.map(function(pu) {
            return pu;
        });

        const columnData = columns[width || "xl"];

        return (
            <Accordion expanded={expanded} onChange={this.handleExpansionChange}>
                <AccordionSummary
                    classes={{
                        root: classes.summaryRoot,
                        expanded: classes.expandMargin
                    }}
                    expandIcon={<ExpandMoreIcon className={classes.whiteText} />}>
                    <CometPlanner className={classes.iconSize} />
                    <Typography variant="title" className={classNames(classes.paddingLeft, classes.whiteText)}>
                        Scenarios
                    </Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.expansionDetails }}>
                    <Typography>
                        The listing for each scenario includes Total Soil Carbon and Total N<sub>2</sub>O in short tons
                        of CO2 equivalent per year for the field based on practices selected in each year and acreage
                        provided for this field. The listing includes the per practice emission coefficient in short
                        tons of CO<sub>2</sub>e per acre per year. Negative values indicate increasing emissions and
                        positive values indicate sequestration (soil carbon) or emissions reduction (N<sub>2</sub>O).
                        The active year is either the current year or the earliest year with a practice change,
                        whichever is later. Soil Carbon change is assumed to occur at a constant rate for 10 years
                        following the practice change; N<sub>2</sub>O change is assumed to occur every year that the
                        practice is implemented. Click edit (
                        <EditIcon className={classes.iconText} color="primary" />) to view or edit a scenario. Click
                        print (<DescriptionIcon className={classes.iconText} color="primary" />) to generate a PDF
                        report for the scenario.
                    </Typography>
                    {authState.user && !authState.user.guest && scenarios.some(scenario => scenario.active) && (
                        <div style={{ float: "right", marginTop: 8, marginBottom: 16 }}>
                            <ActionButton
                                startIcon={<FolderIcon />}
                                onClick={() => {
                                    this.downloadAll();
                                }}
                                loading={zipLoading}>
                                Download Data for All Scenarios
                            </ActionButton>
                        </div>
                    )}
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            columnData={columnData}
                        />
                        <TableBody>
                            {scenarios
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(scenario_attributes => (
                                    <TableRow hover key={scenario_attributes.id}>
                                        <CustomTableCell className={classes.actions}>
                                            <Tooltip title="Edit Scenario">
                                                <IconButton
                                                    onClick={() =>
                                                        this.props.navSelectPage("scenario_" + scenario_attributes.id)
                                                    }
                                                    component={Link}
                                                    to={"/scenario/" + scenario_attributes.id}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Report">
                                                <ActionButton
                                                    icon
                                                    disabled={!scenario_attributes.active}
                                                    fullWidth={false}
                                                    variant="text"
                                                    loading={pdfLoading[scenario_attributes.id]}
                                                    onClick={evt => {
                                                        evt.preventDefault();
                                                        this.download(scenario_attributes);
                                                    }}
                                                    className={classes.buttonWrapper}>
                                                    <DescriptionIcon
                                                        color={
                                                            scenario_attributes.active &&
                                                            !pdfLoading[scenario_attributes.id]
                                                                ? "primary"
                                                                : "disabled"
                                                        }
                                                    />
                                                </ActionButton>
                                            </Tooltip>
                                            <Tooltip title="Data">
                                                <ActionButton
                                                    icon
                                                    disabled={!scenario_attributes.active}
                                                    fullWidth={false}
                                                    variant="text"
                                                    loading={excelLoading[scenario_attributes.id]}
                                                    onClick={evt => {
                                                        evt.preventDefault();
                                                        this.downloadExcel(scenario_attributes);
                                                    }}
                                                    className={classes.buttonWrapper}>
                                                    <GridOnIcon
                                                        color={
                                                            scenario_attributes.active &&
                                                            !excelLoading[scenario_attributes.id]
                                                                ? "primary"
                                                                : "disabled"
                                                        }
                                                    />
                                                </ActionButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    onClick={() =>
                                                        this.setState({ deleteScenarioId: scenario_attributes.id })
                                                    }>
                                                    <DeleteIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </CustomTableCell>
                                        <CustomTableCell>{scenario_attributes.name}</CustomTableCell>
                                        <CustomTableCell>
                                            {scenario_attributes.field ? "Selected Field" : "Direct Entry"}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {scenario_attributes.field ? (
                                                <Tooltip title={this.fieldLabel(scenario_attributes)}>
                                                    <Typography style={{ fontSize: 14 }}>
                                                        {scenario_attributes.field_name}
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                "-"
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {scenario_attributes.county}, {scenario_attributes.state}
                                        </CustomTableCell>
                                        {columnData.slice(5).map(column => (
                                            <CustomTableCell key={column.id}>
                                                {scenario_attributes[column.id]}
                                            </CustomTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && scenarios.length > 10 && (
                                <TableRow style={{ height: 48 * emptyRows }}>
                                    <CustomTableCell colSpan={10} />
                                </TableRow>
                            )}
                            {scenarios.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={10} className={classes.centerAlign}>
                                        No Scenarios Found
                                    </CustomTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        {scenarios.length > 10 && (
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={10}
                                        count={scenarios.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        classes={{
                                            caption: classes.body1
                                        }}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        )}
                        <WarningDialog
                            confirmText={"Yes"}
                            confirmAction={this.okDeleteScenario}
                            cancelText={"No"}
                            cancelAction={this.cancelDeleteScenario}
                            open={!!deleteScenarioId}
                            title="Delete Scenario"
                            text={"Are you sure you want to delete this scenario?"}
                        />
                    </Table>
                </AccordionDetails>
            </Accordion>
        );
    }
}

class ScenarioDashBoard extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        var { authState, classes, scenarios, navSelectPage, width } = this.props;

        return (
            <AppContainer authenticated color="scenario" title="COMET-Planner Scenario Tool">
                <ScenarioTermsDialog />
                <Grid container spacing={4}>
                    <Grid item xs={5} className={classes.description}>
                        <Typography gutterBottom>
                            This evaluation tool is designed to provide generalized estimates of the greenhouse gas
                            impacts of conservation practices and is intended for initial planning purposes.
                            Site-specific conditions (not evaluated in this tool) are required for more detailed
                            assessments of greenhouse gas dynamics on your farm.
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{ display: "flex" }}>
                        <div className={classes.stepDiv}>
                            <StepOneIcon className={classes.iconStepSize} />
                            <Typography className={classes.stepText}>Select from your Existing Fields</Typography>
                        </div>
                        <div className={classes.stepDiv}>
                            <StepTwoIcon className={classes.iconStepSize} />
                            <Typography className={classes.stepText}>Apply Conservation Practices</Typography>
                        </div>
                        <div className={classes.stepDiv}>
                            <StepThreeIcon className={classes.iconStepSize} />
                            <Typography className={classes.stepText}>Get Detailed Results</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => navSelectPage("scenario_new")}
                            component={Link}
                            to="/scenario/new"
                            className={classes.floatRight}
                            variant="raised"
                            color="primary"
                            fullWidth>
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Scenario
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ScenarioLibrary
                            authState={authState}
                            scenarios={scenarios}
                            classes={classes}
                            ormScenarioDelete={this.props.ormScenarioDelete}
                            navSelectPage={navSelectPage}
                            width={width}
                        />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

ScenarioDashBoard = connect(
    state => ({
        authState: state.auth,
        scenarios: allScenarios(state)
    }),
    {
        ...Scenario.actions,
        ...navActions
    }
)(ScenarioDashBoard);

export default withWidth()(withStyles(styles)(ScenarioDashBoard));
