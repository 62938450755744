import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "../../common/ButtonWrapper";
import Divider from "@mui/material/Divider";
import Grid from "../../common/GridWrapper";
import Paper from "@mui/material/Paper";
import Typography from "../../common/TypographyWrapper";

import SoilConservationIcon from "../../field/analysis/img/soil_conservation.png";
import WaterQualityIcon from "../../field/analysis/img/water_quality.png";
import BiodiversityIcon from "../../field/analysis/img/biodiversity.png";
import EnergyUseIcon from "../../field/analysis/img/energy_use.png";
import GreenhouseGasIcon from "../../field/analysis/img/greenhouse_gas.png";

import withStyles from "@mui/styles/withStyles";

import AppContainer from "../../common/AppContainer";
import CornerArrow from "../../common/icons/CornerArrow";
import FieldIcon from "../../common/icons/FieldIconWhite";
import MagnifyIcon from "../../common/icons/Magnify";
import * as navActions from "../../common/actions";
import farmer1 from "./1.png";
import Popup from "./Popup";
import spidergram1 from "./1-1.jpg";
import spidergram2 from "./1-2.jpg";
import spidergram1thumb from "./1-1-thumb.jpg";
import spidergram2thumb from "./1-2-thumb.jpg";

const styles = theme => ({
    marginTop: {
        marginTop: 16
    },
    icon: {
        verticalAlign: "middle",
        height: 60,
        width: 60,
        margin: "-8px -4px"
    },
    linkColor: {
        color: "#808080"
    },
    crumbColor: {
        color: theme.palette.primary.main
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    insertText: {
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 400,
        fontStyle: "italic"
    },
    insertTextPop: {
        padding: 16,
        fontSize: 16.2,
        fontWeight: 400,
        fontStyle: "italic"
    },
    iconSize: {
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    buttonPadding: {
        padding: "0 0 12px 0px",
        "& span": {
            display: "inline-block",
            textAlign: "center"
        }
    },
    svgField: {
        marginRight: 8,
        marginBottom: -8
    },
    buttonTypo: {
        display: "inline-block",
        marginTop: 12,
        fontSize: 15,
        color: "#fff"
    },
    svgCorner: {
        float: "right"
    },
    quoteText: {
        color: theme.palette.primary.main,
        margin: 16,
        fontSize: 20,
        fontStyle: "italic"
    },
    quoteFarmer: {
        textAlign: "right",
        marginBottom: 16
    },
    caption: {
        marginTop: 24,
        marginLeft: 8,
        lineHeight: "1.6em",
        fontSize: 11,
        color: "rgba(0, 0, 0, 0.54)"
    },
    magnify: {
        position: "absolute",
        bottom: 3,
        right: 0,
        width: 40,
        height: 40,
        cursor: "pointer"
    },
    thumbnails: {
        width: 70,
        border: "1px solid #bbb",
        marginRight: 16
    },
    thumbnailSelected: {
        border: "3px solid " + theme.palette.primary.light + " !important"
    },
    buttonDialogPadding: {
        marginTop: 16,
        marginBottom: 16
    },
    specialBreak: {
        "@media (min-width: 1280px) and (max-width: 1450px)": {
            marginTop: -80
        },
        "@media (min-width: 1450px) and (max-width: 1550px)": {
            marginTop: -40
        }
    },
    dynamicText: {
        lineHeight: "1.8em",
        fontSize: 15,
        "@media (min-width: 1280px) and (max-width: 1364px)": {
            lineHeight: "1.3em",
            fontSize: 13
        },
        "@media (min-width: 1364px) and (max-width: 1450px)": {
            lineHeight: "1.4em",
            fontSize: 13.5
        },
        "@media (min-width: 1450px) and (max-width: 1600px)": {
            lineHeight: "1.6em",
            fontSize: 14
        }
    }
});

class FarmerA extends Component {
    state = {
        isOpen: false,
        currentGraph: 0
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { isOpen, currentGraph } = this.state;
        const { classes, navSelectPage } = this.props;

        return (
            <AppContainer
                authenticated
                color="welcome"
                title="Shine a Light on Your Sustainability Story"
                pageTitle="Meet Tim Smith"
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/welcome"}>
                            Hear From Farmers Like You
                        </Link>
                        &nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp; <span className={classes.crumbColor}>Meet Tim Smith</span>
                    </div>
                }>
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Typography variant="title" gutterBottom>
                            <i>Meet Tim Smith</i>
                        </Typography>
                        <Typography gutterBottom>
                            By participating in the Boone River Project and using the Fieldprint® Platform, Tim
                            discovered the sustainability benefits of management practices such as reduced tillage
                            practices and fall cover crops. The Fieldprint Platform has helped Tim document and
                            demonstrate benefits, including:
                        </Typography>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img
                                                src={SoilConservationIcon}
                                                className={classes.icon}
                                                alt="Reduced soil erosion"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced soil erosion</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={WaterQualityIcon}
                                                className={classes.icon}
                                                alt="Improved water quality"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Improved water quality</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={BiodiversityIcon}
                                                className={classes.icon}
                                                alt="New wildlife habitats"
                                            />
                                        </td>
                                        <td>
                                            <Typography>New wildlife habitats</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img
                                                src={EnergyUseIcon}
                                                className={classes.icon}
                                                alt="Reduced energy costs"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced energy costs</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={GreenhouseGasIcon}
                                                className={classes.icon}
                                                alt="Reduced field time"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced field time</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    component={Link}
                                    to="/cropyear/DEMO4-0001-2018"
                                    onClick={() => navSelectPage("field_DEMO4-0001")}
                                    variant="raised"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonPadding}>
                                    <CornerArrow className={classNames(classes.svgCorner, classes.iconSize)} />
                                    <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                    <Typography className={classes.buttonTypo}>Explore My Corn Field</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    component={Link}
                                    to="/cropyear/DEMO4-0001-2017"
                                    onClick={() => navSelectPage("field_DEMO4-0001")}
                                    variant="raised"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonPadding}>
                                    <CornerArrow className={classNames(classes.svgCorner, classes.iconSize)} />
                                    <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                    <Typography className={classes.buttonTypo}>Explore My Soybean Field</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Paper style={{ backgroundColor: "#eee" }}>
                            <Grid container spacing={0} className={classes.marginTop} alignItems="center">
                                <Grid item xs={6} className={classes.noPadding} style={{ marginBottom: -5 }}>
                                    <img src={farmer1} alt="Tim Smith" style={{ width: "100%" }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classNames(classes.insertText, classes.dynamicText)}>
                                        Tim Smith grows corn and soybeans on his 853-acre family farm in Eagle Grove,
                                        Iowa. Tim uses the Fieldprint® Platform to analyze the sustainability
                                        performance of his corn and soybean fields and gain a better understanding of
                                        the impact of his farm management decisions, helping him to focus on preserving
                                        his farm’s greatest resources—its soil and water—for generations to come.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        {currentGraph === 0 && (
                            <Paper style={{ backgroundColor: "#eee" }}>
                                <Grid container spacing={0}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.noPadding}
                                        style={{ marginBottom: -5, position: "relative" }}>
                                        <img
                                            onClick={() => this.setState({ isOpen: true })}
                                            src={spidergram1}
                                            alt="Tim's Soybean Field"
                                            style={{ cursor: "pointer", width: "100%" }}
                                        />
                                        <MagnifyIcon
                                            onClick={() => this.setState({ isOpen: true })}
                                            className={classes.magnify}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="title" style={{ padding: 16, fontSize: 23 }}>
                                            Fieldprint® Analysis of Tim’s Soybean Field
                                        </Typography>
                                        <Typography className={classes.insertText}>
                                            Click on the spider diagram to explore Tim’s Fieldprint Analysis.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        {currentGraph === 1 && (
                            <Paper style={{ backgroundColor: "#eee" }}>
                                <Grid container spacing={0}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.noPadding}
                                        style={{ marginBottom: -5, position: "relative" }}>
                                        <img
                                            onClick={() => this.setState({ isOpen: true })}
                                            src={spidergram2}
                                            alt="Tim's Corn Field"
                                            style={{ cursor: "pointer", width: "100%" }}
                                        />
                                        <MagnifyIcon
                                            onClick={() => this.setState({ isOpen: true })}
                                            className={classes.magnify}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="title" style={{ padding: 16, fontSize: 23 }}>
                                            Fieldprint® Analysis of Tim’s Corn Field
                                        </Typography>
                                        <Typography className={classes.insertText}>
                                            Click on the spider diagram to explore Tim’s Fieldprint Analysis.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        <div className={classes.marginTop}>
                            <img
                                src={spidergram1thumb}
                                alt="spidergram 1"
                                onClick={() => this.setState({ currentGraph: 0 })}
                                className={classNames(
                                    classes.thumbnails,
                                    currentGraph === 0 && classes.thumbnailSelected
                                )}
                            />
                            <img
                                src={spidergram2thumb}
                                alt="spidergram 2"
                                onClick={() => this.setState({ currentGraph: 1 })}
                                className={classNames(
                                    classes.thumbnails,
                                    currentGraph === 1 && classes.thumbnailSelected
                                )}
                            />
                        </div>
                        {currentGraph === 0 && (
                            <Popup
                                open={isOpen}
                                closeEvent={() => this.setState({ isOpen: false })}
                                image={spidergram1}
                                imagealt="Tim's Soybean Field"
                                title="Fieldprint® Analysis of Tim’s Soybean Field"
                                text={
                                    <div>
                                        <Typography className={classes.insertTextPop}>
                                            The Fieldprint Analysis of Tim’s soybean field gives him a full picture into
                                            his field's sustainability performance. The closer the blue shaded area is
                                            to the center of the spider diagram, the greater the sustainability
                                            performance or resource use efficiency.
                                        </Typography>
                                        <Typography className={classes.insertTextPop}>
                                            Farmers like Tim utilize the Fieldprint Platform to both document the
                                            environmental benefits of their conservation and stewardship as well as
                                            identify opportunities for continuous improvement in the next growing
                                            season. Generally, if you there are blue areas that extend beyond the state
                                            or national benchmarks in a Fieldprint Analysis, this highlights an
                                            opportunity for improvement and an important area to consider in the next
                                            growing season. Over time, comparing the results of multiple analyses can
                                            help you evaluate sustainability progress and consider trade-offs between
                                            different management approaches for your field.
                                        </Typography>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={10} lg={6}>
                                                <Button
                                                    component={Link}
                                                    to="/cropyear/DEMO4-0001-2017"
                                                    onClick={() => navSelectPage("field_DEMO4-0001")}
                                                    variant="raised"
                                                    color="primary"
                                                    fullWidth
                                                    className={classNames(
                                                        classes.buttonDialogPadding,
                                                        classes.buttonPadding
                                                    )}>
                                                    <CornerArrow
                                                        className={classNames(classes.svgCorner, classes.iconSize)}
                                                    />
                                                    <FieldIcon
                                                        className={classNames(classes.svgField, classes.iconSize)}
                                                    />
                                                    <Typography className={classes.buttonTypo}>
                                                        Explore My Soybean Field
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            />
                        )}
                        {currentGraph === 1 && (
                            <Popup
                                open={isOpen}
                                closeEvent={() => this.setState({ isOpen: false })}
                                image={spidergram2}
                                imagealt="Tim's Corn Field"
                                title="Fieldprint® Analysis of Tim’s Corn Field"
                                text={
                                    <div>
                                        <Typography className={classes.insertTextPop}>
                                            The Fieldprint Analysis of Tim’s corn field gives him a full picture into
                                            his field's sustainability performance. The closer the blue shaded area is
                                            to the center of the spider diagram, the greater the sustainability
                                            performance or resource use efficiency.
                                        </Typography>
                                        <Typography className={classes.insertTextPop}>
                                            Farmers like Tim utilize the Fieldprint Platform to both document the
                                            environmental benefits of their conservation and stewardship as well as
                                            identify opportunities for continuous improvement in the next growing
                                            season. Generally, if you there are blue areas that extend beyond the state
                                            or national benchmarks in a Fieldprint Analysis, this highlights an
                                            opportunity for improvement and an important area to consider in the next
                                            growing season. Over time, comparing the results of multiple analyses can
                                            help you evaluate sustainability progress and consider trade-offs between
                                            different management approaches for your field.
                                        </Typography>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={10} lg={6}>
                                                <Button
                                                    component={Link}
                                                    to="/cropyear/DEMO4-0001-2018"
                                                    onClick={() => navSelectPage("field_DEMO4-0001")}
                                                    variant="raised"
                                                    color="primary"
                                                    fullWidth
                                                    className={classNames(
                                                        classes.buttonDialogPadding,
                                                        classes.buttonPadding
                                                    )}>
                                                    <CornerArrow
                                                        className={classNames(classes.svgCorner, classes.iconSize)}
                                                    />
                                                    <FieldIcon
                                                        className={classNames(classes.svgField, classes.iconSize)}
                                                    />
                                                    <Typography className={classes.buttonTypo}>
                                                        Explore My Corn Field
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6} className={classNames(classes.specialBreak, classes.marginTop)}>
                        <Divider />
                        <Typography gutterBottom className={classes.quoteText}>
                            “The beauty of the Fieldprint® Platform is how it shows us how you can quantify the
                            management changes you are considering. It allows you to visualize impacts and measure your
                            farm’s conservation efforts in comparison to others.”
                        </Typography>
                        <div className={classes.quoteFarmer}>
                            <Typography>
                                <b>Tim Smith</b>
                            </Typography>
                            <Typography>4th Generation Farmer</Typography>
                            <Typography>Eagle Grove, Iowa</Typography>
                        </div>
                        <Divider />
                        <Typography variant="caption" className={classes.caption}>
                            Please note that the sample fields offered are based on similar climatic conditions, soil
                            type and management practices utilized on this farmer’s operation. This data is provided for
                            illustrative purposes to demonstrate how the Fieldprint Platform can be utilized to measure
                            and assess the sustainability performance of a given field.
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

FarmerA = connect(state => null, {
    ...navActions
})(FarmerA);

export default withStyles(styles)(FarmerA);
