import React, { Component } from "react";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import Grid from "../../../common/GridWrapper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { createSelector } from "../../../common/orm";
import Switch from "../../../common/Switch";
import Select from "../../../common/Select";
import { MAKE_OPTIONS } from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

import {
    FarmHpiSurvey,
    FarmHpiCultivatedLandQuestionnaire,
    FarmHpiForestQuestionnaire,
    FarmHpiGrasslandQuestionnaire,
    FarmHpiRiparianQuestionnaire,
    FarmHpiSurfaceWaterQuestionnaire,
    FarmHpiWetlandQuestionnaire
} from "../../models";
import { DialogActions } from "@mui/material";

const getFarmHpiSurveys = createSelector(
    (state, ownProps) => ownProps.farm_id,
    (session, farm_id) => {
        return session.FarmHpiSurvey.filter({ farm: farm_id })
            .toModelArray()
            .map(survey => ({
                cultivated: survey.cultivatedlandquestionnaire.toModelArray().map(f => ({ ...f._fields })),
                buffer: survey.riparianquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                forest: survey.forestquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                grassland: survey.grasslandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                surfacewater: survey.surfacewaterquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                wetland: survey.wetlandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                ...survey._fields,
                farm: survey.farm.ref
            }));
    }
);

const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    session => {
        return session.CropYear.orderBy("year", "desc")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                field_name: cropYear.field.name,
                farm_name: cropYear.field.farm.name,
                farm_id: cropYear.field.farm.id,
                ...cropYear.ref
            }));
    }
);

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    error: {
        marginTop: ".6em",
        color: "red"
    },
    minWidth: {
        minWidth: 600
    }
};

class AddHpiSurvey extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show_copy_survey: false
        };
    }

    addSurvey(values) {
        const { farm_id, history } = this.props;

        //  create new blank survey
        if (values.default_survey === false) {
            this.props
                .ormFarmHpiSurveyCreateRemoteFirst({
                    farm_id: farm_id,
                    year: values.year,
                    code: "01"
                })
                .then(result => {
                    history.push(`/farm/hpisurvey/${result}`);
                });
        } else {
            //  create new survey and questionnaires based on the the selected survey

            const src_survey = this.props.farmHpiSurveys.filter(s => s.year === values.current_survey_year)[0];

            var tgt_survey = {
                year: values.year,
                farm_id: src_survey.farm.id,
                code: src_survey.code,
                entry_status: src_survey.entry_status,
                landtypes: src_survey.landtypes,
                cultivated_land_hpi: src_survey.cultivated_land_hpi,
                noncultivated_land_hpi: src_survey.noncultivated_land_hpi,
                is_final: src_survey.is_final
            };

            function dropFields(q) {
                let fields_to_drop = ["id", "modify_user", "modify_date", "survey_id", "survey"];
                if (q) {
                    fields_to_drop.forEach(f => {
                        delete q[f];
                    });
                }
                return q;
            }

            function updateSurveyId(q, survey_id) {
                if (q) {
                    q["survey"] = survey_id;
                }
                return q;
            }

            //  cultivated questionnaires
            let cultivated = src_survey.cultivated.map(c => dropFields(c));

            //  forest questionnaire
            let forest = dropFields(src_survey.forest);

            //  grassland questionnaire
            let grassland = dropFields(src_survey.grassland);

            //  buffer questionnaire
            let buffer = dropFields(src_survey.buffer);

            //  surface water questionnaire
            let surfacewater = dropFields(src_survey.surfacewater);

            //  wetland questionnaire
            let wetland = dropFields(src_survey.wetland);

            this.props.ormFarmHpiSurveyCreateRemoteFirst(tgt_survey).then(survey_id => {
                //  something wrong
                if (typeof survey_id === "object") {
                    return;
                }

                cultivated = cultivated.map(c => updateSurveyId(c, survey_id));
                forest = updateSurveyId(forest, survey_id);
                grassland = updateSurveyId(grassland, survey_id);
                buffer = updateSurveyId(buffer, survey_id);
                surfacewater = updateSurveyId(surfacewater, survey_id);
                wetland = updateSurveyId(wetland, survey_id);

                var promises = [];
                cultivated.forEach(c => {
                    promises.push(this.props.ormFarmHpiCultivatedLandQuestionnaireCreateRemoteFirst(c));
                });

                if (forest) promises.push(this.props.ormFarmHpiForestQuestionnaireCreateRemoteFirst(forest));
                if (grassland) promises.push(this.props.ormFarmHpiGrasslandQuestionnaireCreateRemoteFirst(grassland));
                if (buffer) promises.push(this.props.ormFarmHpiRiparianQuestionnaireCreateRemoteFirst(buffer));
                if (surfacewater)
                    promises.push(this.props.ormFarmHpiSurfaceWaterQuestionnaireCreateRemoteFirst(surfacewater));
                if (wetland) promises.push(this.props.ormFarmHpiWetlandQuestionnaireCreateRemoteFirst(wetland));

                Promise.all(promises).then(result => {
                    console.log(result);
                    history.push(`/farm/hpisurvey/${survey_id}`);
                });
            });
        }
    }

    errorValidator = values => {
        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        var valObj = {};
        isRequired("year");

        if (values.default_survey === true) {
            isRequired("current_survey_year");
        }
        return valObj;
    };

    showCopySurvey = value => {
        this.setState({ show_copy_survey: value });
    };

    componentWillMount() {
        let completed_cropyears = this.props.cropYears.filter(
            c => c.is_final === true && c.id.split("-")[0] === this.props.farm_id
        );

        let possible_survey_years = [];
        completed_cropyears.forEach(c => {
            if (possible_survey_years.indexOf(c.year) === -1) {
                possible_survey_years.push(c.year);
            }
        });

        let current_survey_years = this.props.farmHpiSurveys.map(s => s.year);

        let available_survey_years = [];
        possible_survey_years.forEach(y => {
            if (current_survey_years.indexOf(y) === -1) {
                available_survey_years.push(y);
            }
        });

        //valid_years.filter(f => !survey_years.map(y => y.year).includes(f));

        this.setState({
            available_survey_years: available_survey_years,
            current_survey_years: current_survey_years,
            show_copy_survey: false
        });
    }

    render() {
        const { classes, open, returnDialog } = this.props;
        const { show_message, show_copy_survey, available_survey_years, current_survey_years } = this.state;

        const available_year_options =
            available_survey_years.length === 0
                ? []
                : MAKE_OPTIONS(available_survey_years.map(y => ({ name: y, id: y })));
        const survey_year_options = current_survey_years.map(y => ({
            value: y,
            label: y
        }));

        const default_values = {
            year: available_year_options.length === 0 ? null : available_year_options[0].value,
            default_survey: false,
            current_survey_year: survey_year_options.length > 0 ? survey_year_options[0].value : ""
        };

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Add HPI Survey
                    </Typography>
                    <IconButton
                        onClick={() => {
                            this.setState({ showMultiple: false });
                            returnDialog();
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                {available_survey_years.length === 0 && (
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Typography>
                                There are no available years to create a Farm Level Habitat Potential Index Survey. You
                                must have at least one field under this farm with a finalized crop year for the desired
                                HPI survey year. For example, to add a Farm Level HPI Survey for 2019, you must have a
                                2019 crop year finalized under this farm.
                            </Typography>
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                disabled={show_message}
                                variant="raised"
                                color="primary"
                                className={classes.button}
                                onClick={() => {
                                    this.setState({ showMultiple: false });
                                    returnDialog();
                                }}>
                                OK
                            </Button>
                        </DialogActions>
                    </DialogContent>
                )}
                {available_survey_years.length > 0 && (
                    <Form
                        getApi={el => (this.formApi = el)}
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        validateError={this.errorValidator}
                        defaultValues={default_values}
                        onSubmit={values => this.addSurvey(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogContent className={classes.dialogMin}>
                                    <DialogContentText>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="year"
                                                    label="Eligible Year"
                                                    fullWidth
                                                    // eventHandle={this.checkSurvey}
                                                    margin="normal"
                                                    options={available_year_options}
                                                />

                                                {survey_year_options.length > 0 && (
                                                    <Switch
                                                        field="default_survey"
                                                        name="switch"
                                                        eventHandle={value => this.showCopySurvey(value)}
                                                        label="Would you like to default answers from another survey year?"
                                                    />
                                                )}

                                                {survey_year_options.length > 0 && show_copy_survey === true && (
                                                    <Select
                                                        field="current_survey_year"
                                                        label="Survey year to copy"
                                                        fullWidth
                                                        margin="normal"
                                                        options={survey_year_options}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} />
                                        </Grid>
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button
                                            disabled={show_message}
                                            variant="raised"
                                            className={classes.button}
                                            onClick={() => {
                                                this.setState({ showMultiple: false });
                                                returnDialog();
                                            }}>
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={show_message}
                                            type="submit"
                                            variant="raised"
                                            color="primary"
                                            className={classes.button}>
                                            Create
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </form>
                        )}
                    </Form>
                )}
            </Dialog>
        );
    }
}

AddHpiSurvey = connect(
    (state, ownProps) => ({
        cropYears: getCropYears(state, ownProps),
        farmHpiSurveys: getFarmHpiSurveys(state, ownProps)
    }),
    {
        ...FarmHpiSurvey.actions,
        ...FarmHpiCultivatedLandQuestionnaire.actions,
        ...FarmHpiForestQuestionnaire.actions,
        ...FarmHpiGrasslandQuestionnaire.actions,
        ...FarmHpiRiparianQuestionnaire.actions,
        ...FarmHpiSurfaceWaterQuestionnaire.actions,
        ...FarmHpiWetlandQuestionnaire.actions
    }
)(AddHpiSurvey);

export default withStyles(styles)(withRouter(AddHpiSurvey));
