import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import createGenerateClassName from "@mui/styles/createGenerateClassName";
import StylesProvider from "@mui/styles/StylesProvider";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";

import "whatwg-fetch";

import HomePage from "./components/home/HomePage";
import AccountConfirmation from "./components/auth/AccountConfirmation";
import ResendEmail from "./components/auth/ResendEmail";
import RegisterPage from "./components/auth/RegisterPage";
import RegisteredPage from "./components/auth/RegisteredPage";
import UpdatePage from "./components/auth/UpdatePage";
import ScenarioDashBoard from "./components/scenario/ScenarioDashBoard";
import ScenarioProperties from "./components/scenario/ScenarioProperties";
import Dashboard from "./components/dashboard/Dashboard";
import Field from "./components/field/Field";
import FieldProperties from "./components/field/FieldProperties";
import FarmProperties from "./components/field/FarmProperties";
import CropYearDetail from "./components/field/fieldactivities/CropYearDetail";
import Analysis from "./components/field/analysis/Analysis";
import MetricTest from "./components/field/analysis/MetricTest";
import Research from "./components/research/Research";
import RotationLibrary from "./components/rotation/RotationLibrary";
import RotationSearch from "./components/rotation/RotationSearch";
import RotationEditor from "./components/rotation/RotationEditor";
import Report from "./components/field/analysis/Report";
import ReportTool from "./components/common/ReportTool";
import ProjectDashboard from "./components/project/ProjectDashboard";
import ProjectAdminDashboard from "./components/project/AdminDashboard";
import ProjectDetails from "./components/project/ProjectDetails";
import ProjectAdminDetails from "./components/project/AdminDetails";
import ProjectOpt from "./components/project/ProjectOpt";
import Reports from "./components/project/Reports";
import ProjectReport from "./components/project/Report";
import ExportData from "./components/field/ExportData";
import Delegate from "./components/auth/Delegate";
import DelegateResponse from "./components/auth/DelegateResponse";
import Welcome from "./components/welcome/Welcome";
import FarmerA from "./components/welcome/farmers/A";
import FarmerB from "./components/welcome/farmers/B";
import FarmerC from "./components/welcome/farmers/C";
import FarmHpiSurveyPage from "./components/field/hpi/FarmHpiSurvey";
import HpiResults from "./components/field/hpi/FarmHpiResults";
import Survey from "./components/field/sai/Survey";
import registerServiceWorker from "./registerServiceWorker";
import Insights from "./components/dashboard/Insights";

import navReducer from "./components/common/reducers";
import { storeReady } from "./components/common/actions";
import { authInit } from "./components/auth/actions";
import authReducer from "./components/auth/reducers";
import rotationReducer from "./components/rotation/reducers";
import scenarioReducer from "./components/scenario/reducers";
import orm, { syncReducer, reloadAll } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";
import RotationSystemWarningDialogWrapper from "./components/common/RotationSystemWarningDialogWrapper";

import { setStore } from "./api/fetch";

const REPORT_MODE = window.location.href.match("mode=report");

// ff8300 is halfway between gradient ff6e00 ff9700
// https://material.io/color/#!/?view.left=0&view.right=1&primary.color=FF8300
const theme = createTheme(
    adaptV4Theme({
        typography: {
            fontFamily: "'Source Sans Pro', sans-serif"
            //color: "#666666 !important" // Doesn't work?
        },
        overrides: {
            // Make the font color uniform
            MuiFormHelperText: {
                root: {
                    lineHeight: "1.375em",
                    color: "#666666"
                }
            },
            MuiFormLabel: {
                root: {
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#666666"
                }
            },
            MuiInputLabel: {
                root: {
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#666666 !important"
                },
                shrink: {
                    transform: "translate(0, 0px) scale(1)"
                }
            },
            MuiListItem: {
                root: {
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#666666"
                }
            },
            MuiTableSortLabel: {
                root: {
                    whiteSpace: "nowrap"
                }
            },
            MuiTypography: {
                caption: {
                    fontSize: 11
                },
                // Page Header
                h1: {
                    fontSize: "36px",
                    fontWeight: 100,
                    color: "#666666"
                },
                //h2
                h2: {
                    fontSize: "18px",
                    fontWeight: 400,
                    color: "#666666"
                },
                //Form group headings
                h3: {
                    borderBottom: "2px solid #efefef",
                    fontSize: "30px",
                    fontWeight: 400,
                    marginLeft: 0,
                    letterSpacing: 0
                },
                //Form group heading no underline
                h4: {
                    fontSize: "30px",
                    fontWeight: 400,
                    marginLeft: 0,
                    letterSpacing: 0
                },
                // Smaller headings
                title: {
                    fontWeight: 400,
                    fontSize: "20px",
                    color: "#666666"
                },
                // Normal text
                body1: {
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#666666"
                },
                gutterBottom: {
                    marginBottom: ".6em"
                }
            },
            // Table cell 13px weight 400 defined in common/tablecell
            MuiButton: {
                root: {
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                    borderRadius: 2
                }
            },
            MuiTableRow: {
                root: {
                    height: 40
                }
            }
        },
        palette: {
            primary: {
                // FTM Orange
                light: "#ffb444",
                //main: "#ff8300",
                main: "#f15d22",
                dark: "#c55400",
                contrastText: "#fff"
            },
            secondary: {
                // FTM Blue
                light: "#5fc6ff",
                main: "#0096d6",
                dark: "#0068a4",
                contrastText: "#fff"
            },
            grey: {
                main: "#e0e0e0",
                contrastText: "#666666"
            },
            greyText: {
                main: "#666666"
            },
            error: {
                main: "#FF0000"
            }
        }
    })
);

const generateClassName = createGenerateClassName({
    //dangerouslyUseGlobalCSS: true,
    productionPrefix: "c"
});

const logger = createLogger();
const reducer = combineReducers({
    auth: authReducer,
    rotation: rotationReducer,
    orm: createReducer(orm),
    sync: syncReducer,
    nav: navReducer,
    scenario: scenarioReducer
});

const initialState = {
    nav: {
        navigationOpen: true,
        libraryOpen: true,
        projectOpen: false,
        selectedPage: "dashboard",
        selectedButton: "table",
        hideFarmMessage: false,
        sw: { checking: true }
    }
};

const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const store = createStore(
    reducer,
    initialState,
    compose(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => (retries > 5 ? null : retries * 1000),
            persistOptions: {
                storage: localForage,
                serialize: false
            },
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw
                            };
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy
                        };

                        // When registering a brand new model (ex: FarmSurvey)
                        // the new model is not present in inboundState.orm
                        // but it is in state.orm.
                        // Add the empty orm models through state.orm so the new model
                        // is present but overwrite any existing models through inboundState.orm
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm
                        };

                        // Show Scenario Terms once per session
                        inboundState.scenario = state.scenario;

                        return inboundState;
                    }
                }),
            persistCallback: () => {
                store.dispatch(storeReady());
                store.dispatch(REPORT_MODE ? reloadAll(true) : authInit());
            }
        })
    )
);

setStore(store);

const getConfirmation = (message, callback) => {
    if (message.includes("@#$%^&")) {
        ReactDOM.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <RotationSystemWarningDialogWrapper store={store} message={message} callback={callback} />
                </ThemeProvider>
            </StyledEngineProvider>,
            document.getElementById("pp")
        );
    } else {
        ReactDOM.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <WarningDialogWrapper message={message} callback={callback} />
                </ThemeProvider>
            </StyledEngineProvider>,
            document.getElementById("pp")
        );
    }
};

ReactDOM.render(
    <ReduxProvider store={store}>
        <StylesProvider generateClassName={generateClassName}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <BrowserRouter getUserConfirmation={getConfirmation}>
                        <Switch>
                            <Route exact path="/" component={REPORT_MODE ? ReportTool : HomePage} />
                            <Route path="/activate/:uid/:token" component={AccountConfirmation} />
                            <Route path="/activate/resend" component={ResendEmail} />
                            <Route path="/register" component={RegisterPage} />
                            <Route path="/registered/:email" component={RegisteredPage} />
                            <Route path="/updateuser" component={UpdatePage} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/farm/add" component={FarmProperties} />
                            <Route path="/farm/hpisurvey/:id" component={FarmHpiSurveyPage} />
                            <Route path="/farm/hpiresults/:id" component={HpiResults} />
                            <Route path="/farm/:farmid/survey/:id" component={Survey} />
                            <Route path="/farm/:id/edit" component={FarmProperties} />
                            <Route path="/farm/:farmid/addfield" component={FieldProperties} />
                            <Route path="/field/:id/location" component={FieldProperties} />
                            <Route path="/field/:id" component={Field} />
                            <Route path="/insights" component={Insights} />
                            <Route path="/cropyear/:id/analysis" component={Analysis} />
                            <Route path="/cropyear/:id/test" component={MetricTest} />
                            <Route path="/cropyear/:id/report" component={Report} />
                            <Route path="/cropyear/:id" component={CropYearDetail} />
                            <Route path="/exportdata" component={ExportData} />
                            <Route path="/research" component={Research} />
                            <Route path="/rotation/library/project/:projectId" component={RotationLibrary} />
                            <Route path="/rotation/library/cropyear/:cropyearId" component={RotationLibrary} />
                            <Route path="/rotation/library" component={RotationLibrary} />
                            <Route path="/rotation/search/project/:projectId" component={RotationSearch} />
                            <Route path="/rotation/search/cropyear/:cropyearId" component={RotationSearch} />
                            <Route path="/rotation/search" component={RotationSearch} />
                            <Route path="/rotation/:id/project/:projectId" component={RotationEditor} />
                            <Route path="/rotation/:id/cropyear/:cropyearId" component={RotationEditor} />
                            <Route path="/rotation/:id" component={RotationEditor} />
                            <Route path="/project/opt" component={ProjectOpt} />
                            <Route path="/project/reports/:id" component={Reports} />
                            <Route path="/project/:id/report" component={ProjectReport} />
                            <Route path="/project/:id" component={ProjectDetails} />
                            <Route path="/projectadmin/:id" component={ProjectAdminDetails} />
                            <Route path="/projectadmin" component={ProjectAdminDashboard} />
                            <Route path="/project" component={ProjectDashboard} />
                            <Route path="/scenario/:id" component={ScenarioProperties} />
                            <Route path="/scenario" component={ScenarioDashBoard} />
                            <Route path="/delegate/:type" component={DelegateResponse} />
                            <Route path="/delegate" component={Delegate} />
                            <Route path="/welcome/1" component={FarmerA} />
                            <Route path="/welcome/2" component={FarmerB} />
                            <Route path="/welcome/3" component={FarmerC} />
                            <Route path="/welcome" component={Welcome} />
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    </ReduxProvider>,
    document.getElementById("root")
);

registerServiceWorker(store.dispatch);
