import React, { Component } from "react";

import { connect } from "react-redux";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ActivityDetails from "./ActivityDetails";
import { FieldActivity } from "./models";
import HelpDialog from "../../common/HelpDialog";
import WarningDialog from "../../common/WarningDialog";
import Snackbar from "../../common/Snackbar";
import { createSelector } from "../../common/orm";
import { ALFALFA, hasFeatureFlag } from "../../../api/constants";
import { getValue, setValue } from "../../../api/utils";
import { isCompleted } from "./fieldActivitiesUtils";

import { Fertilizer } from "./activities/models";

const getFertilizers = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        return session.Fertilizer.filter(a => a.activity.includes(cropYear.id)).toRefArray();
    }
);

const getFieldActivities = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        var ordering = {},
            sortOrder = ["planting", "irrigation", "application", "manure", "harvest"];
        for (var i = 0; i < sortOrder.length; i++) {
            ordering[sortOrder[i]] = i;
        }
        return session.FieldActivity.filter({ cropyear: cropYear.id })
            .orderBy(["type", "code"])
            .toRefArray()
            .sort((a, b) => ordering[a.type] - ordering[b.type] || a.code.match(/\d+/)[0] - b.code.match(/\d+/)[0]);
    }
);

const getFertilizerCount = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        var fertilizers = [];

        session.FieldActivity.filter({ cropyear: cropYear.id })
            .toRefArray()
            .filter(a => a.type === "application")
            .forEach(a => {
                const temp_fertilizers = session.Fertilizer.filter({ activity: a.id }).toRefArray();
                fertilizers = [...fertilizers, ...temp_fertilizers];
            });

        return fertilizers.length;
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    floatRight: {
        float: "right",
        marginLeft: theme.spacing(2),
        color: "#666666"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    linkColor: {
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    }
});

class FieldActivities extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            expanded: false,
            snackbarOpen: false,
            snackbarMissing: 0,
            helpOpen: false,
            nutrientWarningOpen: false
        };
        this.activityComponents = [];
        this.addApplicationTrip = this.addApplicationTrip.bind(this);
        this.addManureFertilizer = this.addManureFertilizer.bind(this);
        this.addHarvest = this.addHarvest.bind(this);
        this.saveOperations = this.saveOperations.bind(this);
        this.confirmHarvest = this.confirmHarvest.bind(this);
        this.cancelHarvest = this.cancelHarvest.bind(this);
    }

    handleExpansionChange = (panel, fieldActivity) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
        const missing = getValue(fieldActivity, "extrainfo.missing");
        if (missing > 0 && expanded) {
            this.setState({ snackbarMissing: missing, snackbarOpen: true });
        }
    };

    handleN2OSurveyExpansionChange = (panel, fieldActivity) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
        const missing = getValue(fieldActivity, "extrainfo.missing");
        if (missing > 0 && expanded) {
            this.setState({ snackbarMissing: missing, snackbarOpen: true });
        }
    };

    addApplicationTrip() {
        const { id } = this.props.cropYear;
        this.props.ormFieldActivityCreate({
            cropyear: id,
            type: "application",
            order: 4,
            application: {
                energyuse: {
                    herbicide_count: "0",
                    insecticide_count: "0",
                    fungicide_count: "0",
                    growthregulator_count: "0",
                    fumigant_count: "0",
                    harvestaid_count: "0"
                }
            }
        });
    }

    addManureFertilizer() {
        const { cropYear } = this.props,
            { id } = cropYear,
            nutrient_applied = getValue(cropYear, "waterquality.nutrient_applied");
        if (!nutrient_applied) {
            this.setState({ nutrientWarningOpen: true });
            return;
        }
        this.props.ormFieldActivityCreate({
            cropyear: id,
            type: "manure",
            order: 5
        });
    }

    addHarvest() {
        let pHarvests = this.props.fieldActivities.filter(fA => fA.type === "harvest");
        if (pHarvests.length > 0) {
            this.setState({ helpOpen: true });
        }
    }

    confirmHarvest() {
        let pHarvests = this.props.fieldActivities.filter(fA => fA.type === "harvest");

        let newHarvest = pHarvests[pHarvests.length - 1];
        newHarvest = Object.assign({}, newHarvest);
        delete newHarvest.id;
        this.props.ormFieldActivityCreate(newHarvest);
        this.setState({ helpOpen: false });
    }

    cancelHarvest() {
        const { id } = this.props.cropYear;
        this.props.ormFieldActivityCreate({
            cropyear: id,
            type: "harvest",
            order: 2
        });
        this.setState({ helpOpen: false });
    }

    componentDidMount() {
        this.props.onRef(this.saveOperations);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    saveOperations(draftClick, fromStepper) {
        const { id } = this.props.cropYear;
        const { fieldActivities, cropYear } = this.props;
        let values = {};

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        var cy = this.props.cropYear;
        cy["fertilizerCount"] = this.props.fertilizerCount;

        // Save all operation forms
        this.activityComponents.forEach(function(aC) {
            // Using setState does not work here for some reason (even with a timeout), but setting the state directly does...
            //aC.setState({ submitClicked: true });
            if (aC) {
                aC.state["submitClicked"] = true;
                aC.form.submitForm();
            }
        });

        var missing = 0;
        var required = 0;
        fieldActivities.forEach(function(key, index) {
            const missingQ = getValue(fieldActivities[index], "extrainfo.missing");
            const requiredQ = getValue(fieldActivities[index], "extrainfo.required");

            if (!missingQ && missingQ !== 0) {
                // If this happens the user has not even attempted to fill out the form
                // Just mark it as 1 missing
                missing++;
                required++;
            } else {
                missing = missing + parseInt(missingQ);
                required = required + parseInt(requiredQ);
            }
        });

        let manureFertilizers = this.props.fieldActivities.filter(fA => fA.type === "manure");

        var isCompletedVal = isCompleted(
            cropYear,
            this.props.cropYear,
            false,
            this.props.fertilizers,
            manureFertilizers.length,
            this.state.submitClicked
        );

        const c = getValue(cropYear, "extrainfo.completed");
        const m = getValue(cropYear, "extrainfo.missing");
        const r = getValue(cropYear, "extrainfo.required");
        setValue(values, "metrics", null);

        //#666 Set crop year to provisional if missing
        if ((c && c[0] === false) || isCompletedVal[0] !== 0 || missing !== 0) {
            setValue(values, "is_final", false);
        } else {
            setValue(values, "is_final", true);
        }

        this.props.ormCropYearUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: {
                    0: c ? c[0] : undefined,
                    1: isCompletedVal[0] === 0,
                    2: missing === 0
                },
                missing: {
                    0: m ? m[0] : undefined,
                    1: isCompletedVal[0],
                    2: missing
                },
                required: {
                    0: r ? r[0] : undefined,
                    1: isCompletedVal[1],
                    2: required
                }
            }
        });

        if (!Number.isInteger(fromStepper)) {
            if (draftClick) {
                const { fieldId } = this.props;
                this.props.history.push("/field/" + fieldId);
            } else {
                this.props.handleNext();
            }
        }
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            classes,
            cropYear,
            fieldActivities,
            ormFieldActivityUpdate,
            ormFieldActivityUpdateLocalOnly,
            ormFieldActivityDelete,
            fieldName,
            handleUnsavedFields,
            ormCropYearUpdate,
            ormCropYearUpdateLocalOnly
        } = this.props;
        const { expanded, snackbarOpen, snackbarMissing, helpOpen, nutrientWarningOpen } = this.state;

        return (
            <Grid container spacing={16}>
                <HelpDialog
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    question="Alfalfa Cuttings"
                    text="Would you like to copy from your previous Cutting information?"
                    confirmAction={this.confirmHarvest}
                    cancelAction={this.cancelHarvest}
                />

                <WarningDialog
                    noActions
                    onClose={() => this.setState({ nutrientWarningOpen: false })}
                    open={nutrientWarningOpen}
                    title="Add Manure Fertilizer"
                    text={
                        'In order to add a Manure Fertilizer, please answer "Yes" to the question "Did you apply fertilizer (either organic or inorganic source) this crop year?" in the Management section.'
                    }
                />
                <Grid item xs={12}>
                    <Typography variant="display3" gutterBottom>
                        Operations
                    </Typography>
                    <Typography>
                        The following inputs are related to field operations. Not all field operations in your crop
                        rotation management are required here. Data requested is based on metric input requirements.
                        Commercial Fertilizer and Crop Protectant Applications are organized on a per trip basis. For
                        example, you might have three commercial fertilizer and/or crop protectant trips over the course
                        of a season. In your first application trip, you might apply multiple fertilizers in addition to
                        crop protectants.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {fieldActivities.map(object => {
                        if (
                            object.type === "planting" ||
                            object.type === "irrigation" ||
                            (object.type === "harvest" && cropYear.crop !== ALFALFA)
                        ) {
                            return (
                                <ActivityDetails
                                    onRef={el => this.activityComponents.push(el)}
                                    fieldActivity={object}
                                    expanded={expanded}
                                    handleExpansionChange={this.handleExpansionChange}
                                    ormFieldActivityUpdate={ormFieldActivityUpdate}
                                    ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                                    ormFieldActivityDelete={ormFieldActivityDelete}
                                    cropYear={cropYear}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={handleUnsavedFields}
                                />
                            );
                        } else return null;
                    })}
                </Grid>
                {cropYear.crop === ALFALFA && (
                    <Grid item xs={12}>
                        <Typography variant="display3" gutterBottom>
                            Alfalfa Harvest Cutting Operations
                        </Typography>
                    </Grid>
                )}
                {cropYear.crop === ALFALFA && (
                    <Grid item xs={12}>
                        <Button className={classes.floatRight} onClick={this.addHarvest} variant="raised">
                            <AddCircleIcon />
                            &nbsp;&nbsp;&nbsp;Add Harvest Cutting
                        </Button>
                    </Grid>
                )}
                {cropYear.crop === ALFALFA && (
                    <Grid item xs={12}>
                        {fieldActivities.map(object => {
                            if (object.type === "harvest" && cropYear.crop === ALFALFA) {
                                return (
                                    <ActivityDetails
                                        onRef={el => this.activityComponents.push(el)}
                                        fieldActivity={object}
                                        expanded={expanded}
                                        handleExpansionChange={this.handleExpansionChange}
                                        ormFieldActivityUpdate={ormFieldActivityUpdate}
                                        ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                                        ormFieldActivityDelete={ormFieldActivityDelete}
                                        cropYear={cropYear}
                                        ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                        handleUnsavedFields={handleUnsavedFields}
                                    />
                                );
                            } else return null;
                        })}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="display3" gutterBottom>
                        Fertilizer and Spraying Operations
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={this.addApplicationTrip} variant="raised">
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add Commercial Fertilizer and Protectant Trip
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {fieldActivities.map(object => {
                        if (object.type === "application") {
                            return (
                                <ActivityDetails
                                    onRef={el => this.activityComponents.push(el)}
                                    fieldActivity={object}
                                    expanded={expanded}
                                    handleExpansionChange={this.handleExpansionChange}
                                    ormFieldActivityUpdate={ormFieldActivityUpdate}
                                    ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                                    ormFieldActivityDelete={ormFieldActivityDelete}
                                    cropYear={cropYear}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={handleUnsavedFields}
                                    fertilizerCount={this.props.fertilizerCount}
                                />
                            );
                        } else return null;
                    })}
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={this.addManureFertilizer} variant="raised">
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add Manure Fertilizer Trip
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {fieldActivities.map(object => {
                        if (object.type === "manure") {
                            return (
                                <ActivityDetails
                                    onRef={el => this.activityComponents.push(el)}
                                    fieldActivity={object}
                                    expanded={expanded}
                                    handleExpansionChange={this.handleExpansionChange}
                                    ormFieldActivityUpdate={ormFieldActivityUpdate}
                                    ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                                    ormFieldActivityDelete={ormFieldActivityDelete}
                                    cropYear={cropYear}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={handleUnsavedFields}
                                    fertilizerCount={this.props.fertilizerCount}
                                />
                            );
                        } else return null;
                    })}
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="raised"
                        onClick={() => this.saveOperations(true, false)}
                        className={classes.button}>
                        Save and Return to {fieldName} Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={() => this.saveOperations(false, false)}
                        className={classes.button}>
                        Save and Proceed to {hasFeatureFlag("crop_history") ? "Crop History" : "Review Screen"}{" "}
                        <ChevronRightIcon />
                    </Button>
                </Grid>
                <Snackbar error count={snackbarMissing} onClose={this.handleSnackbarClose} open={snackbarOpen} />
            </Grid>
        );
    }
}

FieldActivities = connect(
    (state, ownProps) => ({
        fieldActivities: getFieldActivities(state, ownProps),
        fertilizerCount: getFertilizerCount(state, ownProps),
        fertilizers: getFertilizers(state, ownProps)
    }),
    {
        ...FieldActivity.actions,
        ...Fertilizer.actions
    }
)(FieldActivities);

export default withStyles(styles)(FieldActivities);
