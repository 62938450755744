import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import ResearchDialog from "../research/ResearchDialog";
import * as navActions from "../common/actions";
import CloseIcon from "../common/icons/CloseIcon";
import {
    LineChart,
    Line,
    Label,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Cell,
    LabelList
} from "recharts";

const styles = theme => ({
    grey: {
        backgroundColor: "#eeeeee",
        border: "4px solid #ffffff",
        whiteSpace: "nowrap"
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    centerAlign: {
        textAlign: "center"
    },
    standardMargin: {
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8
    },
    divideButton: {
        marginBottom: 8,
        width: "50%",
        marginTop: 16,
        minWidth: 44
    },
    filterButton: {
        marginBottom: 8,
        width: "100%",
        border: "1px solid #666666"
    },
    buttonSelected: {
        border: "3px solid #00adee",
        minWidth: "44px"
    },
    iconSelected: {
        color: "#00adee"
    },
    mapContainer: {
        height: 600,
        paddingRight: "4px !important",
        paddingLeft: "4px !important"
    },
    smallerIcon: {
        width: ".9em"
    },
    paddingRight: {
        paddingRight: "24px !important"
    },
    paddingRightField: {
        [theme.breakpoints.down("lg")]: {
            paddingRight: "24px !important"
        }
    },
    marker: {
        height: 18,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: -3
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },

    // Facts
    hidden: {
        opacity: 0,
        overflow: "hidden",
        padding: "0 !important",
        height: "0 !important"
    },
    panelText: {
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#00adee",
        marginLeft: "auto",
        marginRight: "auto",
        width: "155px",
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: "0 16px 0 8px"
    },
    panelContainer: {
        padding: "20px",
        height: "130px",
        width: "100%",
        transition: theme.transitions.create(["height", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    button: {
        height: "20px",
        width: "20px",
        color: "#fff"
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: "#00adee"
    },
    floatRight: {
        float: "right",
        marginRight: theme.spacing(1)
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    underlineBottom: {
        borderBottom: "1px dotted",
        marginBottom: theme.spacing(2),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    linkColor: {
        color: "#808080"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
        float: "right"
    }
});

const linedata = [
    {
        name: "2020",
        pv: 1,
        color: "#9f88be"
    },
    {
        name: "2021",
        pv: 587,
        color: "#ef8632"
    },
    {
        name: "2022",
        pv: 453,
        color: "#d0352b"
    },
    {
        name: "2023",
        pv: 485,
        color: "#549e3f"
    }
];

const bardata = [
    {
        name: "2020",
        pv: 20,
        uv: 22,
        color: "#9f88be"
    },
    {
        name: "2021",
        pv: 31438,
        uv: 33576,
        color: "#ef8632"
    },
    {
        name: "2022",
        pv: 26201,
        uv: 27222,
        color: "#d0352b"
    },
    {
        name: "2023",
        pv: 29100,
        uv: 31618,
        color: "#549e3f"
    }
];

const energyData = [
    {
        name: "2021",
        pv: 42500,
        color: "#ef8632"
    },
    {
        name: "2022",
        pv: 38762,
        color: "#d0352b"
    },
    {
        name: "2023",
        pv: 34276,
        color: "#549e3f"
    }
];

const CustomizedDot = props => {
    const { cx, cy, r, stroke, payload } = props;
    const color = payload.color || stroke;

    return <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={1} fill={color} />;
};

const LineLbl = props => {
    const { x, y, value } = props;

    const formattedNumber = value.toLocaleString(undefined, { maximumFractionDigits: 0 });

    return (
        <text x={x} y={y} dy={-10} fill={"8884d8"} fontSize={10} textAnchor="middle" fontWeight={"bold"}>
            {formattedNumber}
        </text>
    );
};

const BarLbl = ({ x, y, fill, width, value }) => {
    const formattedNumber = value.toLocaleString(undefined, { maximumFractionDigits: 0 });

    return (
        <text x={x + width / 2} y={y} dy={-5} fill={"8884d8"} fontSize={10} textAnchor="middle" fontWeight={"bold"}>
            {formattedNumber}
        </text>
    );
};

const tickFormatter = number => number.toLocaleString(undefined, { maximumFractionDigits: 0 });

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

class Insights extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { classes } = this.props;

        return (
            <AppContainer
                authenticated
                header={
                    <>
                        <PageHeader title="Insights" color="dashboard" />

                        <ResearchDialog />
                    </>
                }>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={9}>
                            <Grid style={{ marginTop: "20px" }}>
                                <Typography style={{ fontWeight: "bold" }}>
                                    Summary of Fields and Acres <CloseIcon className={classes.iconSize} />
                                </Typography>
                                <ColoredLine color="#666666" />
                                <Typography>
                                    Below are summary data across years for all fields and acres entered in the
                                    Platform. This data includes both complete and incomplete crop years.
                                </Typography>
                                <Grid style={{ marginTop: "20px", height: "350px" }}>
                                    <ResponsiveContainer width="95%" height="90%">
                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={linedata}
                                            margin={{
                                                top: 60,
                                                right: 30,
                                                left: 0,
                                                bottom: 5
                                            }}>
                                            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />

                                            <Tooltip />
                                            <Line
                                                type="monotone"
                                                dataKey="pv"
                                                stroke="#8884d8"
                                                label={<LineLbl />}
                                                dot={<CustomizedDot r={5} />}
                                            />
                                            <text
                                                x={0}
                                                y={14}
                                                fill="#666666"
                                                textAnchor="left"
                                                dominantBaseline="central">
                                                <tspan fontSize="14" fontWeight={"Bold"}>
                                                    Total fields entered in the Platform across years
                                                </tspan>
                                            </text>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <Grid style={{ marginTop: "20px", height: "350px" }}>
                                    <ResponsiveContainer width="95%" height="90%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={bardata}
                                            margin={{
                                                top: 40,
                                                right: 30,
                                                left: 0,
                                                bottom: 5
                                            }}>
                                            <defs>
                                                {bardata.map((entry, index) => (
                                                    <pattern
                                                        key={`hatch${index}`}
                                                        id={`hatch${index}`}
                                                        width="5"
                                                        height="5"
                                                        patternUnits="userSpaceOnUse"
                                                        patternTransform="rotate(45 0 0)">
                                                        <line
                                                            x1="0"
                                                            y1="0"
                                                            x2="0"
                                                            y2="5"
                                                            style={{ stroke: entry.color, strokeWidth: "5" }}
                                                        />
                                                    </pattern>
                                                ))}
                                            </defs>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                            <YAxis tickFormatter={tickFormatter} />
                                            <Tooltip />
                                            <Bar dataKey="pv">
                                                {bardata.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                                <LabelList content={<BarLbl />} position="top" />
                                            </Bar>
                                            <Bar dataKey="uv">
                                                {bardata.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={`url(#hatch${index})`} />
                                                ))}
                                                <LabelList content={<BarLbl />} position="top" />
                                            </Bar>

                                            <text
                                                x={0}
                                                y={14}
                                                fill="#666666"
                                                textAnchor="left"
                                                dominantBaseline="central">
                                                <tspan fontSize="14" fontWeight={"Bold"}>
                                                    Total enrolled and entered acres across years
                                                </tspan>
                                            </text>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Typography style={{ fontWeight: "bold" }}>
                                    Aggregate Metric Data <CloseIcon className={classes.iconSize} />
                                </Typography>
                                <ColoredLine color="#666666" />
                                <Typography>
                                    Field to Market metrics are aggrigated by crop and irrigation type (irrigated or
                                    rainfed). Select the desired metric and crop from the drop downs below.
                                </Typography>
                            </Grid>
                            <Grid style={{ marginTop: "20px", height: "350px" }}>
                                <ResponsiveContainer width="95%" height="90%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={energyData}
                                        margin={{
                                            top: 40,
                                            right: 30,
                                            left: 20,
                                            bottom: 15
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" padding={{ left: 30, right: 30 }}>
                                            <Label
                                                value="BTU to produce a unit of crop (btu/bushel)"
                                                offset={-5}
                                                position="insideBottom"
                                                style={{
                                                    fontFamily: "'Source Sans Pro',sans-serif",
                                                    color: "#666666",
                                                    fontStyle: "italic"
                                                }}
                                            />
                                        </XAxis>
                                        <YAxis tickFormatter={tickFormatter}>
                                            <Label
                                                value="Energy Use"
                                                offset={-5}
                                                angle={-90}
                                                position="insideLeft"
                                                style={{
                                                    fontFamily: "'Source Sans Pro',sans-serif",
                                                    color: "#666666",
                                                    fontStyle: "italic",
                                                    textAnchor: "middle"
                                                }}
                                            />
                                        </YAxis>
                                        <Tooltip />
                                        <Bar dataKey="pv" label={<BarLbl />}>
                                            {energyData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Bar>

                                        <text x={0} y={14} fill="#666666" textAnchor="left" dominantBaseline="central">
                                            <tspan fontSize="14" fontWeight={"Bold"}>
                                                Aggregate Energy Use for Wheat (winter) (irrigated) across years.
                                            </tspan>
                                        </text>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Grid style={{ marginTop: "20px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Whole-Farm Report</Typography>
                                <ColoredLine color="#666666" />
                                <Typography>
                                    Generate a Whole-Farm Report. The report includes all available metric insights
                                    across crops and years. The report does not include individual crop-year fieldprint
                                    analysis reports.
                                </Typography>
                            </Grid>
                            <Grid style={{ marginTop: "20px" }}>
                                <Typography style={{ fontWeight: "bold" }}>Fieldprint Analysis Reports</Typography>
                                <ColoredLine color="#666666" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Insights = connect(
    state => ({
        navState: state.nav
    }),
    navActions
)(Insights);

export default withStyles(styles)(Insights);
