import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

import Divider from "@mui/material/Divider";
import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import states from "../common/states.json";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "red",
        "text-align": "center",
        "font-size": 16
    },
    centerAlign: {
        textAlign: "center !important"
    },
    linkColor: {
        // TODO: global theme?
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline"
    }
};

class UpdatePage extends Component {
    errorValidator = values => {
        const validateFirstName = name => {
            return !name ? "First Name is required" : null;
        };
        const validateLastName = name => {
            return !name ? "Last Name is required" : null;
        };
        const validateZip = zip => {
            return !zip || !zip.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/g)
                ? "Invalid zip code (Ex: 55555 or 55555-1234)"
                : null;
        };
        const validatePhone = phone => {
            if (!phone) return "Invalid Phone (Ex: 555-555-5555)";
            const phonematch = phone.match(/\d/g);
            return !phonematch || phonematch.length !== 10 ? "Invalid Phone (Ex: 555-555-5555)" : null;
        };
        return {
            first_name: validateFirstName(values.first_name),
            last_name: validateLastName(values.last_name),
            zip: validateZip(values.zip),
            phone: validatePhone(values.phone)
        };
    };
    errorValidatorPassword = values => {
        const validatePasswordSingle = password => {
            if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            current_password: validatePasswordSingle(values.current_password),
            new_password: validatePassword(values.new_password, values.re_new_password),
            re_new_password: validatePassword(values.new_password, values.re_new_password)
        };
    };

    submitForm = values => {
        delete values.research_last_shown;
        this.props.authUpdate(values).then(() => {
            if (this.props.update.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    submitFormPassword = values => {
        this.props.authPasswordChange(values).then(() => {
            if (this.props.change.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    render() {
        const { classes, update, change, authState } = this.props;
        return (
            <AppContainer authenticated>
                <Grid container spacing={4}>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={authState.user}
                        validateError={this.errorValidator}
                        onSubmit={this.submitForm}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Typography variant="title" gutterBottom>
                                                User Profile
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24} gutterBottom>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="email"
                                                label="User Name (login)"
                                                fullWidth
                                                disabled
                                                margin="normal"
                                            />
                                            <TextField
                                                field="first_name"
                                                label="First Name*"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField field="last_name" label="Last Name*" fullWidth margin="normal" />
                                            <TextField
                                                field="phone"
                                                label="Telephone*"
                                                placeholder="555-555-5555"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField field="address" label="Address" fullWidth margin="normal" />
                                            <TextField field="address2" label="Address 2" fullWidth margin="normal" />
                                            <TextField field="city" label="City" fullWidth margin="normal" />
                                            <Select
                                                field="state"
                                                label="State"
                                                options={states}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                field="zip"
                                                label="Zip Code*"
                                                placeholder="55555"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={40}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Submit
                                                label="Update User Information"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={update.pending}
                                            />
                                            <div className={classes.registerError}>{update.error}</div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                    <Grid item xs={12} md={8} lg={6}>
                        <Divider />
                    </Grid>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        validateError={this.errorValidatorPassword}
                        onSubmit={this.submitFormPassword}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={40}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Typography variant="title" gutterBottom>
                                                Update Password
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={40} gutterBottom>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="current_password"
                                                label="Current Password"
                                                type="password"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                field="new_password"
                                                label="New Password"
                                                type="password"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                field="re_new_password"
                                                label="Retype New Password"
                                                type="password"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={40}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Submit
                                                label="Update Password"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={change.pending}
                                            />
                                            <div className={classes.registerError}>{change.error}</div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                    <Grid item xs={12} md={8} lg={6}>
                        <Typography variant="title" gutterBottom>
                            Request Account Deletion
                        </Typography>
                        <Typography>
                            In accordance with the Field to Market Privacy and Data Policy, users can delete their
                            accounts and their data in the system. If you wish to delete your account, please contact us
                            at&nbsp;
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            .
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

UpdatePage = connect(
    state => ({
        authState: state.auth,
        update: (state.auth && state.auth.update) || {},
        change: (state.auth && state.auth.change) || {}
    }),
    authActions
)(UpdatePage);

export default withStyles(styles)(withRouter(UpdatePage));
