import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Grid from "../../common/GridWrapper";

import { getValue } from "../../../api/utils";
import {
    GAL,
    LBS,
    ALFALFA,
    CORN_SILAGE,
    COTTON,
    PEANUTS,
    POTATOES,
    RICE,
    SUGAR_BEETS,
    NO_DRYING,
    NO_DRYING_ALFALFA,
    FUEL,
    FARM_YARD_MANURE
} from "../../../api/constants";
import { getUnitsValue, getPercentValue } from "./StaticData";

import { connect } from "react-redux";
import { SoilTextures } from "../models";

import AutoRow from "./inputs/InputTable";
import PageHeader from "./inputs/PageHeader";
import PageFooter from "./inputs/PageFooter";

import {
    PreviousCrops,
    TillageClasses,
    PredominantRotationPractices,
    ResidueCovers,
    CoverCropSpecies,
    CoverCropEstablishTimings,
    TileDrainage,
    ManagementTechniques,
    PMultiYearApp,
    PSoilTestLevel,
    ConservationPractices,
    WildlifeHabitat,
    IntegratedPestManagement,
    FieldActivity,
    LandConversionTypes,
    Nutrient4RS,
    RiceWaterRegime
} from "../fieldactivities/models";

import {
    RiceCultivars,
    RicePlantingMethods,
    SeedingRates,
    IrrigationMethods,
    PumpPressures,
    PumpingDepths,
    WaterSources,
    DryingSystems,
    FuelTypes,
    AbandonmentReasons,
    EnergySources,
    AlfalfaHarvestTypes,
    CottonMoisture,
    AlfalfaDryingSystems,
    RiceYieldMethods,
    Fertilizers,
    ApplicationTimes,
    DominantApplicationMethods,
    ManureAmounts,
    ManureTypes,
    OrganicFertTypes
} from "../fieldactivities/activities/models";
import {} from "../fieldactivities/activities/models";

import { createSelector } from "../../common/orm";
import { RotationSystem } from "../../rotation/models";

const allRotationSystems = RotationSystem.selectAll();
const allTillageClasses = TillageClasses.selectAll();
const allResidueCovers = ResidueCovers.selectAll();
const allCoverCropSpecies = CoverCropSpecies.selectAll();
const allCoverCropEstablishTimings = CoverCropEstablishTimings.selectAll();
const allPredominantRotationPractices = PredominantRotationPractices.selectAll();
const allTileDrainage = TileDrainage.selectAll();
const allDominantApplicationMethods = DominantApplicationMethods.selectAll();
const allManagementTechniques = ManagementTechniques.selectAll();
const allPMultiYearApp = PMultiYearApp.selectAll();
const allPSoilTestLevel = PSoilTestLevel.selectAll();
const allConservationPractices = ConservationPractices.selectAll();
const getIrrigationFieldActivities = FieldActivity.selectAll();
const allWildlifeHabitats = WildlifeHabitat.selectAll();
const allLandConversionTypes = LandConversionTypes.selectAll();
const allNutrient4RS = Nutrient4RS.selectAll();
const allRiceWaterRegime = RiceWaterRegime.selectAll();
const activeIntegratedPestManagement = IntegratedPestManagement.selectAll();

const allRiceCultivars = RiceCultivars.selectAll();
const allRicePlantingMethods = RicePlantingMethods.selectAll();
const allSeedingRates = SeedingRates.selectAll();
const allSoilTextures = SoilTextures.selectAll();
const allIrrigationMethods = IrrigationMethods.selectAll();
const allPumpPressures = PumpPressures.selectAll();
const allPumpingDepths = PumpingDepths.selectAll();
const allWaterSources = WaterSources.selectAll();
const allApplicationTimes = ApplicationTimes.selectAll();
const allFertilizers = Fertilizers.selectAll();
const allManureTypes = ManureTypes.selectAll();
const allManureAmounts = ManureAmounts.selectAll();
const allOrganicFertTypes = OrganicFertTypes.selectAll();
const allDryingSystems = DryingSystems.selectAll();
const allFuelTypes = FuelTypes.selectAll();
const allAbandonmentReasons = AbandonmentReasons.selectAll();
const allEnergySources = EnergySources.selectAll();
const allAlfalfaHarvestTypes = AlfalfaHarvestTypes.selectAll();
const allCottonMoisture = CottonMoisture.selectAll();
const allAlfalfaDryingSystems = AlfalfaDryingSystems.selectAll();
const allRiceYieldMethods = RiceYieldMethods.selectAll();

const getSelectedRotation = createSelector(
    (state, ownProps) => ownProps.cropYear.rotation,
    (session, systemId) => {
        if (session.RotationSystem.hasId(systemId)) {
            return session.RotationSystem.withId(systemId);
        } else {
            return { name: "No Template Selected" };
        }
    }
);

const allCrops = createSelector(schema => {
    return schema.Crops.all()
        .orderBy("name")
        .toModelArray();
});
const allPreviousCrops = PreviousCrops.selectAll();

const OTHER_DRYING = [COTTON, POTATOES, SUGAR_BEETS, CORN_SILAGE, ALFALFA, PEANUTS];
const MAX_PAGE_LENGTH = 44.1, // 882 / 20
    MAX_LAST_PAGE_LENGTH = MAX_PAGE_LENGTH - 4;

// Custom CSS classes
const styles = theme => ({
    page: {
        height: "11in",
        width: "8.5in",
        overflow: "hidden",
        position: "relative",
        pageBreakAfter: "always"
    },
    inputContent: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in"
    },
    inputContentPage1: {
        marginTop: "0em"
    },
    "@media screen": {
        page: {
            border: "1px solid #ccc",
            marginTop: "1em"
        }
    }
});

function getValueForId(apiData, id) {
    var obj = apiData && apiData.find(o => o.id === id);

    if (obj) {
        return obj.name;
    } else if (id) {
        return `ID: ${id}`;
    } else {
        return null;
    }
}

function getManureUnits(value) {
    if (GAL.includes(value)) {
        return "gal / acre";
    } else if (LBS.includes(value)) {
        return "lb / acre";
    } else {
        return "";
    }
}

function getInputData(section, cropYear, apiData = {}) {
    var data = [];

    const result = getValue(cropYear, "metrics.Calculator.result.cropyears[0]");

    if (section === "inputSoils") {
        return getSoilsInput(cropYear, apiData);
    } else if (section === "inputRotation") {
        return getRotationInput(cropYear, apiData);
    } else if (section === "inputManagement") {
        return getManagementInput(cropYear, result, apiData);
    } else if (section === "inputPlanting") {
        return getPlantingInput(cropYear, apiData);
    } else if (section === "inputIrrigation") {
        return getIrrigationInput(cropYear, result, apiData);
    } else if (section === "inputHarvesting") {
        return getHarvestingInput(cropYear, result, apiData);
    } else if (section === "inputAlfalfaHarvesting") {
        return getAlfalfaHarvesting(cropYear, result, apiData);
    } else if (section === "inputFertilizers") {
        return getFertilizerInput(cropYear, apiData);
    } else if (section === "inputManure") {
        return getManureInput(cropYear, apiData);
    }

    return data;
}

function getSoilsInput(cropYear, apiData) {
    const soilInfo = getValue(cropYear, "metrics.Calculator.result.metadata.soilInfo");
    var rows = [];

    rows.push({
        type: "Map",
        value: cropYear.fieldObject,
        rows: 16.8 // (320 + 8 + 8) / 20
    });

    rows.push({
        type: "item",
        value: {
            label: "Slope",
            value: soilInfo
                ? getValue(soilInfo, "slope.value") + " " + getValue(soilInfo, "slope.unit")
                : getPercentValue(cropYear, "fieldObject.soils.slope")
        }
    });
    rows.push({
        type: "item",
        value: {
            label: "Slope Length",
            value: soilInfo
                ? getValue(soilInfo, "slopeLength.value") + " " + getValue(soilInfo, "slopeLength.unit")
                : (getValue(cropYear, "fieldObject.soils.slope_length") || "").split(" ")[0] + " feet"
        }
    });
    rows.push({
        type: "item",
        value: {
            label: "Surface Soil Texture Class",
            value: soilInfo
                ? getValue(soilInfo, "texture")
                : getValueForId(apiData.soils, getValue(cropYear, "fieldObject.greenhouse.soil_texture_id"))
        }
    });

    return rows;
}

function getRotationInput(cropYear, apiData) {
    var rows = [];

    rows.push({
        type: "item",
        value: {
            label: "Was the previous crop residue burned?",
            value: getValue(cropYear, "greenhouse.residue_burning") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "greenhouse.residue_burning")) {
        rows.push({
            type: "item",
            value: {
                label: "What was the primary crop grown in the previous year?",
                value: getValueForId(apiData.previousCrops, getValue(cropYear, "greenhouse.previous_crop_id")),
                subitem: true
            }
        });
        if (getValue(cropYear, "greenhouse.previous_crop_id") !== "999") {
            rows.push({
                type: "item",
                value: {
                    label: "What was the yield in the previous year? ",
                    value: getValue(cropYear, "greenhouse.yield_previous"),
                    subitem: true
                }
            });
        }
    }

    if (getValue(cropYear, "crop_name") === "Rice" || getValue(cropYear, "crop_name") === "Alfalfa") {
        rows.push({
            type: "item",
            value: {
                label: "How much residue remains on the field from the previous year?",
                value: getValueForId(apiData.residueCovers, getValue(cropYear, "energyuse.previous_residue"))
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "What is the predominant crop rotation practice used?",
            value: getValueForId(
                apiData.predominantRotationPractices,
                getValue(cropYear, "biodiversity.rotation_practice")
            )
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Do you use a cover crop?",
            value: getValue(cropYear, "biodiversity.cover_crop") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "biodiversity.cover_crop")) {
        rows.push({
            type: "item",
            value: {
                label: "Dominant Species",
                value: getValueForId(apiData.coverCropSpecies, getValue(cropYear, "waterquality.cover_crop_species")),
                subitem: true
            }
        });
        rows.push({
            type: "item",
            value: {
                label: "Establishment Timing",
                value: getValueForId(
                    apiData.coverCropEstablishTimings,
                    getValue(cropYear, "waterquality.cover_crop_establish_timing")
                ),
                subitem: true
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "What is the predominant tillage practice used?",
            value: getValueForId(apiData.tillageClasses, getValue(cropYear, "biodiversity.tillage_class"))
        }
    });

    const events = [...cropYear.rotation.events].sort(dateSort),
        headerSize = 3.45, // (27 + 26 + 8 + 8) / 20
        maxEvents = Math.floor(MAX_PAGE_LENGTH - headerSize);

    for (let offset = 0; offset < events.length; offset += maxEvents) {
        const rowEvents = events.slice(offset, offset + maxEvents);
        rows.push({
            type: "Rotation",
            value: {
                ...cropYear.rotation,
                firstDate: events[0].date,
                events: rowEvents
            },
            rows: headerSize + rowEvents.length
        });
    }

    return rows;
}

function dateSort(event1, event2) {
    if (event1.date < event2.date) {
        return -1;
    } else if (event1.date > event2.date) {
        return 1;
    } else {
        return 0;
    }
}

function getManagementInput(cropYear, result, apiData) {
    function get4RS() {
        if (getValue(cropYear, "biodiversity.nutrient_4_rs", []).length) {
            var ids = getValue(cropYear, "biodiversity.nutrient_4_rs", []).sort();
            var names = ids.map(id => getValueForId(apiData.nutrient4RS, id)).join(", ");
            return names;
        } else if (getValue(cropYear, "biodiversity.nutrient_management_plan")) {
            return "Yes";
        } else {
            return "No";
        }
    }

    function getWildlifeHabitats() {
        if (getValue(cropYear, "biodiversity.wildlife_habitat_ids", []).length) {
            var ids = getValue(cropYear, "biodiversity.wildlife_habitat_ids", []).sort();
            var names = ids.map(id => getValueForId(apiData.wildlifeHabitat, id)).join(", ");
            return names;
        } else {
            return "No";
        }
    }

    function getManagementTechniques() {
        const ids = getValue(cropYear, "waterquality.management_techniques", []);
        if (ids.length) {
            return ids
                .sort()
                .map(id => getValueForId(apiData.managementTechniques, id))
                .map(label => label.replace("Nutrient Application", "App."))
                .join("\n");
        } else {
            return "No";
        }
    }

    function getConservationPractices() {
        const ids = getValue(cropYear, "waterquality.conservation_codes", []);
        if (ids.length) {
            return ids
                .sort()
                .map(id => getValueForId(apiData.conservationPractices, id))
                .join(", ");
        } else {
            return "No";
        }
    }

    var rows = [];

    rows.push({
        type: "item",
        value: {
            label: "Does the field have a wind barrier based on NRCS standards?",
            value: getValue(cropYear, "soils.has_wind_barriers") ? "Yes" : "No"
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Tile Drainage System Type",
            value: getValueForId(apiData.tileDrainage, getValue(cropYear, "waterquality.tile_drainage"))
        }
    });

    if (getValue(cropYear, "crop_name") === "Rice") {
        rows.push({
            type: "item",
            value: {
                label: "Do you practice alternate wetting and drying during the rice growing season?",
                value: getValueForId(apiData.riceWaterRegime, getValue(cropYear, "greenhouse.water_regime"))
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "Are you implementing an approved Nutrient Management Plan?",
            value: getValue(cropYear, "biodiversity.nutrient_management_plan") ? "Yes" : "No"
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Do you apply nutrients according to the 4 R’s of nutrient stewardship?",
            value: get4RS()
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Did you apply lime for this crop?",
            value: getValue(cropYear, "energyuse.lime_applied") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "energyuse.lime_applied")) {
        rows.push({
            type: "item",
            value: {
                label: "What year was it applied?",
                value: getValue(cropYear, "energyuse.lime_year_applied"),
                subitem: true
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Estimated Number of Years Lime will be Applicable",
                value: getValue(cropYear, "energyuse.lime_years"),
                subitem: true
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Product Amount",
                value: getValue(cropYear, "energyuse.lime_amount"),
                subitem: true
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "Did you apply fertilizer (either organic or inorganic source) this crop year?",
            value: getValue(cropYear, "waterquality.nutrient_applied") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "waterquality.nutrient_applied")) {
        rows.push({
            type: "item",
            value: {
                label: "Did you apply nitrogen this crop year?",
                value: getValue(cropYear, "waterquality.nitrogen_applied") ? "Yes" : "No"
            }
        });
        if (getValue(cropYear, "waterquality.nitrogen_applied")) {
            rows.push({
                type: "item",
                value: {
                    label: "What was the nitrogen carry over from last season?",
                    value: getValue(cropYear, "waterquality.n_carry_over") || "50 lb / acre (default)",
                    subitem: true
                }
            });
        }
        rows.push({
            type: "item",
            value: {
                label: "Did you apply phosphorus this crop year?",
                value: getValue(cropYear, "waterquality.phosphorus_applied") ? "Yes" : "No"
            }
        });
        if (getValue(cropYear, "waterquality.phosphorus_applied")) {
            rows.push({
                type: "item",
                value: {
                    label: "How many crop years is the phosphorus intended to supply?",
                    value: getValueForId(apiData.pMultiYearApp, getValue(cropYear, "waterquality.p_multi_year_app")),
                    subitem: true
                }
            });
            rows.push({
                type: "item",
                value: {
                    label: "Phosphorus soil test (within past 4 years)",
                    value: getValueForId(apiData.pSoilTestLevel, getValue(cropYear, "waterquality.p_soil_test_level")),
                    subitem: true
                }
            });
        }
        rows.push({
            type: "item",
            value: {
                label: "Nutrient Management Techniques",
                value: getManagementTechniques()
            }
        });
    }

    if (getValue(cropYear, "crop_name") === "Rice") {
        rows.push({
            type: "item",
            value: {
                label: "Did you apply Sulfate?",
                value: getValue(cropYear, "greenhouse.has_sulfur") ? "Yes" : "No"
            }
        });

        if (getValue(cropYear, "greenhouse.has_sulfur")) {
            rows.push({
                type: "item",
                value: {
                    label: "Product Amount ",
                    value: getValue(cropYear, "greenhouse.sulfur_rate"),
                    subitem: true
                }
            });
        }
    }

    rows.push({
        type: "item",
        value: {
            label: "Integrated Pest Management",
            value: getValueForId(
                apiData.integratedPestManagement,
                getValue(cropYear, "waterquality.pest_management_id")
            )
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Was any part of this field converted from anything other than cropland in the past 5 years.",
            value: getValue(cropYear, "biodiversity.land_cover_change") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "biodiversity.land_cover_change")) {
        rows.push({
            type: "item",
            value: {
                label: "Type of Land Conversion",
                value: getValueForId(
                    apiData.landConversionTypes,
                    getValue(cropYear, "biodiversity.previous_land_cover")
                ),
                subitem: true
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Acres",
                value: getValue(cropYear, "biodiversity.field_area_converted"),
                subitem: true
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "Do you practice any seasonal enhancements for wildlife habitat?",
            value: getWildlifeHabitats()
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Conservation Practices",
            value: getConservationPractices()
        }
    });

    return rows;
}

function getPlantingInput(cropYear, apiData) {
    var activity = getValue(cropYear, "activities", []).filter(
        activity => getValue(activity, "type") === "planting"
    )[0];

    var seedingRateObj =
        (apiData.seedingRates || []).find(sr => sr.id === getValue(activity, "planting.energyuse.seeding_rate")) || {};

    var rows = [];

    rows.push({ type: "Header2", value: "Planting" });

    rows.push({
        type: "item",
        value: {
            label: "Seeding Rate",
            value:
                getValueForId(apiData.seedingRates, getValue(activity, "planting.energyuse.seeding_rate")) +
                " " +
                seedingRateObj.units
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Are Seed Treatments Used?",
            value: getValue(activity, "planting.energyuse.has_seed_treatment") ? "Yes" : "No"
        }
    });

    if (getValue(cropYear, "crop_name") === "Rice") {
        rows.push({
            type: "item",
            value: {
                label: "Planting Method",
                value: getValueForId(
                    apiData.ricePlantingMethods,
                    getValue(activity, "planting.greenhouse.seeding_method")
                )
            }
        });

        if (getValue(activity, "planting.greenhouse.rice_cultivar")) {
            rows.push({
                type: "item",
                value: {
                    label: "Cultivar",
                    value: getValueForId(apiData.riceCultivars, getValue(activity, "planting.greenhouse.rice_cultivar"))
                }
            });
        }
    }

    if (getValue(cropYear, "crop_name") === "Alfalfa") {
        rows.push({
            type: "item",
            value: {
                label: "Establishment Year ",
                value: getValue(activity, "planting.energyuse.establishment_year")
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Expected Stand Life ",
                value: getValue(activity, "planting.energyuse.stand_life")
            }
        });
    }

    return rows;
}

function getIrrigationInput(cropYear, result, apiData) {
    var activity = getValue(cropYear, "activities", []).filter(
        activity => getValue(activity, "type") === "irrigation"
    )[0];

    var rows = [];
    rows.push({ type: "Header2", value: "Irrigation" });

    rows.push({
        type: "item",
        value: {
            label: "Water Source",
            value: getValueForId(apiData.waterSources, getValue(activity, "irrigation.wateruse.water_source"))
        }
    });

    rows.push({
        type: "item",
        value: {
            label: "Do you practice any water conservation methods?",
            value: getValue(activity, "irrigation.biodiversity.irrigation_conservation") ? "Yes" : "No"
        }
    });

    ["surface", "ground"].forEach(item => {
        if (getValue(activity, "irrigation.wateruse." + item + "_water_applied")) {
            rows.push({
                type: "Header3",
                value: item === "surface" ? "Surface Water Irrigation" : "Ground Water Irrigation"
            });

            rows.push({
                type: "item",
                value: {
                    label: "Water applied",
                    value: getUnitsValue(activity, "irrigation.wateruse." + item + "_water_applied")
                }
            });

            const hasEnergy = getValue(activity, "irrigation.energyuse." + item + "_has_irrigation_energy");

            rows.push({
                type: "item",
                value: {
                    label: "Do you use energy to irrigate the field through pumping?",
                    value: hasEnergy ? "Yes" : "No"
                }
            });

            if (hasEnergy) {
                const energysource = getValue(activity, "irrigation.energyuse." + item + "_energy_source");
                rows.push({
                    type: "item",
                    value: {
                        label: "Energy source",
                        subitem: true,
                        value: getValueForId(apiData.energySources, energysource)
                    }
                });

                if (energysource === FUEL) {
                    rows.push({
                        type: "item",
                        value: {
                            label: "Fuel Type",
                            value: getValueForId(
                                apiData.fuelTypes,
                                getValue(activity, "irrigation.energyuse." + item + "_fuel_id", "N/A")
                            ),
                            subitem: true
                        }
                    });
                }

                if (getValue(activity, "irrigation.energyuse." + item + "_use_actual")) {
                    if (energysource === FUEL) {
                        rows.push({
                            type: "item",
                            value: {
                                label: "Fuel Amount",
                                value: getValue(activity, "irrigation.energyuse." + item + "_fuel_amount", "N/A"),
                                subitem: true
                            }
                        });
                    } else {
                        rows.push({
                            type: "item",
                            value: {
                                label: "Electricity Amount",
                                value: getValue(
                                    activity,
                                    "irrigation.energyuse." + item + "_electricity_amount",
                                    "N/A"
                                ),
                                subitem: true
                            }
                        });
                    }
                } else {
                    rows.push({
                        type: "item",
                        value: {
                            label: "Pumping pressure",
                            subitem: true,
                            value:
                                getValueForId(
                                    apiData.pumpPressures,
                                    getValue(activity, "irrigation.energyuse." + item + "_pump_pressure")
                                ) + " PSI"
                        }
                    });

                    rows.push({
                        type: "item",
                        value: {
                            label: "Pumping depth",
                            subitem: true,
                            value:
                                getValueForId(
                                    apiData.pumpDepths,
                                    getValue(activity, "irrigation.energyuse." + item + "_pump_depth")
                                ) + " ft"
                        }
                    });
                }
            }
        }
    });

    return rows;
}

function getHarvestingInput(cropYear, result, apiData) {
    var activity = getValue(cropYear, "activities", []).filter(activity => getValue(activity, "type") === "harvest")[0];

    var rows = [];

    rows.push({ type: "Header2", value: "Harvesting" });

    const enteredYield = getUnitsValue(activity, "harvest.landuse.yield");
    rows.push({
        type: "item",
        value: {
            label: "Yield",
            value: enteredYield
        }
    });

    if (getValue(cropYear, "is_irrigated") && cropYear.crop !== RICE) {
        rows.push({
            type: "item",
            value: {
                label: "Estimated Yield if Field was not Irrigated",
                subitem: true,
                value: getValue(activity, "harvest.wateruse.est_non_irrigated_yield")
            }
        });
    }

    if (cropYear.crop === RICE) {
        rows.push({
            type: "item",
            value: {
                label: "How is your yield calculated?",
                subitem: true,
                value: getValueForId(apiData.riceYieldMethods, getValue(activity, "harvest.landuse.yield_method"))
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Average Harvest Moisture",
                subitem: true,
                value: getValue(activity, "harvest.landuse.harvest_moisture")
            }
        });

        if (cropYear.crop === RICE) {
            rows.push({
                type: "item",
                subitem: true,
                value: {
                    label: "Did you harvest a ratoon crop? ",
                    subitem: true,
                    value: getValue(activity, "harvest.greenhouse.ratoon_cropping") ? "Yes" : "No"
                }
            });
        }
    } else if (cropYear.crop === SUGAR_BEETS) {
        rows.push({
            type: "item",
            value: {
                label:
                    "What is the percent sugar contained with the root of the sugar beet (cultivar and growing condition dependent)?",
                subitem: true,
                value: getPercentValue(activity, "harvest.landuse.percent_sugar")
            }
        });
    }

    const adjustedYield =
        getValue(result, "landUse.yieldAdjusted.value") + " " + getValue(result, "landUse.yieldAdjusted.unit");

    if (parseFloat(enteredYield) !== parseFloat(adjustedYield)) {
        rows.push({
            type: "item",
            value: {
                label: "Adjusted Yield",
                subitem: true,
                value: adjustedYield
            }
        });
    }

    rows.push({
        type: "item",
        value: {
            label: "Did you abandon or not harvest any acres you planted?",
            subitem: true,
            value: getValue(activity, "harvest.landuse.abandonment") ? "Yes" : "No"
        }
    });

    if (getValue(activity, "harvest.landuse.abandonment")) {
        rows.push({
            type: "item",
            value: {
                label: "Harvested Acres",
                value: getValue(activity, "harvest.landuse.harvested_area"),
                subitem: true
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Difference Reason",
                value: getValueForId(
                    apiData.abandonmentReasons,
                    getValue(activity, "harvest.landuse.abandonment_reason")
                ),
                subitem: true
            }
        });
    }

    const distance = getValue(activity, "harvest.energyuse.transportation_distance");

    rows.push({
        type: "item",
        value: {
            label: "Distance from field to point of sale",
            value: distance
        }
    });

    if (parseFloat(distance) > 0) {
        rows.push({
            type: "item",
            value: {
                label: "Did you haul back a load?",
                subitem: true,
                value: getValue(activity, "harvest.energyuse.backhauling") ? "Yes" : "No"
            }
        });
        rows.push({
            type: "item",
            value: {
                label: "Fuel Type",
                value: getValueForId(apiData.fuelTypes, getValue(activity, "harvest.energyuse.transportation_fuel_id")),
                subitem: true
            }
        });
    }

    if (cropYear.crop === PEANUTS) {
        rows.push({
            type: "item",
            value: {
                label: "What is the initial moisture content when delivered to the buying point?",
                value: getPercentValue(activity, "harvest.energyuse.peanuts_initial_moisture")
            }
        });
    } else if (cropYear.crop === COTTON) {
        rows.push({
            type: "item",
            subitem: true,
            value: {
                label: "Moisture at Ginning",
                value: getValueForId(
                    apiData.cottonMoisture,
                    getValue(activity, "harvest.energyuse.cotton_target_moisture")
                )
            }
        });
    } else if (!OTHER_DRYING.includes(cropYear.crop)) {
        const dryingSystem = getValue(activity, "harvest.energyuse.drying_system"),
            noDry = NO_DRYING.includes(dryingSystem);
        rows.push({
            type: "item",
            value: {
                label: "Drying System",
                value: getValueForId(apiData.dryingSystems, dryingSystem)
            }
        });

        if (!noDry) {
            const energysource = getValue(activity, "harvest.energyuse.drying_energy_source");
            rows.push({
                type: "item",
                value: {
                    label: "Energy source",
                    value: getValueForId(apiData.energySources, energysource),
                    subitem: true
                }
            });
            if (energysource === FUEL) {
                rows.push({
                    type: "item",
                    value: {
                        label: "Fuel Type",
                        value: getValueForId(apiData.fuelTypes, getValue(activity, "harvest.energyuse.drying_fuel_id")),
                        subitem: true
                    }
                });
            }
            if (getValue(activity, "harvest.energyuse.drying_use_actual")) {
                if (energysource === FUEL) {
                    rows.push({
                        type: "item",
                        value: {
                            label: "Fuel Amount",
                            value: getValue(activity, "harvest.energyuse.drying_fuel_amount"),
                            subitem: true
                        }
                    });
                } else {
                    rows.push({
                        type: "item",
                        value: {
                            label: "Electric Amount",
                            value: getValue(activity, "harvest.energyuse.drying_electricity_amount"),
                            subitem: true
                        }
                    });
                }
            } else {
                rows.push({
                    type: "item",
                    value: {
                        label: "Points of Moisture Removed from Drying",
                        value: getValue(activity, "harvest.energyuse.moisture_removed"),
                        subitem: true
                    }
                });
            }
        }
    }

    return rows;
}

function getAlfalfaHarvesting(cropYear, result, apiData) {
    var activities = getValue(cropYear, "activities", []).filter(activity => getValue(activity, "type") === "harvest");
    var rows = [];

    rows.push({ type: "Header2", value: "Harvesting" });
    rows.push({
        type: "item",
        value: {
            label: "Total Adjusted Yield",
            value:
                getValue(result, "landUse.yieldAdjusted.value") + " " + getValue(result, "landUse.yieldAdjusted.unit")
        }
    });

    activities.forEach((activity, i) => {
        rows.push({ type: "Header3", value: "Cutting " + (i + 1) });

        rows.push({
            type: "item",
            value: {
                label: "Yield",
                value: getUnitsValue(activity, "harvest.landuse.yield")
            }
        });

        if (getValue(cropYear, "is_irrigated")) {
            rows.push({
                type: "item",
                subitem: true,
                value: {
                    label: "Estimated Yield if Field was not Irrigated",
                    value: getUnitsValue(activity, "harvest.wateruse.est_non_irrigated_yield")
                }
            });
        }

        rows.push({
            type: "item",
            value: {
                label: "Average Harvest Moisture",
                subitem: true,
                value: getPercentValue(activity, "harvest.landuse.harvest_moisture")
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Harvest Type",
                subitem: true,
                value: getValueForId(apiData.alfalfaHarvestTypes, getValue(activity, "harvest.energyuse.harvest_type"))
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Did you abandon or not harvest any acres you planted?",
                subitem: true,
                value: getValue(activity, "harvest.landuse.abandonment") ? "Yes" : "No"
            }
        });

        if (getValue(activity, "harvest.landuse.abandonment")) {
            rows.push({
                type: "item",
                value: {
                    label: "Harvested Acres",
                    value: getValue(activity, "harvest.landuse.harvested_area"),
                    subitem: true
                }
            });

            rows.push({
                type: "item",
                value: {
                    label: "Difference Reason",
                    value: getValueForId(
                        apiData.abandonmentReasons,
                        getValue(activity, "harvest.landuse.abandonment_reason")
                    ),
                    subitem: true
                }
            });
        }

        const distance = getValue(activity, "harvest.energyuse.transportation_distance");

        rows.push({
            type: "item",
            value: {
                label: "Distance from field to point of sale",
                value: distance
            }
        });

        if (parseFloat(distance) > 0) {
            rows.push({
                type: "item",
                value: {
                    label: "Did you haul back a load?",
                    subitem: true,
                    value: getValue(activity, "harvest.energyuse.backhauling") ? "Yes" : "No"
                }
            });
            rows.push({
                type: "item",
                value: {
                    label: "Fuel Type",
                    value: getValueForId(
                        apiData.fuelTypes,
                        getValue(activity, "harvest.energyuse.transportation_fuel_id")
                    ),
                    subitem: true
                }
            });
        }

        rows.push({
            type: "item",
            value: {
                label: "Drying System",
                value: getValueForId(
                    apiData.alfalfaDryingSystems,
                    getValue(activity, "harvest.energyuse.alfalfa_drying_system")
                )
            }
        });

        if (!NO_DRYING_ALFALFA.includes(getValue(activity, "harvest.energyuse.alfalfa_drying_system"))) {
            rows.push({
                type: "item",
                value: {
                    label: "Points of Moisture removed from Drying",
                    value: getValue(activity, "harvest.energyuse.moisture_removed"),
                    subitem: true
                }
            });
        }
    });

    return rows;
}

function getFertilizerInput(cropYear, apiData) {
    var activities = getValue(cropYear, "activities", []).filter(
        activity => getValue(activity, "type") === "application"
    );

    var rows = [];

    rows.push({ type: "Header2", value: "Commercial Fertilizer and Protectant Trips" });

    activities.forEach((activity, i) => {
        var fertilizerActivities = getValue(activity, "fertilizers", []);

        rows.push({
            type: "Header3",
            value: "Trip " + (i + 1)
        });

        rows.push({
            type: "item",
            value: {
                label: "Application Timing",
                value: getValueForId(
                    apiData.applicationTimes,
                    getValue(activity, "application.waterquality.application_timing")
                )
            }
        });

        rows.push({
            type: "item",
            value: {
                label: "Application Method",
                value: getValueForId(
                    apiData.dominantApplicationMethods,
                    getValue(activity, "application.waterquality.application_method_id")
                )
            }
        });

        fertilizerActivities.forEach((fertilizerActivity, i) => {
            rows.push({
                type: "Header4",
                value: "Fertilizer " + (i + 1)
            });

            if (fertilizerActivity.energyuse.customblend && fertilizerActivity.energyuse.customblend.nitrogen) {
                rows.push({
                    type: "item",
                    value: {
                        label: "Fertilizer",
                        value: getValueForId(
                            apiData.fertilizers,
                            getValue(fertilizerActivity, "energyuse.fertilizer_id")
                        )
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "Amount",
                        value: getValue(fertilizerActivity, "energyuse.fertilizer_rate")
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "N:P:K",
                        value: [
                            getPercentValue(fertilizerActivity, "energyuse.customblend.nitrogen"),
                            getPercentValue(fertilizerActivity, "energyuse.customblend.phosphorus"),
                            getPercentValue(fertilizerActivity, "energyuse.customblend.potassium")
                        ].join(" : ")
                    }
                });
            } else if (fertilizerActivity.energyuse.customrate && fertilizerActivity.energyuse.customrate.nitrogen) {
                rows.push({
                    type: "item",
                    value: {
                        label: "Fertilizer",
                        value: getValueForId(
                            apiData.fertilizers,
                            getValue(fertilizerActivity, "energyuse.fertilizer_id")
                        )
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "Nitrogen Amount",
                        value: fertilizerActivity.energyuse.customrate.nitrogen
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "Phosphorus Amount",
                        value: fertilizerActivity.energyuse.customrate.phosphorus
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "Potassium Amount",
                        value: fertilizerActivity.energyuse.customrate.potassium
                    }
                });
            } else {
                rows.push({
                    type: "item",
                    value: {
                        label: "Fertilizer",
                        value: getValueForId(
                            apiData.fertilizers,
                            getValue(fertilizerActivity, "energyuse.fertilizer_id")
                        )
                    }
                });

                rows.push({
                    type: "item",
                    value: {
                        label: "Amount",
                        value: getValue(fertilizerActivity, "energyuse.fertilizer_rate")
                    }
                });
            }
        });

        rows.push({
            type: "Header4",
            value: "Protectants"
        });

        rows.push({
            type: "item",
            value: { label: "Herbicides", value: getValue(activity, "application.energyuse.herbicide_count") }
        });

        rows.push({
            type: "item",
            value: { label: "Insecticides", value: getValue(activity, "application.energyuse.insecticide_count") }
        });
        rows.push({
            type: "item",
            value: { label: "Fungicides", value: getValue(activity, "application.energyuse.fungicide_count") }
        });
        rows.push({
            type: "item",
            value: {
                label: "Growth Regulators",
                value: getValue(activity, "application.energyuse.growthregulator_count")
            }
        });
        rows.push({
            type: "item",
            value: { label: "Fumigants", value: getValue(activity, "application.energyuse.fumigant_count") }
        });

        if (cropYear.crop_name === "Rice") {
            rows.push({
                type: "item",
                value: { label: "Harvest Aids", value: getValue(activity, "application.energyuse.harvestaid_count") }
            });
        }
    });

    if (rows.length === 1) {
        rows.push({
            type: "item",
            value: { label: "No Fertilizer or Protectant Trips", value: "" }
        });
    }

    return rows;
}

function getManureInput(cropYear, apiData) {
    var activities = getValue(cropYear, "activities", []).filter(activity => getValue(activity, "type") === "manure");

    var rows = [];

    rows.push({ type: "Header2", value: "Manure Fertilizer Trips" });

    activities.forEach((activity, i) => {
        rows.push({ type: "Header3", value: "Trip " + (i + 1) });

        const isRice = cropYear.crop_name === "Rice",
            fertType = getValue(activity, "manure.greenhouse.organic_fert_type"),
            isManure = !isRice || fertType === FARM_YARD_MANURE;

        if (isRice) {
            rows.push({
                type: "item",
                value: {
                    label: "Organic Manure Type",
                    value: getValueForId(apiData.organicFertTypes, fertType)
                }
            });
        }

        if (isManure) {
            rows.push({
                type: "item",
                value: {
                    label: "Manure Type",
                    value: getValueForId(apiData.manureTypes, getValue(activity, "manure.energyuse.manure_type"))
                }
            });
            rows.push({
                type: "item",
                value: {
                    label: "Application Timing",
                    value: getValueForId(
                        apiData.applicationTimes,
                        getValue(activity, "manure.waterquality.application_timing")
                    )
                }
            });
            rows.push({
                type: "item",
                value: {
                    label: "Application Method",
                    value: getValueForId(
                        apiData.dominantApplicationMethods,
                        getValue(activity, "manure.waterquality.application_method_id")
                    )
                }
            });
        }

        rows.push({
            type: "item",
            value: {
                label: "Amount",
                value:
                    getValueForId(apiData.manureAmounts, getValue(activity, "manure.energyuse.manure_rate")) +
                    " " +
                    getManureUnits(getValue(activity, "manure.energyuse.manure_type"))
            }
        });

        if (isManure) {
            rows.push({
                type: "item",
                value: {
                    label: "Nitrogen Amount",
                    value: getValue(activity, "manure.greenhouse.manure_nitrogen_rate")
                }
            });
            rows.push({
                type: "item",
                value: {
                    label: "Phosphorus Amount",
                    value: getValue(activity, "manure.waterquality.manure_phosphorus_rate")
                }
            });
        }
    });

    if (rows.length === 1) {
        rows.push({
            type: "item",
            value: { label: "No Manure Fertilizer Trips", value: "" }
        });
    }

    return rows;
}

function getServices(cropYear) {
    const csip = getValue(cropYear, "metrics.Calculator.result.metadata.version.csip"),
        rows = [];
    if (!csip) {
        return rows;
    }
    rows.push({
        type: "Header1",
        value: "USDA Model Services"
    });
    rows.push({
        type: "item",
        value: { label: "USDA Water Erosion Prediction Project (WEPP)", value: `wepp/${csip.wepp}` }
    });
    rows.push({
        type: "item",
        value: { label: "USDA Wind Erosion Prediction System (WEPS)", value: `weps/${csip.weps}` }
    });
    rows.push({
        type: "item",
        value: {
            label: "Soil Survey Geographic Database (SSURGO)",
            value: `fppsoilparams/${csip.soils}`
        }
    });
    return rows;
}

export function getPageRows(cropYear, sectionApiData = {}) {
    const soilsHeader = [{ type: "Header1", value: "Soils Information" }];
    const rotationHeader = [{ type: "Header1", value: "Rotational and Residue Practices" }];
    const managementHeader = [{ type: "Header1", value: "Management Practices" }];
    const operationsHeader = [{ type: "Header1", value: "Operations" }];

    const soilsInput = getInputData("inputSoils", cropYear, sectionApiData["inputSoils"]);
    const rotationInput = getInputData("inputRotation", cropYear, sectionApiData["inputRotation"]);
    const managementInput = getInputData("inputManagement", cropYear, sectionApiData["inputManagement"]);
    const plantingInput = getInputData("inputPlanting", cropYear, sectionApiData["inputPlanting"]);
    const irrigationInput = getValue(cropYear, "is_irrigated")
        ? getInputData("inputIrrigation", cropYear, sectionApiData["inputIrrigation"])
        : [];
    const harvestingInput =
        cropYear.crop === ALFALFA
            ? getInputData("inputAlfalfaHarvesting", cropYear, sectionApiData["inputAlfalfaHarvesting"])
            : getInputData("inputHarvesting", cropYear, sectionApiData["inputHarvesting"]);
    const fertilizerInput = getInputData("inputFertilizers", cropYear, sectionApiData["inputFertilizers"]);
    const manureInput = getInputData("inputManure", cropYear, sectionApiData["inputManure"]);
    const services = getServices(cropYear);

    const rows = [
        /*
        {
            type: "MainHeader",
            value: "User Inputs"
        },
        */

        ...soilsHeader,
        ...soilsInput,
        ...rotationHeader,
        ...rotationInput,
        ...managementHeader,
        ...managementInput,
        ...operationsHeader,
        ...plantingInput,
        ...irrigationInput,
        ...harvestingInput,
        ...fertilizerInput,
        ...manureInput,
        ...services
    ];

    var pages = [];

    var currentPage = [];
    var maxRowStringLength = 64;
    var currentPageLength = 0;

    rows.forEach(row => {
        let threshold;
        if (row.type === "item") {
            var label_row_count = Math.ceil(row.value.label.length / maxRowStringLength);
            var value_row_count = Math.ceil(("" + row.value.value).length / maxRowStringLength);
            if (("" + row.value.value).match(/\n/)) {
                value_row_count = Math.max(value_row_count, row.value.value.split(/\n/).length);
            }
            row.rows = Math.max(label_row_count, value_row_count);
            threshold = row.rows;
        } else if (row.rows) {
            // Rotation/Map
            threshold = row.rows;
        } else {
            // Section Header
            row.rows = 1.75; // (27 + 8) / 20
            threshold = 5;
        }

        if (Math.ceil(currentPageLength + threshold) >= MAX_PAGE_LENGTH) {
            pages.push(currentPage);
            currentPage = [];
            currentPageLength = 0;
        }

        currentPageLength += row.rows;
        currentPage.push(row);

        if (Math.ceil(currentPageLength) >= MAX_PAGE_LENGTH) {
            pages.push(currentPage);
            currentPage = [];
            currentPageLength = 0;
        }
    });

    if (currentPage.length) {
        pages.push(currentPage);
    }

    const lastPageLength = pages[pages.length - 1].map(row => row.rows).reduce((a, b) => a + b);
    if (lastPageLength > MAX_LAST_PAGE_LENGTH) {
        // Move disclaimer to separate last page
        pages.push([]);
    }

    return pages;
}

class InputDetail extends Component {
    render() {
        const sectionApiData = {
            inputSoils: {
                soils: this.props.soilTextureApiData
            },
            inputRotation: {
                crops: this.props.crops,
                previousCrops: this.props.previousCrops,
                rotationSystems: this.props.rotationSystems,
                selectedRotation: this.props.selectedRotation,
                tillageClasses: this.props.tillageClasses,
                predominantRotationPractices: this.props.predominantRotationPractices,
                residueCovers: this.props.residueCovers,
                coverCropSpecies: this.props.coverCropSpecies,
                coverCropEstablishTimings: this.props.coverCropEstablishTimings
            },
            inputManagement: {
                tileDrainage: this.props.tileDrainage,
                managementTechniques: this.props.managementTechniques,
                pMultiYearApp: this.props.pMultiYearApp,
                pSoilTestLevel: this.props.pSoilTestLevel,
                conservationPractices: this.props.conservationPractices,
                integratedPestManagement: this.props.integratedPestManagement,
                wildlifeHabitat: this.props.wildlifeHabitat,
                landConversionTypes: this.props.landConversionTypes,
                nutrient4RS: this.props.nutrient4RS,
                riceWaterRegime: this.props.riceWaterRegime
            },
            inputPlanting: {
                seedingRates: this.props.seedingRates,
                riceCultivars: this.props.riceCultivars,
                ricePlantingMethods: this.props.ricePlantingMethods
            },
            inputIrrigation: {
                energySources: this.props.energySources,
                fuelTypes: this.props.fuelTypes,
                irrigationMethods: this.props.irrigationMethods,
                pumpPressures: this.props.pumpPressures,
                pumpDepths: this.props.pumpingDepths,
                waterSources: this.props.waterSources
            },
            inputHarvesting: {
                fuelTypes: this.props.fuelTypes,
                abandonmentReasons: this.props.abandonmentReasons,
                energySources: this.props.energySources,
                cottonMoisture: this.props.cottonMoisture,
                riceYieldMethods: this.props.riceYieldMethods,
                dryingSystems: this.props.dryingSystems
            },
            inputAlfalfaHarvesting: {
                alfalfaHarvestTypes: this.props.alfalfaHarvestTypes,
                alfalfaDryingSystems: this.props.alfalfaDryingSystems,
                abandonmentReasons: this.props.abandonmentReasons,
                fuelTypes: this.props.fuelTypes
            },
            inputFertilizers: {
                fertilizers: this.props.fertilizerApiData,
                applicationTimes: this.props.applicationTimesApiData,
                dominantApplicationMethods: this.props.dominantApplicationMethods
            },
            inputManure: {
                manureTypes: this.props.manureTypeApiData,
                manureAmounts: this.props.manureAmountApiData,
                organicFertTypes: this.props.organicFertTypeApiData,
                applicationTimes: this.props.applicationTimesApiData,
                dominantApplicationMethods: this.props.dominantApplicationMethods
            }
        };

        const { cropYear, classes } = this.props;

        const pages = getPageRows(cropYear, sectionApiData);

        return (
            <>
                {pages.map((page, i) => {
                    return (
                        <>
                            <div className={classes.page}>
                                <PageHeader cropYear={cropYear} />
                                <div className={classNames(classes.inputContent, i === 0 && classes.inputContentPage1)}>
                                    <Grid container>
                                        {page.map(row => (
                                            <AutoRow {...row} />
                                        ))}
                                    </Grid>
                                </div>

                                <PageFooter page={i + 1 + 3} pages={pages.length + 3} />
                            </div>
                        </>
                    );
                })}
            </>
        );
    }
}

InputDetail = connect((state, ownProps) => ({
    crops: allCrops(state),
    previousCrops: allPreviousCrops(state),
    soilTextureApiData: allSoilTextures(state),
    rotationSystems: allRotationSystems(state),
    selectedRotation: getSelectedRotation(state, ownProps),
    tillageClasses: allTillageClasses(state),
    predominantRotationPractices: allPredominantRotationPractices(state),
    residueCovers: allResidueCovers(state),
    coverCropSpecies: allCoverCropSpecies(state),
    coverCropEstablishTimings: allCoverCropEstablishTimings(state),
    tileDrainage: allTileDrainage(state),
    dominantApplicationMethods: allDominantApplicationMethods(state),
    managementTechniques: allManagementTechniques(state),
    pMultiYearApp: allPMultiYearApp(state),
    pSoilTestLevel: allPSoilTestLevel(state),
    conservationPractices: allConservationPractices(state),
    irrigationFieldActivity: getIrrigationFieldActivities(state),
    integratedPestManagement: activeIntegratedPestManagement(state),
    wildlifeHabitat: allWildlifeHabitats(state),
    landConversionTypes: allLandConversionTypes(state),
    nutrient4RS: allNutrient4RS(state),
    riceWaterRegime: allRiceWaterRegime(state),
    seedingRates: allSeedingRates(state),
    riceCultivars: allRiceCultivars(state),
    ricePlantingMethods: allRicePlantingMethods(state),
    irrigationMethods: allIrrigationMethods(state),
    pumpPressures: allPumpPressures(state),
    pumpingDepths: allPumpingDepths(state),
    waterSources: allWaterSources(state),
    dryingSystems: allDryingSystems(state),
    fuelTypes: allFuelTypes(state),
    abandonmentReasons: allAbandonmentReasons(state),
    energySources: allEnergySources(state),
    cottonMoisture: allCottonMoisture(state),
    riceYieldMethods: allRiceYieldMethods(state),
    alfalfaHarvestTypes: allAlfalfaHarvestTypes(state),
    alfalfaDryingSystems: allAlfalfaDryingSystems(state),
    fertilizerApiData: allFertilizers(state),
    applicationTimesApiData: allApplicationTimes(state),
    manureTypeApiData: allManureTypes(state),
    manureAmountApiData: allManureAmounts(state),
    organicFertTypeApiData: allOrganicFertTypes(state)
}))(InputDetail);

export default withStyles(styles)(InputDetail);
