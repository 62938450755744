import orm, { CustomModel, createSelector } from "../common/orm";
import React from "react";

export class Message extends CustomModel {
    static get fields() {
        return {};
    }
    static get source() {
        return "/db/messages";
    }
    static get modelName() {
        return "Message";
    }
    static selectById(id) {
        return createSelector(session => {
            const message = session.Message.withId(id);
            if (message) {
                if (message.content) {
                    return <div dangerouslySetInnerHTML={{ __html: message.content }} />;
                } else {
                    return true;
                }
            } else {
                return null;
            }
        });
    }
}
orm.register(Message);
