import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Grid from "../common/GridWrapper";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewQuilt from "@mui/icons-material/ViewQuilt";

import { Farm, FarmCropYear, FarmSurvey, FarmHpiSurvey } from "./models";
import { Message } from "../home/models";
import AddManagedAcres from "./AddManagedAcres";
import AddHpiSurvey from "./hpi/dialogs/AddHpiSurveyDialog";
import AddSaiSurvey from "./sai/AddSaiSurvey";
import FarmHpiTable from "./hpi/FarmHpiTable";
import SaiTable from "./sai/SaiTable";
import logo from "./sai/sai_logo_transparent.png";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TextField from "../common/TextField";
import FieldIcon from "../common/icons/FieldIcon";
import TablePaginationActionsWrapped from "../common/Paginator";
import WarningDialog from "../common/WarningDialog";
import * as navActions from "../common/actions";
import { createSelector, reloadAll } from "../common/orm";

import biodiversity from "./analysis/img/biodiversity_transparent.png";

const getFarm = Farm.selectByUrlId(farm => ({
    fields: farm.fields
        .all()
        .toModelArray()
        .map(field => ({
            cropyears: field.cropyears
                .all()
                .orderBy("order")
                .toRefArray(),
            ...field.ref
        })),
    ...farm.ref
}));
const getFarmSurvey = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, farm_id) => {
        return session.FarmSurvey.filter({ farm: farm_id })
            .orderBy("year", "desc")
            .toModelArray();
    }
);

const getFarmHpiActive = Message.selectById("farmhpi");

const getFarmHpiSurveys = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, farm_id) => {
        var surveys = session.FarmHpiSurvey.filter({ farm: farm_id })
            .orderBy("year")
            .toModelArray()
            .map(survey => ({
                ...survey._fields,
                cultivated: survey.cultivatedlandquestionnaire.toModelArray().map(f => ({ ...f._fields })),
                buffer: survey.riparianquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                forest: survey.forestquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                grassland: survey.grasslandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                surfacewater: survey.surfacewaterquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
                wetland: survey.wetlandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0]
            }));

        return surveys;
    }
);

const getFarmCropYear = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, farm_id) => {
        return session.FarmCropYear.filter({ farm: farm_id })
            .orderBy("year", "desc")
            .toModelArray()
            .map(farmCropYear => {
                // Calculate the entered acres
                var enteredirrigated = 0;
                var enterednonirrigated = 0;
                farmCropYear.farm.fields.toModelArray().forEach(function(f) {
                    f.cropyears.toModelArray().forEach(function(cy) {
                        if (cy.year === farmCropYear.year && cy.crop.id === farmCropYear.crop.id) {
                            if (cy.is_irrigated) enteredirrigated = enteredirrigated + parseFloat(f.size.split(" ")[0]);
                            else enterednonirrigated = enterednonirrigated + parseFloat(f.size.split(" ")[0]);
                        }
                    });
                });

                return {
                    crop_name: farmCropYear.crop.name,
                    total: (parseFloat(farmCropYear.nonirrigated) + parseFloat(farmCropYear.irrigated)).toFixed(1),
                    enteredtotal: (parseFloat(enteredirrigated) + parseFloat(enterednonirrigated)).toFixed(1),
                    enteredirrigated: enteredirrigated,
                    enterednonirrigated: enterednonirrigated,
                    ...farmCropYear.ref
                };
            });
    }
);

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 500
    },
    button: {
        marginBottom: 6
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    centerAlign: {
        textAlign: "center"
    },
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    buttonFloat: {
        float: "right"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    summaryRoot: {
        minHeight: theme.spacing(8),
        border: "1px solid lightgrey",
        backgroundColor: "#F7F6F6"
    },
    expansionDetails: {
        display: "block"
    },
    icon: {
        height: 40,
        width: 40,
        marginRight: 8,
        marginTop: -4
    },
    linkColor: {
        color: "#808080"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListItem: {
        borderBottom: "1px solid #666666",
        backgroundColor: "#eaeaea",
        textAlign: "center"
    },
    topAlertText: {
        color: "#666666",
        fontSize: "21px",
        fontWeight: "200"
    },
    listItem: {
        borderBottom: "1px solid #666666"
    },
    alertText: {
        color: "#666666"
    },
    iconSizeSmaller: {
        flexShrink: 0,
        width: ".8em",
        height: ".8em",
        fontSize: "24px",
        marginLeft: 4
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", allowSort: false },
    {
        id: "total",
        numeric: true,
        label: (
            <div>
                Total
                <br />
                Managed
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    { id: "year", numeric: true, label: "Year", allowSort: true },
    { id: "crop_name", numeric: false, label: "Crop", allowSort: true },
    {
        id: "enterednonirrigated",
        numeric: true,
        label: (
            <div>
                Non-Irrigated
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    {
        id: "enteredirrigated",
        numeric: true,
        label: (
            <div>
                Irrigated
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    {
        id: "nonirrigated",
        numeric: true,
        label: (
            <div>
                Managed
                <br />
                Non-Irrigated
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    {
        id: "irrigated",
        numeric: true,
        label: (
            <div>
                Managed
                <br />
                Irrigated
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    {
        id: "enteredtotal",
        numeric: true,
        label: (
            <div>
                Total
                <br />
                Entered
                <br />
                Acres
            </div>
        ),
        allowSort: true
    },
    {
        id: "difference",
        numeric: true,
        label: (
            <div>
                % of Total
                <br />
                Entered Acres to
                <br />
                Total Managed Acres
            </div>
        ),
        allowSort: false
    }
];

class FarmCropYearLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
            newDialogOpen: false,
            farmCropYear: null
        };
        this.returnDialog = this.returnDialog.bind(this);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const isNumeric = columnData.filter(function(item) {
            if (item.id === property) return item;
            else return null;
        });
        if (isNumeric[0].numeric) {
            order === "desc"
                ? this.props.farmCropYears.sort((a, b) => (parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1))
                : this.props.farmCropYears.sort((a, b) => (parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.farmCropYears.sort((a, b) =>
                      b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1
                  )
                : this.props.farmCropYears.sort((a, b) =>
                      a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1
                  );
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    returnDialog() {
        this.setState({ newDialogOpen: false });
    }

    render() {
        const { farmCropYears, classes, farm_name, ormFarmCropYearUpdate } = this.props;

        const { order, orderBy, rowsPerPage, page, farmCropYear, newDialogOpen } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, farmCropYears.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData}
                    />
                    <TableBody>
                        {farmCropYears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(mA => {
                            /*const matchacres =
                                (parseFloat(mA.nonirrigated) + parseFloat(mA.irrigated)).toFixed(1) ===
                                (parseFloat(mA.enterednonirrigated) + parseFloat(mA.nonirrigated)).toFixed(1);*/

                            var managedTotal = parseFloat(mA.nonirrigated) + parseFloat(mA.irrigated);
                            const percent = Math.abs((parseFloat(mA.enteredtotal) / managedTotal) * 100).toFixed(0);
                            return (
                                <TableRow key={mA.id} hover>
                                    <CustomTableCell>
                                        <Tooltip title="Edit Managed Acres">
                                            <Button
                                                onClick={() => this.setState({ farmCropYear: mA, newDialogOpen: true })}
                                                className={classes.buttonWidth}>
                                                <EditIcon color="primary" />
                                                {/*
                                                        {matchacres && <EditIcon style={{ color: "green" }} />}
                                                        {!matchacres && <EditIcon color="primary" />}
                                                    */}
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {(parseFloat(mA.nonirrigated) + parseFloat(mA.irrigated)).toFixed(1)}
                                    </CustomTableCell>
                                    <CustomTableCell>{mA.year}</CustomTableCell>
                                    <CustomTableCell>{mA.crop_name}</CustomTableCell>
                                    <CustomTableCell>{mA.enterednonirrigated.toFixed(1)}</CustomTableCell>
                                    <CustomTableCell>{mA.enteredirrigated.toFixed(1)}</CustomTableCell>
                                    <CustomTableCell>{mA.nonirrigated.split(" ")[0]}</CustomTableCell>
                                    <CustomTableCell>{mA.irrigated.split(" ")[0]}</CustomTableCell>
                                    <CustomTableCell>{mA.enteredtotal}</CustomTableCell>
                                    <CustomTableCell>{percent}%</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && farmCropYears.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={10} />
                            </TableRow>
                        )}
                        {farmCropYears.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={10} className={classes.centerAlign}>
                                    No Managed Acres Entered
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {farmCropYears.length > 10 && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={10}
                                    count={farmCropYears.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    classes={{
                                        caption: classes.body1
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                    <ManagedPopup
                        farmCropYear={farmCropYear}
                        classes={classes}
                        farm_name={farm_name}
                        ormFarmCropYearUpdate={ormFarmCropYearUpdate}
                        returnDialog={this.returnDialog}
                        open={newDialogOpen}
                    />
                </Table>
            </Paper>
        );
    }
}

class ManagedPopup extends Component {
    saveAcres = values => {
        const { farmCropYear, returnDialog } = this.props;

        this.props.ormFarmCropYearUpdate({
            id: farmCropYear.id,
            ...values
        });

        returnDialog();
    };

    errorValidator = values => {
        const validateNumber = num => {
            if (!num) {
                return "Required";
            }
            const number = parseFloat(num);
            if (number < 0 || number > 80000) {
                return "Must be between 0-80000";
            }
            return null;
        };
        return {
            irrigated: validateNumber(values.irrigated),
            nonirrigated: validateNumber(values.nonirrigated)
        };
    };

    render() {
        const { classes, farmCropYear, farm_name, returnDialog, open } = this.props;

        return (
            <Dialog open={open}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Managed Acres
                    </Typography>
                    <IconButton
                        onClick={() => {
                            returnDialog();
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            defaultValues={farmCropYear}
                            onSubmit={this.saveAcres}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom>
                                                Managed acres represent the total number of acres, for both irrigated
                                                and non-irrigated acres, you grow for the selected crop on this farm in
                                                the selected year. You can edit this information at any time on the Farm
                                                page.
                                            </Typography>
                                            <Typography gutterBottom>
                                                For example, you enter two corn fields for a total of 100 acres in the
                                                Platform for 2017, that represent a total of 850 acres of corn across
                                                your entire farm. If these fields are associated with a Project, the
                                                total managed acres (850 acres) may be considered enrolled if a minimum
                                                of 10% of the total managed acres are entered in Fieldprint Platform.
                                            </Typography>
                                            <TextField
                                                field="nonirrigated"
                                                units="acres"
                                                label={
                                                    <div>
                                                        Enter the total <b>non-irrigated</b> acres of{" "}
                                                        {farmCropYear.crop_name} grown in {farmCropYear.year} on{" "}
                                                        {farm_name}.
                                                    </div>
                                                }
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                            />
                                            <TextField
                                                field="irrigated"
                                                units="acres"
                                                label={
                                                    <div>
                                                        Enter the total <b>irrigated</b> acres of{" "}
                                                        {farmCropYear.crop_name} grown in {farmCropYear.year} on{" "}
                                                        {farm_name}.
                                                    </div>
                                                }
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                            />
                                            <Button
                                                type="submit"
                                                variant="raised"
                                                color="primary"
                                                className={classes.buttonFloat}>
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class FarmProperties extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            deleteDialogOpen: false,
            newDialogOpen: false,
            newHPIDialogOpen: false,
            newSAIDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            saiOpen: false,
            saiText: null,
            userMenuAnchor: null,
            renameActive: false
        };
        this.returnDialog = this.returnDialog.bind(this);
        this.returnHPIDialog = this.returnHPIDialog.bind(this);
        this.returnSAIDialog = this.returnSAIDialog.bind(this);
    }

    handleUserMenu = event => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };

    navigateField = farmId => {
        this.props.navSelectPage("field_new_" + farmId);
        this.props.history.push(`/farm/${farmId}/addfield`);
    };

    updateFarm = values => {
        let { farm } = this.props,
            farmId = farm.id;
        if (farm.isNewFarm) {
            delete values.isNewFarm;
            this.props.ormFarmCreateRemoteFirst(values).then(farmId => {
                this.props.ormFarmUpdateLocalOnly({
                    id: farmId,
                    open: true
                });
                this.props.navSelectPage("field_new_" + farmId);
                this.props.history.push(`/farm/${farmId}/addfield`);
            });
        } else {
            this.props.ormFarmUpdate({
                id: farmId,
                ...values
            });
            this.setState({ renameActive: false });
        }
    };

    returnDialog() {
        this.setState({ newDialogOpen: false });
    }

    returnHPIDialog() {
        this.setState({ newHPIDialogOpen: false });
    }

    returnSAIDialog() {
        this.setState({ newSAIDialogOpen: false });
    }

    deleteFarm = (farm, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: farm,
            deleteDialogText: (
                <div>
                    Are you sure you wish to permanently delete {name}?<br />
                    <br />
                    Please note that deleting your farm deletes all associated fields and crop years and all associated
                    data. This information cannot be recovered. If any crop years are associated with Projects, those
                    associations will also be deleted. In addition, any SAI Platform FSA Equivalency Module surveys
                    completed will be deleted. If you are connected with a Project you may want to confirm with the
                    Project Administrator prior to deleting your farm and fields. <br />
                    <br />
                    Are you sure you wish to permanently delete this farm and all associated data?
                </div>
            )
        });
    };

    doDeleteFarm = farm => {
        this.props.ormFarmDelete(farm);
        this.setState({ deleteDialogOpen: false });
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    errorValidator = values => {
        const isRequired = val => {
            if (!val) return "Required";
            if (val.length < 3) return "At a minimum you must use three characters to identify your farm";
            return null;
        };
        return {
            name: isRequired(values.name)
        };
    };

    checkSAIEligibility = () => {
        const { farm } = this.props;
        var is_complete = false;
        var is_project = false;
        var has_cropyears = false;
        var language = null;

        farm.fields.forEach(function(field) {
            field.cropyears.forEach(function(cropyear) {
                if (cropyear) has_cropyears = true;
                if (cropyear.project_instance) is_project = true;
                if (cropyear.is_final) is_complete = true;
            });
        });

        if (is_complete && is_project && has_cropyears) this.setState({ newSAIDialogOpen: true });
        else {
            if (!has_cropyears) language = "We did not find any crop years under this farm.";
            else if (!is_project && !is_complete)
                language = "We found one or more crop years but none were finalized or associated with a Project.";
            else if (!is_project)
                language = "We found one or more finalized crop years but none were associated with a Project.";
            else if (!is_complete)
                language = "We found one or more crop years associated with a Project but none were finalized.";
            this.setState({
                saiOpen: true,
                saiText: (
                    <div>
                        Given that the SAI Platform’s FSA Equivalency requires active platform usage, before you
                        complete the survey you must:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;a) have at least one field with one crop year finalized - to include the
                        most recent growing season, and
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;b) have at least one of the crop years associated with the Project for
                        which the survey is being requested.
                        <br />
                        <br />
                        <ul>
                            <li>{language}</li>
                        </ul>{" "}
                        <br />
                        You must finalize at least one of the one crop years before your survey results will appear in
                        Project Administrator reporting. Please contact the Project Administrator if you need
                        assistance.
                    </div>
                )
            });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {
            classes,
            farm,
            farmCropYear,
            ormFarmCropYearUpdate,
            ormFarmSurveyDelete,
            ormFarmHpiSurveyDelete,
            farmSurvey,
            farmHpiActive,
            farmHpiSurveys,
            farmShowMessage,
            navState
        } = this.props;
        const {
            deleteDialogOpen,
            newHPIDialogOpen,
            newSAIDialogOpen,
            deleteDialogText,
            deleteDialogConfirmAction,
            newDialogOpen,
            saiOpen,
            saiText,
            userMenuAnchor,
            renameActive
        } = this.state;

        const userMenuOpen = Boolean(userMenuAnchor);

        return (
            <AppContainer authenticated color="field" title={farm.isNewFarm ? "New Farm" : farm.name || farm.id}>
                <Menu
                    id="menu-farm"
                    anchorEl={userMenuAnchor}
                    classes={{
                        paper: classes.list
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={userMenuOpen}
                    onClose={this.handleUserMenuClose}>
                    <ListItem button className={classes.topListItem}>
                        <ListItemText
                            primary="Edit Farm"
                            classes={{
                                primary: classes.topAlertText
                            }}
                        />
                    </ListItem>
                    <ListItem
                        onClick={() => {
                            this.setState({ renameActive: true });
                            this.handleUserMenuClose();
                            var test = this;
                            setTimeout(function() {
                                test["farm_input"].focus();
                            }, 500);
                        }}
                        button
                        className={classes.listItem}>
                        <ListItemIcon className={classes.alertText}>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Rename Farm"
                            classes={{
                                primary: classes.alertText
                            }}
                        />
                    </ListItem>
                    <ListItem onClick={() => this.navigateField(farm.id)} button className={classes.listItem}>
                        <ListItemIcon className={classes.alertText}>
                            <FieldIcon className={classes.iconSizeSmaller} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Add Field"
                            classes={{
                                primary: classes.alertText
                            }}
                        />
                    </ListItem>
                    <ListItem
                        onClick={() => this.deleteFarm(farm.id, farm.name || farm.id)}
                        button
                        className={classes.listItem}>
                        <ListItemIcon className={classes.alertText}>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Delete Farm"
                            classes={{
                                primary: classes.alertText
                            }}
                        />
                    </ListItem>
                </Menu>
                <Grid container spacing={24}>
                    <Form
                        key={farm.id}
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={farm}
                        validateError={this.errorValidator}
                        onSubmit={this.updateFarm}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24} alignItems="flex-end">
                                        {!farm.isNewFarm && (
                                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                                <Typography variant="title" style={{ float: "left" }}>
                                                    FPP ID: {farm.fpp_id}
                                                </Typography>
                                                <Button onClick={this.handleUserMenu} variant="raised" color="primary">
                                                    Edit Farm
                                                </Button>
                                            </Grid>
                                        )}
                                        {(farm.isNewFarm || renameActive) && (
                                            <>
                                                <Grid item xs={6} lg={5} xl={4}>
                                                    <TextField
                                                        help={
                                                            <div>
                                                                A farm or farm operation is used as a way to group
                                                                fields in a way that is useful to the user.
                                                                <br />
                                                                <br />
                                                                If you have multiple fields you can use farms to
                                                                logically group them. Use a name that is recognizable to
                                                                you.
                                                            </div>
                                                        }
                                                        field="name"
                                                        label="Farm Name"
                                                        fullWidth
                                                        //autoFocus
                                                        inputRef={input => (this["farm_input"] = input)}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        type="submit"
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.button}>
                                                        Save {farm.isNewFarm && " and Add Field"}
                                                    </Button>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Button
                                                        type="button"
                                                        onClick={() => {
                                                            if (farm.isNewFarm) {
                                                                this.props.navSelectPage("dashboard");
                                                                this.props.history.push("/dashboard");
                                                            } else this.setState({ renameActive: false });
                                                        }}
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.button}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                    {!farm.isNewFarm && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="title">
                                    Use this page to manage farm-level features such as your farm name, managed acres,
                                    farmlevel biodiversity, and, if requested, complete SAI Platform's FSA
                                    questionnaires.
                                </Typography>
                            </Grid>
                            {!navState.hideFarmMessage && (
                                <Grid item xs={12}>
                                    <Typography style={{ backgroundColor: "#f15d22", padding: 12, color: "#fff" }}>
                                        Please Confirm Your Managed Acres
                                        <br />
                                        <br />
                                        <table>
                                            <tr>
                                                <td>
                                                    Please confirm that your managed acres data below is accurate. This
                                                    data comes from your entries at the crop year level but may need
                                                    adjustment if fields or crop years are moved or deleted. This
                                                    information is very important for Program and Project reporting.
                                                </td>
                                                <td>
                                                    <Tooltip title="Hide this message">
                                                        <IconButton
                                                            onClick={farmShowMessage}
                                                            style={{ marginTop: -40, color: "#fff" }}
                                                            size="large">
                                                            <Close />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </table>
                                    </Typography>
                                </Grid>
                            )}
                            <Grid xs={12} item>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        classes={{
                                            root: classes.summaryRoot
                                        }}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <ViewQuilt className={classes.icon} color="primary" />
                                        <Typography variant="title" style={{ marginTop: 8 }}>
                                            Managed Acres
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: classes.expansionDetails }}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <Typography variant="title">
                                                    Managed acres represent the total number of acres, for both
                                                    irrigated and non-irrigated acres, you grow for each crop in each
                                                    year on this farm. This information is typically entered when adding
                                                    a new crop year but you can review and modify the information across
                                                    all fields here.
                                                </Typography>
                                                <Button
                                                    onClick={() => this.setState({ newDialogOpen: true })}
                                                    fullWidth
                                                    className={classes.gutterTop}
                                                    variant="raised"
                                                    color="primary">
                                                    <AddCircleOutlineIcon />
                                                    &nbsp;&nbsp;&nbsp;Add New Managed Acres Record
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FarmCropYearLibrary
                                                    classes={classes}
                                                    ormFarmCropYearUpdate={ormFarmCropYearUpdate}
                                                    farm_name={farm.name || farm.id}
                                                    farmCropYears={farmCropYear}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {farmHpiActive && (
                                <Grid xs={12} item>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            classes={{
                                                root: classes.summaryRoot
                                            }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <img src={biodiversity} className={classes.icon} alt="Biodiversity" />
                                            <Typography variant="title" style={{ marginTop: 8 }}>
                                                Farm Level Habitat Potential Index Survey
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.expansionDetails }}>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12}>
                                                    {farmHpiActive}
                                                    <Typography variant="title">
                                                        The Habitat Potential Index (HPI) for Biodiversity is an
                                                        educational tool that promotes the coexistence of agricultural
                                                        productivity and biodiversity at the individual farm scale by
                                                        identifying opportunities for growers to optimize ecological
                                                        benefits of land management and effective stewardship based on
                                                        the land cover(s) present on their farm.
                                                    </Typography>
                                                    <Button
                                                        onClick={() => this.setState({ newHPIDialogOpen: true })}
                                                        fullWidth
                                                        className={classes.gutterTop}
                                                        variant="raised"
                                                        color="primary">
                                                        <AddCircleOutlineIcon />
                                                        &nbsp;&nbsp;&nbsp;Add New HPI Survey
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FarmHpiTable
                                                        ormFarmHpiSurveyDelete={ormFarmHpiSurveyDelete}
                                                        farmHpiSurveys={farmHpiSurveys}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}

                            <Grid xs={12} item>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        classes={{
                                            root: classes.summaryRoot
                                        }}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <img src={logo} className={classes.icon} alt="SAI" />
                                        <Typography variant="title" style={{ marginTop: 8 }}>
                                            SAI Platform's FSA Equivalency Module
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: classes.expansionDetails }}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <Typography variant="title">
                                                    Field to Market provides equivalency guidance for Sustainable
                                                    Agriculture Initiative (SAI) Platform.{" "}
                                                    {/*The guidelines can be
                                                    found&nbsp;
                                                    <a
                                                        className={classes.linkColor}
                                                        href="https://fieldtomarket.org/media/2017/03/FAQ-Guidance-for-Growers-Appendix-3-v.1.1.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        here
                                                    </a>
                                                    .*/}
                                                </Typography>
                                                <Button
                                                    onClick={this.checkSAIEligibility}
                                                    fullWidth
                                                    className={classes.gutterTop}
                                                    variant="raised"
                                                    color="primary">
                                                    <AddCircleOutlineIcon />
                                                    &nbsp;&nbsp;&nbsp;Add New Questionnaire
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SaiTable
                                                    ormFarmSurveyDelete={ormFarmSurveyDelete}
                                                    farmSurveys={farmSurvey}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </>
                    )}
                </Grid>
                <AddManagedAcres
                    returnDialog={this.returnDialog}
                    farm_id={farm.id}
                    farm_name={farm.name}
                    open={newDialogOpen}
                />
                {newHPIDialogOpen === true && (
                    <AddHpiSurvey returnDialog={this.returnHPIDialog} farm_id={farm.id} open={newHPIDialogOpen} />
                )}
                <AddSaiSurvey
                    farm_fields={farm.fields}
                    returnDialog={this.returnSAIDialog}
                    farm_id={farm.id}
                    open={newSAIDialogOpen}
                />
                <WarningDialog
                    confirmAction={() => this.doDeleteFarm(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Farm"
                    confirmText="Delete Farm"
                    text={deleteDialogText}
                />
                <WarningDialog
                    noActions
                    open={saiOpen}
                    onClose={() => this.setState({ saiOpen: false })}
                    title="Warning"
                    text={saiText}
                />
            </AppContainer>
        );
    }
}

FarmProperties = connect(
    (state, ownProps) => ({
        farm:
            ownProps.match && ownProps.match.params && ownProps.match.params.id
                ? getFarm(state, ownProps)
                : { isNewFarm: true },
        farmCropYear: getFarmCropYear(state, ownProps),
        farmSurvey: getFarmSurvey(state, ownProps),
        farmHpiActive: getFarmHpiActive(state, ownProps),
        farmHpiSurveys: getFarmHpiSurveys(state, ownProps),
        navState: state.nav
    }),
    {
        ...Farm.actions,
        ...navActions,
        ...FarmCropYear.actions,
        ...FarmSurvey.actions,
        ...FarmHpiSurvey.actions,
        reloadAll
    }
)(FarmProperties);

export default withStyles(styles)(withRouter(FarmProperties));
