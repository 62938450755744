import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "../common/GridWrapper";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

import * as utils from "./CropManagementUtils";
import RotationSystemProjectDialog from "./RotationSystemProjectDialog";
import TemplateLibraryTable from "./tables/TemplateLibraryTable";

const styles = theme => ({
    flex: {
        flex: 1
    },
    contentPadding: {
        padding: "24px"
    },
    toolbarBackground: {
        backgroundColor: "#ff8300"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    preview: {
        minWidth: 500,
        minHeight: 100
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    errorBox: {
        minWidth: 400
    },
    searchButton: {
        border: "1px solid #979797"
    },
    chip: {
        margin: theme.spacing(1 / 4),
        backgroundColor: "#FFFFFF",
        border: "1px solid #e0e0e0"
    },
    chipDelete: {
        margin: theme.spacing(1 / 4),
        backgroundColor: "#FFFFFF",
        border: "1px solid #e0e0e0",
        "&:hover": {
            backgroundColor: "#ff7d32",
            color: "#fff",
            "& polyline, & rect, & line, & path, & polygon": {
                stroke: "#fff"
            }
        }
    }
});

class ImportTemplateDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: "",
            name: "",
            rotationSystem: null,
            message: null,
            showProjectDialog: false,
            projects: [],
            selectedProjects: []
        };
    }

    resetState = () => {
        this.setState({
            selectedFile: "",
            name: "",
            rotationSystem: null,
            selectedProjects: [],
            message: null
        });
    };

    handleNameChange = e => {
        this.setState({ name: e.target.value });
    };

    handleSaveClick = () => {
        var rotationSystemObj = {
            name: this.state.name,
            template: "Import",
            template_name: "Import"
        };

        const projectIds = this.props.admin ? this.state.selectedProjects : null;

        const parameters = {
            ormRotationSystemCreate: this.props.ormRotationSystemCreate,
            ormRotationEventCreate: this.props.ormRotationEventCreate,
            ormRotationSystemUpdate: this.props.ormRotationSystemUpdate,
            rotationSystem: rotationSystemObj,
            events: this.state.rotationSystem.events,
            projectIds: projectIds,
            operations: this.state.operations,
            crops: this.state.crops
        };

        utils.createRotationSystemAndEvents(parameters);

        var message = "";

        if (projectIds) {
            message =
                "Rotation system has been imported to the selected projects under the name " + this.state.name + ".";
        } else {
            message = "Rotation system has been imported to your library with the name " + this.state.name + ".";
        }

        this.setState({
            message: message
        });
    };

    handleCancelClick = () => {
        this.resetState();
        this.props.onImportTemplateCancelClick();
    };

    loadRotationFile = event => {
        var that = this;
        var input = event.target;
        var reader = new FileReader();
        reader.onload = function() {
            try {
                var json = JSON.parse(reader.result);
                var events = json["managements"][0]["events"];
                json["managements"][0]["events"] = events;
                var name = json["managements"][0]["name"] ? json["managements"][0]["name"] : "";
                that.setState({
                    rotationSystem: json["managements"][0],
                    name: name,
                    message: null
                });
            } catch (err) {
                var message = "";
                if (err instanceof SyntaxError) {
                    message =
                        "ERROR: Could not parse the rotation file due to syntax error.  Please review the rotation file. " +
                        err.message;
                } else {
                    message = "Error: Could not parse the rotation file. " + err.message;
                }
                that.setState({
                    rotationSystem: null,
                    selectedFile: "",
                    name: "",
                    message: message
                });
            }
        };
        reader.readAsText(input.files[0]);
    };

    componentDidMount() {
        this.getBuilderData();
    }

    getBuilderData() {
        let that = this;
        Promise.all([utils.OperationsRequest, utils.CropsRequest]).then(function(values) {
            let operations = values[0]["result"][0]["value"]["operations"];
            let crops = values[1];
            that.setState({
                operations: operations,
                crops: crops
            });
        });
    }

    handleProjectSelect = projectId => {
        var selectedProjects = this.state.selectedProjects;
        selectedProjects.push(projectId);
        this.setState({ selectedProjects: selectedProjects });
    };

    handleProjectDeselect = project => {
        const selectedProjects = this.state.selectedProjects.filter(p => p.id !== project.id);
        this.setState({ selectedProjects: selectedProjects });
    };

    handleProjectDialogOk = project_ids => {
        const projects = this.state.projects.filter(p => project_ids.indexOf(p.id) > -1);
        this.setState({
            selectedProjects: projects,
            showProjectDialog: false
        });
    };

    handleProjectDialogCancel = () => {
        this.setState({
            showProjectDialog: false
        });
    };

    getNameError = () => {
        const { name } = this.state;
        if (name) {
            if (name.trim().length < 3) {
                return "Must be 3 - 250 characters.";
            }
        }
    };

    getSaveDisabled = (admin, rotationEvents, name, selectedProjects) => {
        if (admin) {
            if (rotationEvents.length > 0 && name !== "" && name.length >= 3 && selectedProjects.length > 0) {
                return false;
            }
        } else {
            if (rotationEvents.length > 0 && name !== "" && name.length >= 3) {
                return false;
            }
        }
        return true;
    };

    render() {
        const { classes, projectOptions, admin, cropyearId } = this.props;
        const { message, selectedFile, rotationSystem, name, showProjectDialog, selectedProjects } = this.state;

        const columnData = [
            { id: "operationName", numeric: false, disablePadding: false, label: "Operation Name", allowSort: false },
            { id: "year", numeric: false, disablePadding: false, label: "Year", allowSort: false },
            { id: "day", numeric: false, disablePadding: false, label: "Day", allowSort: false },
            { id: "crop", numeric: false, disablePadding: false, label: "Crop", allowSort: false },
            { id: "residue", numeric: false, disablePadding: false, label: "Residue", allowSort: false },
            { id: "residueAmount", numeric: false, disablePadding: false, label: "Residue Amount", allowSort: false }
        ];

        const rotationEvents =
            rotationSystem && this.state.operations
                ? utils.remapRotationEvents(this.state.rotationSystem.events, this.state.operations)
                : [];

        const saveDisabled = this.getSaveDisabled(admin, rotationEvents, name, selectedProjects);

        const nameError = this.getNameError();

        return (
            <>
                {showProjectDialog && (
                    <RotationSystemProjectDialog
                        open={showProjectDialog}
                        projects={projectOptions}
                        selectedProjects={this.state.selectedProjects}
                        handleProjectDialogCancel={this.handleProjectDialogCancel}
                        handleProjectDialogOk={this.handleProjectDialogOk}
                    />
                )}
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={this.state.rotationSystem}
                    maxWidth={this.state.rotationSystem ? "100%" : "sm"}>
                    <Toolbar>
                        <Typography variant="title" className={classes.flex}>
                            Import Rotation System Template
                        </Typography>
                        <Tooltip title="Close">
                            <IconButton onClick={() => this.handleCancelClick()} size="large">
                                <Close color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>

                    <DialogContent className={classes.contentPadding}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={rotationEvents.length > 0 ? 6 : 12}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <input
                                            accept=".rot"
                                            className={classes.input}
                                            id="raised-button-file"
                                            multiple
                                            type="file"
                                            hidden
                                            onChange={a => this.loadRotationFile(a)}
                                            value={selectedFile}
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button raised component="span" className={classes.searchButton} fullWidth>
                                                <SearchIcon className={classes.optionButtonIcon} />
                                                Search for rotation file
                                            </Button>
                                        </label>
                                    </Grid>
                                    {rotationEvents.length > 0 && (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                id="outlined-full-width"
                                                style={{ margin: 8 }}
                                                label="Rotation System Name"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                onChange={this.handleNameChange}
                                                value={this.state.name}
                                                helperText={nameError}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            {admin && !!rotationSystem && (
                                <Grid item xs={12} md={6}>
                                    <Typography gutterBottom variant="body1">
                                        Click all the projects below that you want to save the imported rotation system
                                        template. You must select at least one.
                                    </Typography>

                                    {projectOptions.map(p => {
                                        if (selectedProjects.indexOf(p.id) === -1) {
                                            return (
                                                <Chip
                                                    key={p.id}
                                                    label={p.name}
                                                    onClick={() => this.handleProjectSelect(p.id)}
                                                    className={classes.chip}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Chip
                                                    key={p.id}
                                                    label={p.name}
                                                    onDelete={() => this.handleProjectDeselect(p.id)}
                                                    className={classes.chipDelete}
                                                />
                                            );
                                        }
                                    })}
                                </Grid>
                            )}

                            {message && (
                                <Grid item xs={12}>
                                    <Typography color="primary">{message}</Typography>
                                </Grid>
                            )}

                            {rotationEvents.length > 0 && (
                                <Grid item xs={12}>
                                    <TemplateLibraryTable
                                        noPagination={false}
                                        data={rotationEvents}
                                        columnData={columnData}
                                        noRecordsMessage={"No rotation events."}
                                        onEdit={this.handleEditEventClick}
                                        onDelete={this.handleDeleteEventClick}
                                        toolTips={[{ column: "operationName", tip: "operationTip" }]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        {cropyearId && (
                            <Button
                                onReturnToCropyear={this.handleReturnToCropyear}
                                onClick={() => this.props.onReturnToCropyear()}
                                color="primary"
                                className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                                Return to Editing Crop Year
                            </Button>
                        )}

                        <Button
                            onClick={() => this.handleCancelClick()}
                            color="primary"
                            className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                            Cancel
                        </Button>

                        <Button
                            disabled={saveDisabled}
                            onClick={() => this.handleSaveClick()}
                            className={classes.buttonMargin}
                            variant="raised"
                            color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(ImportTemplateDialog);
