import { getValue, setValue } from "../../../api/utils";
import {
    ALFALFA,
    COTTON,
    PEANUTS,
    RICE,
    SUGAR_BEETS,
    CUSTOM_BLEND,
    CUSTOM_RATE,
    FARM_YARD_MANURE
} from "../../../api/constants";

const UNDEFINED = "UNDEFINED";

function getValueWithUnit(payload, path, defaultUnit, defaultZero) {
    const value = getValue(payload, path);
    if (value === null || (typeof value === "string" && value.trim() === "")) {
        return defaultUnit
            ? {
                  value: defaultZero ? 0 : null,
                  unit: defaultUnit
              }
            : null;
    }

    if (typeof value === "undefined") {
        return UNDEFINED;
    }

    const parts = value.split(" ");
    return {
        value: parts[0],
        unit: parts.slice(1).join(" ")
    };
}

export const convert = result => {
    console.log("--> makeQDMPPayload");

    const payload = result["Calculator"]["payload"];

    let crop_id = getValue(payload, "cropyear.crop");

    let qdmpPayload = {
        geometry_type: "geojson",
        rotation_type: "rotation_json",
        custom_fertilizer_type: "json",
        generate_report: false,
        field: "Enter Field Name or ID",
        generated_for: "Enter Grower Name",
        location: null,
        grower: "Enter Grower Name",
        json: {
            geojson: {
                type: "Polygon",
                coordinates: getValue(payload, "field.geometry.coordinates")
            },
            plantable_acres: getValueWithUnit(payload, "field.size"),
            slope: getValueWithUnit(payload, "field.soils.slope"),
            slope_length: getValueWithUnit(payload, "field.soils.slope_length"),
            texture_id: getValue(payload, "field.greenhouse.soil_texture_id")
        }
    };

    let rotation = {
        year: getValue(payload, "cropyear.year"),
        crop_id: getValue(payload, "cropyear.crop"),
        previous_crop_residue_burned: getValue(payload, "cropyear.greenhouse.residue_burning"),
        previous_yield: getValueWithUnit(payload, "cropyear.greenhouse.yield_previous"),
        previous_crop_id: getValue(payload, "cropyear.greenhouse.previous_crop_id"),
        predominant_rotation_practice_id: getValue(payload, "cropyear.biodiversity.rotation_practice"),
        cover_crop: getValue(payload, "cropyear.biodiversity.cover_crop"),
        cover_crop_species_id: getValue(payload, "cropyear.waterquality.cover_crop_species"),
        cover_crop_establish_timing_id: getValue(payload, "cropyear.waterquality.cover_crop_establish_timing"),
        tillage_class_id: getValue(payload, "cropyear.biodiversity.tillage_class"),
        conservation_practice_ids: getValue(payload, "cropyear.waterquality.conservation_codes"),
        wind_barrier: getValue(payload, "cropyear.soils.has_wind_barriers"),
        tile_drainage_id: getValue(payload, "cropyear.waterquality.tile_drainage"),
        nutrient_plan: getValue(payload, "cropyear.biodiversity.nutrient_management_plan"),
        nutrient_4r_ids: getValue(payload, "cropyear.biodiversity.nutrient_4_rs"),
        n_carry_over: getValueWithUnit(payload, "cropyear.waterquality.n_carry_over", "lb/ac"),
        p_soil_test_level_id: getValue(payload, "cropyear.waterquality.p_soil_test_level"),
        p_multi_crop_application_id: getValue(payload, "cropyear.waterquality.p_multi_year_app"),
        management_technique_ids: getValue(payload, "cropyear.waterquality.management_techniques"),
        integrated_pest_management_id: getValue(payload, "cropyear.waterquality.pest_management_id"),
        land_conversion: getValue(payload, "cropyear.biodiversity.land_cover_change"),
        land_conversion_type_id: getValue(payload, "cropyear.biodiversity.previous_land_cover"),
        land_conversion_acres: getValueWithUnit(payload, "cropyear.biodiversity.field_area_converted"),
        wildlife_habitat_ids: getValue(payload, "cropyear.biodiversity.wildlife_habitat_ids"),
        lime_year_applied: getValue(payload, "cropyear.energyuse.lime_year_applied"),
        lime_years_applicable: getValueWithUnit(payload, "cropyear.energyuse.lime_years", "yr"),
        lime_amount: getValueWithUnit(payload, "cropyear.energyuse.lime_amount", "t/ac"),
        is_irrigated: getValue(payload, "cropyear.is_irrigated"),
        seeding_rate_id: getValue(payload, "activity.planting.energyuse.seeding_rate"),
        seed_treatment: getValue(payload, "activity.planting.energyuse.has_seed_treatment")
    };

    //  alfalfa
    if (crop_id === ALFALFA) {
        setValue(
            rotation,
            "alfalfa_establishment_year",
            getValue(payload, "activity.planting.energyuse.establishment_year")
        );
        setValue(
            rotation,
            "alfalfa_expected_stand_life",
            getValueWithUnit(payload, "activity.planting.energyuse.stand_life")
        );
        setValue(rotation, "alfalfa_residue_id", getValue(payload, "cropyear.energyuse.previous_residue"));
    }

    //  rice
    if (crop_id === RICE) {
        setValue(rotation, "rice_cultivar_id", getValue(payload, "activity.planting.greenhouse.rice_cultivar"));
        setValue(rotation, "rice_planting_method_id", getValue(payload, "activity.planting.greenhouse.seeding_method"));
        setValue(rotation, "rice_ratoon_crop", getValue(payload, "activity.planting.greenhouse.seeding_method"));
        setValue(rotation, "rice_water_regime_id", getValue(payload, "cropyear.greenhouse.water_regime"));
        setValue(rotation, "rice_residue_id", getValue(payload, "cropyear.energyuse.previous_residue"));
    }

    // manure
    rotation["manureApps"] = (getValue(payload, "activity.manure") || []).map(manure_app => {
        const isRice = crop_id === RICE,
            fertType = getValue(manure_app, "greenhouse.organic_fert_type"),
            isManure = !isRice || fertType === FARM_YARD_MANURE;

        let app = {};

        if (isRice) {
            setValue(app, "rice_organic_fert_type_id", getValue(manure_app, "greenhouse.organic_fert_type"));
        }
        if (isManure) {
            setValue(app, "manure_type_id", getValue(manure_app, "energyuse.manure_type"));
            setValue(app, "manure_app_timing_id", getValue(manure_app, "waterquality.application_timing"));
            setValue(app, "manure_app_method_id", getValue(manure_app, "waterquality.application_method_id"));
        }
        setValue(app, "manure_amount_id", getValue(manure_app, "energyuse.manure_rate"));
        if (isManure) {
            setValue(app, "n_applied", getValueWithUnit(manure_app, "greenhouse.manure_nitrogen_rate"));
            setValue(app, "p_applied", getValueWithUnit(manure_app, "waterquality.manure_phosphorus_rate"));
        }

        return app;
    });

    //  irrigation: if both ground_has_irrigation_energy and ground_has_irrigation_energy,
    // then there is secondary irrigation
    if (getValue(payload, "cropyear.is_irrigated") === true) {
        function getIrrigation(payload, rotation, primary, ground) {
            let a = primary === true ? "" : "secondary_";
            let b = ground === true ? "ground" : "surface";

            let has_energy = getValue(payload, "activity.irrigation.energyuse." + b + "_has_irrigation_energy");
            setValue(rotation, a + "irrigation_energy", has_energy);

            setValue(
                rotation,
                a + "irrigation",
                getValueWithUnit(payload, "activity.irrigation.wateruse." + b + "_water_applied", "ac_in/ac")
            );

            setValue(
                rotation,
                a + "irrigation_energy_exact",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_use_actual") : false
            );
            setValue(
                rotation,
                a + "pump_pressure_id",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_pump_pressure") : null
            );
            setValue(
                rotation,
                a + "pump_pressure_id",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_pump_pressure") : null
            );
            setValue(
                rotation,
                a + "pumping_depth_id",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_pump_depth") : null
            );
            setValue(
                rotation,
                a + "energy_source_id",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_energy_source") : null
            );
            setValue(
                rotation,
                a + "irrigation_fuel_id",
                has_energy === true ? getValue(payload, "activity.irrigation.energyuse." + b + "_fuel_id") : null
            );

            if (has_energy === true) {
                setValue(
                    rotation,
                    a + "irrigation_electric_amt",
                    getValueWithUnit(payload, "activity.irrigation.energyuse." + b + "_electricity_amount", "kWh", true)
                );

                setValue(
                    rotation,
                    a + "irrigation_fuel_amt",
                    getValueWithUnit(payload, "activity.irrigation.energyuse." + b + "_fuel_amount", "gal", true)
                );
            } else {
                setValue(rotation, a + "irrigation_electric_amt.value", null);
                setValue(rotation, a + "irrigation_electric_amt.unit", "kWh");

                setValue(rotation, a + "irrigation_fuel_amt.value", null);
                setValue(rotation, a + "irrigation_fuel_amt.unit", "gal");
            }
        }

        rotation["water_source_id"] = getValue(payload, "activity.irrigation.wateruse.water_source");
        rotation["irrigation_water_conservation"] = getValue(
            payload,
            "activity.irrigation.biodiversity.irrigation_conservation"
        );

        let has_ground_irrigation = getValue(payload, "activity.irrigation.wateruse.ground_water_applied");
        let has_surface_irrigation = getValue(payload, "activity.irrigation.wateruse.surface_water_applied");

        if (has_ground_irrigation !== null && has_surface_irrigation !== null) {
            getIrrigation(payload, rotation, true, true);
            getIrrigation(payload, rotation, false, false);
        } else if (has_ground_irrigation !== null && has_surface_irrigation === null) {
            getIrrigation(payload, rotation, true, true);
            getIrrigation(payload, rotation, false, false);
        } else if (has_ground_irrigation === null && has_surface_irrigation !== null) {
            getIrrigation(payload, rotation, true, false);
            getIrrigation(payload, rotation, false, true);
        } else {
            getIrrigation(payload, rotation, true, true);
            getIrrigation(payload, rotation, false, false);
        }
    }

    function getHarvestInfo(rotationObj, cropid) {
        let harvest = (getValue(payload, "activity.harvest") || [{}])[0];

        //  transportation
        rotationObj["trans_back_haul"] = getValue(harvest, "energyuse.backhauling");
        rotationObj["trans_fuel_type_id"] = getValue(harvest, "energyuse.transportation_fuel_id");
        setValue(rotationObj, "field_to_pos_distance", getValueWithUnit(harvest, "energyuse.transportation_distance"));

        //  drying
        rotationObj["drying_energy_source_id"] = getValue(harvest, "energyuse.drying_energy_source");
        rotationObj["drying_systems_id"] = getValue(harvest, "energyuse.drying_system");
        rotationObj["drying_fuel_id"] = getValue(harvest, "energyuse.drying_fuel_id");
        rotationObj["drying_fuel_amt"] = getValueWithUnit(harvest, "energyuse.drying_fuel_amount", "gal");
        rotationObj["crop_level_moisture_diff"] = getValueWithUnit(harvest, "energyuse.moisture_removed", "%");
        rotationObj["yield"] = getValueWithUnit(harvest, "landuse.yield");
        rotationObj["drying_electric_amt"] = getValueWithUnit(harvest, "energyuse.drying_electricity_amount", "kWh");
        rotationObj["abandoned_harvested_acres"] = getValueWithUnit(harvest, "landuse.harvested_area", "ac");
        rotationObj["reason_id"] = getValue(harvest, "landuse.abandonment_reason");

        //  est_non_irrigated_yield
        if (getValue(payload, "cropyear.is_irrigated") === true) {
            if (crop_id === RICE) {
                setValue(rotationObj, "est_non_irrigated_yield", null);
            } else {
                setValue(
                    rotationObj,
                    "est_non_irrigated_yield",
                    getValueWithUnit(harvest, "wateruse.est_non_irrigated_yield")
                );
            }
        } else {
            setValue(rotationObj, "est_non_irrigated_yield", null);
        }

        if (crop_id === RICE) {
            //  rice
            setValue(rotation, "rice_ratoon_crop", getValue(harvest, "greenhouse.ratoon_cropping"));
            setValue(rotation, "rice_yield_calc_method_id", getValue(harvest, "landuse.yield_method"));
            const moisture = getValueWithUnit(harvest, "landuse.harvest_moisture");
            if (moisture) {
                setValue(rotation, "rice_avg_harvest_moisture", moisture);
            }
        }

        if (crop_id === PEANUTS) {
            //  peanuts
            setValue(
                rotation,
                "peanuts_initial_moisture",
                getValueWithUnit(harvest, "energyuse.peanuts_initial_moisture")
            );
        }

        if (crop_id === COTTON) {
            setValue(rotation, "cotton_moisture_id", getValue(harvest, "energyuse.cotton_target_moisture"));
        }

        if (crop_id === SUGAR_BEETS) {
            setValue(rotation, "beets_perct_sugar", getValueWithUnit(harvest, "landuse.percent_sugar", "%"));
        }

        return rotationObj;
    }

    function getAlfalfaHarvestInfo(alfalfa_harvests) {
        let cuttings = [];
        alfalfa_harvests.forEach(cutting => {
            let temp_cutting = {};
            setValue(
                temp_cutting,
                "abandoned_harvested_acres",
                getValueWithUnit(cutting, "landuse.harvested_area", "ac")
            );
            setValue(temp_cutting, "abandoned_harvested_acres.unit", "ac");
            setValue(temp_cutting, "reason_id", getValue(cutting, "landuse.abandonment_reason"));

            setValue(temp_cutting, "yield", getValueWithUnit(cutting, "landuse.yield"));

            setValue(temp_cutting, "alfalfa_harvest_moisture", getValueWithUnit(cutting, "landuse.harvest_moisture"));

            //  est_non_irrigated_yield
            if (getValue(payload, "cropyear.is_irrigated") === true) {
                setValue(
                    temp_cutting,
                    "est_non_irrigated_yield",
                    getValueWithUnit(cutting, "wateruse.est_non_irrigated_yield")
                );
            } else {
                setValue(temp_cutting, "est_non_irrigated_yield", null);
            }

            setValue(temp_cutting, "alfalfa_harvest_type_id", getValue(cutting, "energyuse.harvest_type"));

            setValue(
                temp_cutting,
                "field_to_pos_distance",
                getValueWithUnit(cutting, "energyuse.transportation_distance")
            );
            setValue(temp_cutting, "trans_fuel_type_id", getValue(cutting, "energyuse.transportation_fuel_id"));
            setValue(temp_cutting, "trans_back_haul", getValue(cutting, "energyuse.backhauling"));
            setValue(temp_cutting, "alfalfa_drying_system_id", getValue(cutting, "energyuse.alfalfa_drying_system"));
            setValue(
                temp_cutting,
                "crop_level_moisture_diff",
                getValueWithUnit(cutting, "energyuse.moisture_removed", "%", true)
            );

            cuttings.push(temp_cutting);
        });
        return cuttings;
    }

    //  harvest
    if (crop_id === ALFALFA) {
        setValue(rotation, "alfalfa_cuttings", getAlfalfaHarvestInfo(payload["activity"]["harvest"]));
    } else {
        rotation = getHarvestInfo(rotation, crop_id);
    }

    //  fertilizers
    let app_trips = [];
    payload["activity"]["application"].forEach(app => {
        let temp_trip = {};
        setValue(temp_trip, "fertilizer_app_timing_id", getValue(app, "waterquality.application_timing"));
        setValue(temp_trip, "fertilizer_app_method_id", getValue(app, "waterquality.application_method_id"));

        //  protectants
        let temp_prot = {};
        setValue(temp_prot, "herbicide_apps", getValue(app, "energyuse.herbicide_count"));
        setValue(temp_prot, "insecticide_apps", getValue(app, "energyuse.insecticide_count"));
        setValue(temp_prot, "fungicide_apps", getValue(app, "energyuse.fungicide_count"));
        setValue(temp_prot, "growth_reg_apps", getValue(app, "energyuse.growthregulator_count"));
        setValue(temp_prot, "fumigant_apps", getValue(app, "energyuse.fumigant_count"));
        if (crop_id === RICE && "harvestaid_count" in getValue(app, "energyuse")) {
            setValue(temp_prot, "harvest_aid_apps", getValue(app, "energyuse.harvestaid_count"));
        }
        setValue(temp_trip, "protApps", [temp_prot]);

        //  fertilizers
        let fert_apps = [];
        if (!!app["fertilizers"] === true) {
            app["fertilizers"].forEach(fert => {
                let temp_fert = {};
                setValue(temp_fert, "fert_id", getValue(fert, "energyuse.fertilizer_id"));
                if (getValue(fert, "energyuse.fertilizer_rate") === null) {
                    setValue(temp_fert, "fertilizer_amount.value", null);
                    setValue(temp_fert, "fertilizer_amount.unit", "lb/ac");
                } else {
                    setValue(temp_fert, "fertilizer_amount", getValueWithUnit(fert, "energyuse.fertilizer_rate"));
                }

                let cust_fert_json = {};
                if (getValue(fert, "energyuse.fertilizer_id") === CUSTOM_BLEND) {
                    setValue(cust_fert_json, "type", "blend");
                    setValue(cust_fert_json, "n", getValueWithUnit(fert, "energyuse.customblend.nitrogen"));
                    setValue(cust_fert_json, "p", getValueWithUnit(fert, "energyuse.customblend.phosphorus"));
                    setValue(cust_fert_json, "k", getValueWithUnit(fert, "energyuse.customblend.potassium"));
                }
                if (getValue(fert, "energyuse.fertilizer_id") === CUSTOM_RATE) {
                    setValue(cust_fert_json, "type", "rate");
                    setValue(cust_fert_json, "n", getValueWithUnit(fert, "energyuse.customrate.nitrogen"));
                    setValue(cust_fert_json, "p", getValueWithUnit(fert, "energyuse.customrate.phosphorus"));
                    setValue(cust_fert_json, "k", getValueWithUnit(fert, "energyuse.customrate.potassium"));
                }
                setValue(temp_fert, "custom_fert_json", cust_fert_json);
                fert_apps.push(temp_fert);
            });
        }
        setValue(temp_trip, "fertApps", fert_apps);
        app_trips.push(temp_trip);
    });

    setValue(rotation, "applicationTrips", app_trips);

    //  rotation
    if ("rotation" in payload["cropyear"]) {
        setValue(rotation, "rotation_json", getValue(payload, "cropyear.rotation"));
    }

    setValue(qdmpPayload, "json.Rotation", [rotation]);

    return qdmpPayload;
};
