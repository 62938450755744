import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

import { CROP_HISTORY_CROPS } from "../../../api/constants";
import Select from "../../common/Select";
import * as navActions from "../../common/actions";
import { Typography } from "@mui/material";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    expansionPanel: {
        boxShadow: "none"
    },
    expansionSummary: {
        backgroundColor: "#F7F6F6",
        border: "1px solid lightgrey"
    },
    select: {
        marginTop: -5
    }
});

function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

class CropHistory extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    continueButtons = draftClick => {
        if (draftClick) {
            const { fieldId } = this.props;
            this.props.history.push("/field/" + fieldId);
        } else {
            this.props.handleNext();
        }
    };

    changeCrop = (value, year) => {
        const { cropYear, ormFieldUpdate } = this.props;

        var crop_history = cropYear.fieldObject.crop_history;
        var c_index = crop_history.findIndex(c => c.year === year);
        crop_history[c_index].crops[0].crop = value;

        ormFieldUpdate({
            id: cropYear.fieldObject.id,
            crop_history: crop_history
        });
    };

    render() {
        const { classes, fieldName, cropYear } = this.props;

        var previous_1, previous_2, previous_1_year, previous_2_year;

        if (cropYear.fieldObject && cropYear.fieldObject.crop_history) {
            cropYear.fieldObject.crop_history.forEach(year => {
                if (year === cropYear.year - 1) {
                    previous_1 = year.crops[0].crop;
                    previous_1_year = year.year;
                }
                if (year === cropYear.year - 2) {
                    previous_2 = year.crops[0].crop;
                    previous_1_year = year.year;
                }
            });
            if (!previous_1 && cropYear.year > 2016) {
                previous_1 = cropYear.fieldObject.crop_history[0].crops[0].crop;
                previous_1_year = cropYear.fieldObject.crop_history[0].year;
                previous_2 = cropYear.fieldObject.crop_history[1].crops[0].crop;
                previous_2_year = cropYear.fieldObject.crop_history[1].year;
            }
        }

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} className={classes.expansionPanel}>
                        <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="title">
                                <b>{cropYear.crop_name}</b> ({cropYear.year})
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>...</AccordionDetails>
                    </Accordion>
                    {previous_1 && (
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">
                                    <b>
                                        <Form
                                            defaultValues={{ crop: previous_1 }}
                                            dontValidateOnMount="true"
                                            validateOnSubmit="true">
                                            {formApi => (
                                                <form style={{ display: "inline-block" }}>
                                                    <Select
                                                        eventHandle={value => {
                                                            this.changeCrop(value, previous_1_year);
                                                        }}
                                                        className={classes.select}
                                                        field="crop"
                                                        label=""
                                                        options={MAKE_OPTIONS(CROP_HISTORY_CROPS)}
                                                        margin="normal"
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </b>{" "}
                                    <span style={{ display: "inline-block", marginTop: 12 }}>({previous_1_year})</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>...</AccordionDetails>
                        </Accordion>
                    )}
                    {previous_2 && (
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">
                                    <b>
                                        <Form
                                            defaultValues={{ crop: previous_2 }}
                                            dontValidateOnMount="true"
                                            validateOnSubmit="true">
                                            {formApi => (
                                                <form style={{ display: "inline-block" }}>
                                                    <Select
                                                        eventHandle={value => {
                                                            this.changeCrop(value, previous_2_year);
                                                        }}
                                                        className={classes.select}
                                                        field="crop"
                                                        label=""
                                                        options={MAKE_OPTIONS(CROP_HISTORY_CROPS)}
                                                        margin="normal"
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </b>{" "}
                                    <span style={{ display: "inline-block", marginTop: 12 }}>({previous_2_year})</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>...</AccordionDetails>
                        </Accordion>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button variant="raised" onClick={() => this.continueButtons(true)} className={classes.button}>
                        Save and Return to {fieldName} Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={() => this.continueButtons(false)}
                        className={classes.button}>
                        Save and Proceed to Review Screen <ChevronRightIcon />
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

CropHistory = connect(null, {
    ...navActions
})(CropHistory);

export default withStyles(styles)(CropHistory);
