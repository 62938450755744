import React from "react";
import { hasFeatureFlag } from "../../api/constants";

export default function FeatureFlag({ flag, component: Component, ...props }) {
    if (hasFeatureFlag(flag)) {
        return <Component {...props} />;
    } else {
        return null;
    }
}
