import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import withStyles from "@mui/styles/withStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BookIcon from "@mui/icons-material/Book";

import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import HelpDialog from "../../common/HelpDialog";
import TablePaginationActionsWrapped from "../../common/Paginator";
import WarningDialog from "../../common/WarningDialog";
import * as authActions from "../../auth/actions";
import { dbFetch } from "../../../api/fetch";

const styles = theme => ({
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    centerAlign: {
        textAlign: "center"
    },
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 500
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", allowSort: false },
    { id: "year", numeric: true, label: "Year", allowSort: true },
    { id: "status", numeric: false, label: "Status", allowSort: true },
    { id: "fsa", numeric: false, label: "Equivalency FSA Level", allowSort: true }
];

class SaiTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            dialogOpen: false
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const isNumeric = columnData.filter(function(item) {
            if (item.id === property) return item;
            else return null;
        });
        if (isNumeric[0].numeric) {
            order === "desc"
                ? this.props.farmSurveys.sort((a, b) => (parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1))
                : this.props.farmSurveys.sort((a, b) => (parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.farmSurveys.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
                : this.props.farmSurveys.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    deleteSurvey = (survey, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: survey,
            deleteDialogText: "Are you sure you wish to permanently delete the " + name + " survey?"
        });
    };

    openReport = id => {
        var { history, authLogout } = this.props;

        dbFetch("/survey/report/" + id, {
            method: "POST"
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                this.setState({ dialogOpen: true });
            }
        });
    };

    render() {
        const { farmSurveys, classes, ormFarmSurveyDelete } = this.props;

        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            deleteDialogOpen,
            deleteDialogText,
            deleteDialogConfirmAction,
            dialogOpen
        } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, farmSurveys.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData}
                    />
                    <TableBody>
                        {farmSurveys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(mA => {
                            return (
                                <TableRow key={mA.id} hover>
                                    <CustomTableCell>
                                        <Tooltip title="Edit Questionnaire">
                                            <Button
                                                className={classes.buttonWidth}
                                                component={Link}
                                                to={"survey/" + mA.id}>
                                                <EditIcon color="primary" />
                                                {/*
                                                    {mA.status && <EditIcon style={{ color: "green" }} />}
                                                    {!mA.status && <EditIcon color="primary" />}
                                                    */}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Report">
                                            <div className={classes.analyticsTooltip}>
                                                <Button
                                                    className={classes.buttonWidth}
                                                    disabled={!mA.status}
                                                    onClick={() => this.openReport(mA.id)}>
                                                    {mA.status && <BookIcon color="primary" />}
                                                    {!mA.status && <BookIcon />}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Delete Questionnaire">
                                            <Button
                                                className={classes.buttonWidth}
                                                onClick={() => this.deleteSurvey(mA.id, mA.year)}>
                                                <DeleteIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell>{mA.year}</CustomTableCell>
                                    <CustomTableCell>{mA.status ? "Complete" : "Incomplete"}</CustomTableCell>
                                    <CustomTableCell>{mA.fsa ? mA.fsa : "Equivalency level not met."}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && farmSurveys.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={4} />
                            </TableRow>
                        )}
                        {farmSurveys.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                    No SAI Surveys Entered
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {farmSurveys.length > 10 && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={4}
                                    count={farmSurveys.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    classes={{
                                        caption: classes.body1
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
                <WarningDialog
                    confirmAction={() => {
                        ormFarmSurveyDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Survey"
                    text={deleteDialogText}
                />
                <HelpDialog
                    question="FSA Equivalency Submission"
                    showErrorOutline={true}
                    text="Your FSA Equivalency responses are being submitted to the queue. When ready, an email with a link to download the report in Microsoft Excel format will be sent. Please check your spam folder if you do not receive the email."
                    open={dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                />
            </Paper>
        );
    }
}

SaiTable = connect(null, {
    ...authActions
})(SaiTable);

export default withStyles(styles)(withRouter(SaiTable));
