import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Grid from "../../../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import HorizontalLogo from "../img/color_02_horizontal.jpg";
import { getValue } from "../../../../api/utils";

// Custom CSS classes
const styles = theme => ({
    header: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in"
    },
    headerTitle: {
        fontSize: 15
    },
    firstHeaderText: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 13
    },
    runningHeaderText: {
        lineHeight: "1.4em",
        display: "block",
        fontSize: 12
    },
    marginTop: {
        paddingTop: 0.5
    },
    summary: {
        backgroundColor: "#eee",
        marginTop: "1.5em",
        marginBottom: "1em",
        padding: "0.5em",
        color: "rgba(0, 0, 0, 0.54)"
    },
    headerImage: {
        height: "1in"
    }
});

function getTimeStamp() {
    function pad(n) {
        return n < 10 ? "0" + n : n;
    }

    var currentTime = new Date();
    var MM = pad(currentTime.getMonth() + 1);
    var DD = pad(currentTime.getDate());
    var YYYY = currentTime.getFullYear();
    var hh = currentTime.getHours();
    if (hh > 12) {
        hh = hh - 12;
    }
    hh = pad(hh);
    var mm = pad(currentTime.getMinutes());

    var ampm = "AM";
    if (currentTime.getHours() > 11) {
        ampm = "PM";
    }

    var timestamp = [[MM, DD, YYYY].join("/"), [hh, mm].join(":"), ampm].join(" ");

    return timestamp;
}

function getVersion(cropYear) {
    const version = getValue(cropYear, "metrics.Calculator.result.metadata.version.api") || "-";
    const [major, minor] = version.split(".", 2);
    return `${major}.${minor}`;
}

class PageHeader extends Component {
    render() {
        const { classes, cropYear, first } = this.props,
            fieldSize = getValue(cropYear, "fieldObject.size"),
            fieldLocation = getValue(cropYear, "fieldObject.location"),
            timestamp = getTimeStamp(),
            headerVariant = first ? "body2" : "caption",
            headerClass = first ? classes.firstHeaderText : classes.runningHeaderText;

        return (
            <div className={classNames(classes.header, !first && classes.marginTop)}>
                {first && (
                    <>
                        <center>
                            <img className={classes.headerImage} src={HorizontalLogo} alt={"Field To Market logo"} />
                        </center>
                        <Typography variant="h6" align="center" className={classes.headerTitle}>
                            Fieldprint Analysis Report | {cropYear.year} {cropYear.crop_name}
                        </Typography>
                    </>
                )}
                <Grid container className={classes.summary}>
                    <Grid item xs={7}>
                        <Typography variant={headerVariant} className={headerClass}>
                            <b>Grower/Account Name:</b> {cropYear.modify_user}
                            <br />
                            <b>Location:</b> {fieldLocation}
                            <br />
                            <b>Farm/Field:</b> {cropYear.farm_name} / {cropYear.field_name}
                            <br />
                            <b>Crop Year:</b> {cropYear.year} {cropYear.crop_name}
                            <br />
                            <b>Report Generated:</b> {timestamp}
                        </Typography>
                    </Grid>

                    <Grid item xs={5}>
                        <Typography variant={headerVariant} className={headerClass}>
                            <b>Plantable Acres:</b> {fieldSize}
                            <br />
                            <b>Irrigated:</b> {cropYear.is_irrigated ? "Yes" : "No"}
                            <br />
                            <b>Platform:</b> {cropYear.platform || "Fieldprint Calculator"}
                            <br />
                            <b>FPP Version:</b> {getVersion(cropYear)}
                            <br />
                            <b>Data Status:</b>{" "}
                            {cropYear.platform
                                ? cropYear.data_status || "Not Specified"
                                : cropYear.is_final
                                ? "Final"
                                : "Provisional"}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(PageHeader);
