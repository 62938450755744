import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";

import withStyles from "@mui/styles/withStyles";
import DeleteIcon from "@mui/icons-material/Delete";

import Select from "../../../common/Select";
import TextField from "../../../common/TextField";
import WarningDialog from "../../../common/WarningDialog";
import { createSelector } from "../../../common/orm";
import { CUSTOM_BLEND, CUSTOM_RATE } from "../../../../api/constants";
import { getValue, setValue } from "../../../../api/utils";

const allFertilizers = createSelector(schema => {
    return schema.Fertilizers.all()
        .orderBy("type")
        .toModelArray();
});

const styles = theme => ({
    formRoot: {
        border: "1px dotted",
        padding: "0 16px",
        borderRadius: theme.spacing(1),
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(1),
        position: "relative"
    },
    deleteButton: {
        position: "absolute",
        right: 0,
        zIndex: 1200
    }
});

class Applications extends Component {
    // FIXME: Ideally every Application would be saved when the parent component (FertilizingCommerical) was saved
    // There doesnt seem to be a way to pass a function down to every child component that saves them when the parent is saved
    // Instead just save during onChange instead of onSubmit (maybe this should be the default if there aren't issues saving too much)
    state = {
        deleteDialogOpen: false,
        deleteDialogText: null,
        deleteDialogConfirmAction: null,
        productUnits: null
    };
    typingTimer;
    typingTimer2;

    updateFertilizer(values) {
        const { ormFertilizerUpdate, ormCropYearUpdateLocalOnly, cropYear } = this.props;
        const { id } = this.props.application;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFertilizerUpdate({
                id: id,
                ...values
            });
            ormCropYearUpdateLocalOnly({
                id: cropYear.id,
                metrics: null
            });
        }, 5000);
    }

    deleteApplication = field => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete this Fertilizer Application?"
        });
    };

    productUnit = (value, formValues) => {
        const { fertilizers, ormFertilizerUpdateLocalOnly } = this.props;
        const { id } = this.props.application;
        const app = this;
        if (!formValues) formValues = this.form.values;

        var units = null;
        fertilizers.forEach(function(f) {
            if (f.id === value) {
                units = f._fields.default_units;
                app.setState({ productUnits: f._fields.default_units });
            }
        });

        var current_value = getValue(formValues, "energyuse.fertilizer_rate");

        if (current_value) {
            //  need to update form value with units manually
            var new_value = current_value.split(" ")[0] + " " + units;
            setValue(formValues, "energyuse.fertilizer_rate", new_value);
            this.form.setValue("energyuse.fertilizer_rate", new_value);
        }

        // Clear out hidden values when fertilizer type is changed
        // This event happens before form onsubmit (from form change)
        if (value === CUSTOM_RATE) {
            this.form.setValue("energyuse.fertilizer_rate", null);
        }
        if (value !== CUSTOM_BLEND) {
            this.form.setValue("energyuse.customblend.nitrogen", null);
            this.form.setValue("energyuse.customblend.phosphorus", null);
            this.form.setValue("energyuse.customblend.potassium", null);
        }
        if (value !== CUSTOM_RATE) {
            this.form.setValue("energyuse.customrate.nitrogen", null);
            this.form.setValue("energyuse.customrate.phosphorus", null);
            this.form.setValue("energyuse.customrate.potassium", null);
        }
        const formValues2 = {
            energyuse: { ...this.props.application.energyuse }
        };
        setValue(formValues2, "energyuse.fertilizer_id", value);
        ormFertilizerUpdateLocalOnly({
            id: id,
            ...formValues2
        });
    };

    componentDidMount() {
        const { application } = this.props;
        const fertilizer_id = getValue(application, "energyuse.fertilizer_id");
        this.productUnit(fertilizer_id);
    }

    warningValidator = (values, cY) => {
        const { fertilizers } = this.props;
        const validateFert = path => {
            let val = getValue(values, path);
            let fertType = getValue(values, "energyuse.fertilizer_id");
            const fertVal = fertilizers.find(f => f.id === fertType);

            if (!fertVal) return;

            const number = parseFloat(val);

            if (number > fertVal["max_flag"] && number <= fertVal["max_error"]) {
                setValue(valObj, path, "Typically the product does not exceed " + fertVal["max_flag"]);
                return;
            }
            setValue(valObj, path, null);
        };

        const validateBlend = (path, rate, rateinput) => {
            let val = parseFloat(getValue(values, path));
            let ri = parseFloat(getValue(values, rateinput));

            if ((val / 100) * ri > rate) {
                setValue(valObj, path, "Typically the amount does not exceed " + rate);
                return;
            }
            setValue(valObj, path, null);
        };

        const validateRate = (path, rate) => {
            let val = parseFloat(getValue(values, path));

            if (val > rate) {
                setValue(valObj, path, "Typically the amount does not exceed " + rate);
                return;
            }
            setValue(valObj, path, null);
        };

        var valObj = {};
        const fertilizer_id = getValue(values, "energyuse.fertilizer_id");
        if (fertilizer_id === CUSTOM_BLEND) {
            validateBlend("energyuse.customblend.nitrogen", 275, "energyuse.fertilizer_rate");
            validateBlend("energyuse.customblend.phosphorus", 100, "energyuse.fertilizer_rate");
            validateBlend("energyuse.customblend.potassium", 250, "energyuse.fertilizer_rate");
        } else if (fertilizer_id === CUSTOM_RATE) {
            validateRate("energyuse.customrate.nitrogen", 275);
            validateRate("energyuse.customrate.phosphorus", 100);
            validateRate("energyuse.customrate.potassium", 250);
        } else {
            validateFert("energyuse.fertilizer_rate");
        }

        return valObj;
    };

    errorValidator = (values, cY) => {
        const { fertilizers } = this.props;
        const validateFert = path => {
            let val = getValue(values, path);
            let fertType = getValue(values, "energyuse.fertilizer_id");
            const fertVal = fertilizers.find(f => f.id === fertType);

            if (!fertVal) return;

            const number = parseFloat(val);

            if (number < 0 || number > fertVal["max_error"]) {
                setValue(valObj, path, "Amount cannot exceed " + fertVal["max_error"]);
                return;
            }
            setValue(valObj, path, null);
        };

        const validateHundred = (p1, p2, p3) => {
            let val1 = getValue(values, p1);
            let val2 = getValue(values, p2);
            let val3 = getValue(values, p3);
            if (parseFloat(val1) + parseFloat(val2) + parseFloat(val3) > 100) {
                setValue(valObj, p1, "Combined percentages over 100");
                setValue(valObj, p2, "Combined percentages over 100");
                setValue(valObj, p3, "Combined percentages over 100");
                return;
            }
            setValue(valObj, p1, null);
            setValue(valObj, p2, null);
            setValue(valObj, p3, null);
        };

        const validateBlend = (path, rate, rateinput) => {
            let val = parseFloat(getValue(values, path));
            let ri = parseFloat(getValue(values, rateinput));

            if ((val / 100) * ri > rate) {
                setValue(valObj, path, "Amount cannot exceed " + rate);
                return;
            }
            setValue(valObj, path, null);
        };

        const validateRate = (path, rate) => {
            let val = parseFloat(getValue(values, path));

            if (val > rate) {
                setValue(valObj, path, "Amount cannot exceed " + rate);
                return;
            }
            setValue(valObj, path, null);
        };

        var valObj = {};
        const fertilizer_id = getValue(values, "energyuse.fertilizer_id");
        if (fertilizer_id === CUSTOM_BLEND) {
            validateHundred(
                "energyuse.customblend.nitrogen",
                "energyuse.customblend.phosphorus",
                "energyuse.customblend.potassium"
            );
            validateBlend("energyuse.customblend.nitrogen", 325, "energyuse.fertilizer_rate");
            validateBlend("energyuse.customblend.phosphorus", 150, "energyuse.fertilizer_rate");
            validateBlend("energyuse.customblend.potassium", 300, "energyuse.fertilizer_rate");
        } else if (fertilizer_id === CUSTOM_RATE) {
            validateRate("energyuse.customrate.nitrogen", 325);
            validateRate("energyuse.customrate.phosphorus", 150);
            validateRate("energyuse.customrate.potassium", 300);
        } else {
            validateFert("energyuse.fertilizer_rate");
        }

        return valObj;
    };

    parentValidate() {
        const { parentValidator } = this.props;
        // Update the validators on the parent form
        // There is no separate "validate only" command so submit the parent form
        // but don't submit the form to the server if silent

        clearTimeout(this.typingTimer2);
        this.typingTimer2 = setTimeout(function() {
            parentValidator.addValue("silent", "set");
            parentValidator.submitForm("silent");
        }, 5000);
    }

    render() {
        const { classes, application, fertilizers, ormFertilizerDelete, cropYear, parentValidator } = this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, productUnits } = this.state;

        // Custom makeOptions function for optgroups
        function makeOptions(table) {
            var type = "";
            var newArray = [];
            var newOption = [];
            table.forEach(function(item, index) {
                if (index === 0) type = item.type;

                if (type !== item.type || index === table.length - 1) {
                    if (index === table.length - 1) {
                        newOption.push({ iLabel: item.name, iValue: item.id });
                    }
                    newOption.sort(function(a, b) {
                        var a_is_text = /[A-Za-z]/.test(a.iLabel);
                        var b_is_text = /[A-Za-z]/.test(b.iLabel);

                        // If both values are text sort normally
                        if (a_is_text && b_is_text) {
                            if (a.iLabel < b.iLabel) {
                                return -1;
                            }
                            if (a.iLabel > b.iLabel) {
                                return 1;
                            }
                            return 0;
                        }
                        // If only a is text move above
                        if (a_is_text) {
                            return -1;
                        }
                        // If only b is text move down
                        if (b_is_text) {
                            return 1;
                        }
                        // if both numbers
                        if (parseInt(a.iLabel.split("-")[0]) < parseInt(b.iLabel.split("-")[0])) {
                            return -1;
                        }
                        if (parseInt(a.iLabel.split("-")[0]) > parseInt(b.iLabel.split("-")[0])) {
                            return 1;
                        }
                        return 0;
                    });
                    newArray.push({ items: newOption, label: type, optgroup: true });
                    newOption = [];
                    type = item.type;
                }
                newOption.push({ iLabel: item.name, iValue: item.id });
            });

            newArray.unshift({ label: "", value: null });
            var SORT_ORDER = [
                "Commonly selected fertilizers",
                "Fluid fertilizers (gal/acre)",
                "Dry fertilizers (lb/acre)"
            ];
            newArray.sort(function(a, b) {
                if (SORT_ORDER.indexOf(a.label) < SORT_ORDER.indexOf(b.label)) {
                    return -1;
                }
                if (SORT_ORDER.indexOf(a.label) > SORT_ORDER.indexOf(b.label)) {
                    return 1;
                }
                return 0;
            });
            return newArray;
        }
        var unitLabel = "";
        const fertilizer_id = getValue(application, "energyuse.fertilizer_id");

        if (fertilizer_id === CUSTOM_BLEND) unitLabel = "%";
        else if (fertilizer_id === CUSTOM_RATE) unitLabel = "lb/ac";

        return (
            <Form
                getApi={el => (this.form = el)}
                key={application.id}
                dontValidateOnMount="true"
                validateOnSubmit="true"
                defaultValues={application}
                formDidUpdate={() => this.parentValidate()}
                validateError={values => this.errorValidator(values, cropYear)}
                validateWarning={values => this.warningValidator(values, cropYear)}
                onSubmit={values => this.updateFertilizer(values)}>
                {formApi => (
                    <form className={classes.formRoot} onChange={formApi.submitForm}>
                        <WarningDialog
                            confirmAction={() => {
                                ormFertilizerDelete(deleteDialogConfirmAction);
                                this.setState({ deleteDialogOpen: false });
                                setTimeout(function() {
                                    parentValidator.addValue("silent", "set");
                                    parentValidator.submitForm("silent"); // Refresh validator upon deleting
                                }, 200);
                            }}
                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                            open={deleteDialogOpen}
                            title="Delete Fertilizer Application"
                            text={deleteDialogText}
                        />
                        <Button className={classes.deleteButton} onClick={() => this.deleteApplication(application.id)}>
                            <DeleteIcon />
                        </Button>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <Select
                                    field="energyuse.fertilizer_id"
                                    label="Fertilizer product type"
                                    SelectProps={{ native: true }}
                                    native
                                    eventHandle={value => this.productUnit(value, formApi.values)}
                                    options={makeOptions(fertilizers)}
                                    help={
                                        <div>
                                            Select a fertilizer type from the list of dry blends, liquid blends (in
                                            gallons or pounds) or products. Pay special attention to the unit of measure
                                            when entering your fertilizer rates.
                                            <br />
                                            <br />
                                            Dry Blends: Typically sold as granules Liquid fertilizers: Typically sold as
                                            water soluble powders or liquid concentrates
                                            <br />
                                            <br />
                                            Custom: Specific custom formulation of N, P2O5 and K2O that differs from
                                            standard commercial products Other
                                            <br />
                                            <br />
                                            (Rate): Specific formulations of fertilizers commonly applied.
                                        </div>
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {fertilizer_id !== CUSTOM_RATE && (
                                    <TextField
                                        type="number"
                                        inputProps={{ step: 0.1 }}
                                        field="energyuse.fertilizer_rate"
                                        fullWidth
                                        label="Product amount"
                                        margin="normal"
                                        help="Enter the amount of this fertilizer type that was applied in this application trip. Pay special attention to the units of measure when entering this data."
                                        units={productUnits}
                                    />
                                )}
                                {fertilizer_id === CUSTOM_BLEND && (
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customblend.nitrogen"
                                                units={unitLabel}
                                                fullWidth
                                                label="N"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customblend.phosphorus"
                                                units={unitLabel}
                                                fullWidth
                                                label="P2O5"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customblend.potassium"
                                                units={unitLabel}
                                                fullWidth
                                                label="K2O"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {fertilizer_id === CUSTOM_RATE && (
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customrate.nitrogen"
                                                units={unitLabel}
                                                fullWidth
                                                label="N"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customrate.phosphorus"
                                                units={unitLabel}
                                                fullWidth
                                                label="P2O5"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="energyuse.customrate.potassium"
                                                units={unitLabel}
                                                fullWidth
                                                label="K2O"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Applications = connect(state => ({
    fertilizers: allFertilizers(state)
}))(Applications);

export default withStyles(styles)(Applications);
