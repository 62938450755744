import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";

import { ManureTypes, ManureAmounts, OrganicFertTypes, ApplicationTimes, DominantApplicationMethods } from "./models";
import { ACTIVITY_TYPES } from "../models";
import Select from "../../../common/Select";
import Snackbar from "../../../common/Snackbar";
import TextField from "../../../common/TextField";
import WarningDialog from "../../../common/WarningDialog";
import { RICE, GAL, LBS, FARM_YARD_MANURE, MAKE_OPTIONS } from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

const allManureTypes = ManureTypes.selectAll();
const allManureAmounts = ManureAmounts.selectAll();
const allOrganicFertTypes = OrganicFertTypes.selectAll();
const allApplicationTimes = ApplicationTimes.selectAll();
const allDominantApplicationMethods = DominantApplicationMethods.selectAll();

const styles = {
    floatRight: {
        float: "right"
    }
};

const NITROGEN_MSG = "Typically the rate does not exceed 250 lbs/ac.",
    NITROGEN_LOW_MSG = "Values less than 1 are typically not valid.",
    PHOSPHORUS_MSG = "Typically the rate does not exceed 100 lbs/ac.";

class Manure extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            amountLabel: "",
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            snackbarOpen: false
        };
    }

    updateFieldActivity(values) {
        const { id } = this.props.fieldActivity;
        var isCompleted = this.errorValidator(values, this.props.cropYear, false);

        this.props.ormFieldActivityUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: isCompleted[0] === 0,
                missing: isCompleted[0],
                required: isCompleted[1]
            }
        });
        this.props.ormCropYearUpdateLocalOnly({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.handleUnsavedFields(false);

        // Reapply required label to any field not yet filled in by adding a nonexistant value
        this.setState({ submitClicked: false, snackbarOpen: true });
        this.form.addValue("foo", "bar");
    }

    fertChange = value => {
        if (value !== FARM_YARD_MANURE) {
            this.form.setValue("manure.greenhouse.manure_nitrogen_rate", null);
            this.form.setValue("manure.energyuse.manure_type", null);
        }
    };

    deleteFieldActivity = (field, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    // Update values when it becomes real lookup
    typeChange = value => {
        if (GAL.includes(value)) {
            this.setState({ amountLabel: "gal / acre" });
        } else if (LBS.includes(value)) {
            this.setState({ amountLabel: "lb / acre" });
        } else {
            this.setState({ amountLabel: "" });
        }
    };

    componentDidMount() {
        const { fieldActivity, handleUnsavedFields } = this.props;
        const { amountLabel } = this.state;
        this.props.onRef(this);
        const manure_type = getValue(fieldActivity, "manure.energyuse.manure_type");

        if (amountLabel !== this.typeChangeLookup(manure_type)) this.typeChange(manure_type);

        setTimeout(function() {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    typeChangeLookup = value => {
        if (GAL.includes(value)) {
            return "gal / acre";
        } else if (LBS.includes(value)) {
            return "lb / acre";
        } else {
            return "";
        }
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    errorValidator = (values, cY, hardRequire) => {
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        const validateNumber = (path, max, maxMessage, nonZeroMessage) => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            if (nonZeroMessage && number === 0) {
                setValue(valObj, path, nonZeroMessage);
                return;
            }
            if (max && number > max) {
                setValue(valObj, path, maxMessage || "Invalid");
                return;
            }
            setValue(valObj, path, null);
        };

        var valObj = {};
        const organicfert = getValue(values, "manure.greenhouse.organic_fert_type");

        if (cY.crop === RICE) {
            isRequired("manure.greenhouse.organic_fert_type");
        }
        if ((cY.crop === RICE && organicfert === FARM_YARD_MANURE) || cY.crop !== RICE) {
            validateNumber("manure.greenhouse.manure_nitrogen_rate", 300, NITROGEN_MSG, NITROGEN_LOW_MSG);
            validateNumber("manure.waterquality.manure_phosphorus_rate", 150, PHOSPHORUS_MSG);
            isRequired("manure.energyuse.manure_type");
            isRequired("manure.waterquality.application_timing");
            isRequired("manure.waterquality.application_method_id");
        }
        if ((cY.crop === RICE && organicfert !== null) || cY.crop !== RICE) {
            isRequired("manure.energyuse.manure_rate");
        }

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(function(key) {
                    if (valObj[key] !== null && typeof valObj[key] === "object") {
                        // Also check child objects
                        Object.keys(valObj[key]).forEach(function(childKey) {
                            if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                                Object.keys(valObj[key][childKey]).forEach(function(childKey2) {
                                    valObj[key][childKey][childKey2] = null;
                                });
                            }
                        });
                    }
                });
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function(key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function(childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function(childKey2) {
                            if (valObj[key][childKey][childKey2] !== null) missing++;
                        });
                    }
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    warningValidator = (values, cY) => {
        const warnings = {};

        const organicfert = getValue(values, "manure.greenhouse.organic_fert_type");
        if ((cY.crop === RICE && organicfert === FARM_YARD_MANURE) || cY.crop !== RICE) {
            const path = "manure.greenhouse.manure_nitrogen_rate",
                amount = parseFloat(getValue(values, path));
            if (amount < 1) {
                setValue(warnings, path, NITROGEN_LOW_MSG);
            } else if (amount > 250) {
                setValue(warnings, path, NITROGEN_MSG);
            } else {
                setValue(warnings, path, null);
            }

            const ppath = "manure.waterquality.manure_phosphorus_rate",
                pamount = parseFloat(getValue(values, ppath));
            if (pamount > 100) {
                setValue(warnings, ppath, PHOSPHORUS_MSG);
            } else {
                setValue(warnings, ppath, null);
            }
        }

        return warnings;
    };

    render() {
        const {
            classes,
            fieldActivity,
            cropYear,
            manureTypes,
            manureAmounts,
            ormFieldActivityDelete,
            organicFertTypes,
            applicationTimes,
            applicationMethods,
            handleUnsavedFields
        } = this.props;
        const { amountLabel, deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, snackbarOpen } = this.state;

        const title = cropYear.crop === RICE ? "Organic Fertilizer Trip" : "Manure Fertilizer Trip";

        const getFormValue = name => (this.form ? this.form.getValue(name) : getValue(fieldActivity, name));

        const organicfert = getFormValue("manure.greenhouse.organic_fert_type"),
            hasAmount = cropYear.crop !== RICE || !!organicfert,
            hasType = cropYear.crop !== RICE || organicfert === FARM_YARD_MANURE;

        // Only run validation if users have been here before (missing set)
        const miss = getValue(fieldActivity, "extrainfo.missing");
        const isMissing = miss > 0;
        return (
            <Form
                getApi={el => (this.form = el)}
                key={fieldActivity.id}
                dontValidateOnMount={isMissing ? false : true}
                validateOnSubmit={isMissing ? false : true}
                defaultValues={fieldActivity}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values, cropYear, true)}
                validateWarning={values => this.warningValidator(values, cropYear)}
                onSubmitFailure={(errors, formApi) => {
                    // This only occurs when switching steppers from step 3
                    // The errorValidator is indeed returning null for every valobj however it still has the old errors in finishSubmission
                    // https://github.com/react-tools/react-form/blob/v2.16.3/src/components/ReduxForm.js
                    // Something to do with calling submitform from two parent components above? (3_FieldActivites -> Save Operations)
                    // Skip the validation and go straight to the orm update
                    this.updateFieldActivity(formApi.values);
                }}
                onSubmit={values => this.updateFieldActivity(values)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <WarningDialog
                            confirmAction={() => {
                                ormFieldActivityDelete(deleteDialogConfirmAction);
                                this.setState({ deleteDialogOpen: false });
                            }}
                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                            open={deleteDialogOpen}
                            title={`Delete ${title}`}
                            text={deleteDialogText}
                        />
                        <Grid container spacing={24}>
                            <Grid item xs={6} md={5} lg={4} xl={3}>
                                {cropYear.crop === RICE && (
                                    <Select
                                        field="manure.greenhouse.organic_fert_type"
                                        label="Organic fertilizer source"
                                        eventHandle={this.fertChange}
                                        help="Select the type of organic fertilizer added to the field: compost (decaying organic matter), animal manure, or green manure (live plants or plant residues)."
                                        options={MAKE_OPTIONS(organicFertTypes)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {hasType && (
                                    <Select
                                        eventHandle={value => this.typeChange(value)}
                                        field="manure.energyuse.manure_type"
                                        label="Manure/organic fertilizer form"
                                        help="Select the form of manure applied based on the moisture content."
                                        options={MAKE_OPTIONS(manureTypes)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6} md={5} lg={4} xl={3}>
                                {hasAmount && (
                                    <Select
                                        field="manure.energyuse.manure_rate"
                                        label="Manure/organic fertilizer amount"
                                        options={MAKE_OPTIONS(manureAmounts)}
                                        unitsVisual={amountLabel}
                                        help="Enter the amount of manure applied."
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                        </Grid>
                        {hasAmount && (
                            <>
                                <Grid container spacing={24}>
                                    <Grid item xs={6} md={5} lg={4} xl={3}>
                                        <Select
                                            field="manure.waterquality.application_timing"
                                            label="Manure/organic fertilizer application timing"
                                            help="Select the option that best represents the time during the crop year for your application of fertilizers and/or crop protectant chemicals. The option selected will determine if the system considers that the energy used in the pass over the field is captured in another operation (with planter, with irrigation) or should be separately calculated as a pass over the field independent of planting or irrigation operations."
                                            options={MAKE_OPTIONS(applicationTimes)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={5} lg={4} xl={3}>
                                        <Select
                                            field="manure.waterquality.application_method_id"
                                            label="Manure/organic fertilizer application method"
                                            help="Select the method used during this trip to apply manure or organic fertilizer."
                                            options={MAKE_OPTIONS(applicationMethods)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={6} md={5} lg={4} xl={3}>
                                        <TextField
                                            type="number"
                                            inputProps={{ step: 0.1 }}
                                            field="manure.greenhouse.manure_nitrogen_rate"
                                            label="Pounds of nitrogen applied per acre"
                                            units="lbs/ac"
                                            help="Select the option that best represents the pounds of nitrogen applied per acre based on nutrient test results."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={5} lg={4} xl={3}>
                                        <TextField
                                            type="number"
                                            inputProps={{ step: 0.1 }}
                                            field="manure.waterquality.manure_phosphorus_rate"
                                            label="Pounds of phosphorous applied per acre"
                                            units="lbs/ac"
                                            help="Select the option that best represents the pounds of phosphorous applied per acre based on nutrient test results."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        this.deleteFieldActivity(
                                            fieldActivity.id,
                                            ACTIVITY_TYPES[fieldActivity.type] + " " + fieldActivity.number
                                        )
                                    }>
                                    Delete Manure Fertilizer
                                </Button>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="primary"
                                    className={classes.floatRight}
                                    onClick={() => this.setState({ submitClicked: true })}>
                                    Save {title}
                                </Button>
                            </Grid>
                            <Snackbar
                                success
                                onClose={this.handleSnackbarClose}
                                open={snackbarOpen}
                                section="manure fertilizer operation"
                            />
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Manure = connect(state => ({
    manureTypes: allManureTypes(state),
    manureAmounts: allManureAmounts(state),
    organicFertTypes: allOrganicFertTypes(state),
    applicationTimes: allApplicationTimes(state),
    applicationMethods: allDominantApplicationMethods(state)
}))(Manure);

export default withStyles(styles)(Manure);
