import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitIcon from "@mui/icons-material/ExitToApp";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TimelineIcon from "@mui/icons-material/Timeline";
import ExportDataIcon from "@mui/icons-material/SystemUpdateAlt";
import Info from "@mui/icons-material/InfoOutlined";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "./GridWrapper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import withStyles from "@mui/styles/withStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";
import Button from "../common/ButtonWrapper";
import ImportFieldsAction from "../field/ImportFieldsDialog";

import logo from "../../color_01_horizontal.jpg";
import pack from "../../../package.json";

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAll } from "./orm";
import { dbFetch } from "../../api/fetch";

const styles = {
    appLogo: {
        width: 232,
        marginTop: -5,
        marginBottom: -5
    },
    divWrapper: {
        width: "100%",
        textAlign: "right"
    },
    inlineRight: {
        display: "inline-block",
        marginRight: "40px",
        marginTop: 22
    },
    inlineRightImpersonate: {
        display: "inline-block",
        marginRight: "40px"
    },
    impersonateColor: {
        fontWeight: 900
    },
    impersonateContainer: {
        marginTop: -8
    },
    headline: {
        textAlign: "right",
        zIndex: 2
    },
    zIndex: {
        zIndex: 1300
    },
    listItem: {
        backgroundColor: "#eaeaea",
        borderBottom: "1px solid #666666"
    },
    alertText: {
        color: "#666666"
    },
    topListItem: {
        borderBottom: "1px solid #666666"
    },
    topAlertText: {
        color: "#666666",
        fontSize: "21px",
        fontWeight: "200"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    updateReady: {
        color: "#fff",
        backgroundColor: "#4e4e4e",
        position: "absolute",
        width: "100%"
    },
    zIndexRefresh: {
        zIndex: 3
    }
};

class Header extends Component {
    constructor() {
        super();
        this.state = {
            userMenuAnchor: null
        };
    }

    handleUserMenu = event => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };

    logOut = () => {
        const { handleUnsavedFields, history, authLogout } = this.props;
        if (handleUnsavedFields) handleUnsavedFields(false);
        history.push("/");
        authLogout();
    };

    restoreAccess = () => {
        const { authState, reload, authStopImpersonate } = this.props;

        authStopImpersonate();

        return dbFetch("/impersonate/stop").then(data => {
            delete authState.user._impersonate;
            delete authState.user._impersonateEmail;
            delete authState.user._impersonateName;
            reload();
            this.props.history.push("/");
        });
    };

    render() {
        const { classes, authenticated, authState, navState, navSelectPage } = this.props;
        const { userMenuAnchor } = this.state;
        const userMenuOpen = Boolean(userMenuAnchor);

        if (!authState) return "...";

        var { user } = authState;
        if (!user) {
            user = {};
            if (authenticated) {
                // Page requires authentication
                if (!navState.storeReady) {
                    // Give store a chance to reload
                    user.first_name = "...";
                } else {
                    user.first_name = "Not Logged In!";
                }
            }
        }

        // real user is not a guest and not impersonating
        // if a user is a guest user.guest = true
        const isRealUser = !(user.guest ? user.guest : false) && !(user._impersonate ? user._impersonate : false);
        const isImpersonate = user._impersonate;

        const showUnused = false;
        const updateReady = navState.sw && navState.sw.update;

        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justifyContent="center">
                    <Grid item className={classes.zIndexRefresh}>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the Platform is available. Please save your work and refresh your
                            browser. &nbsp;
                            <Button variant="raised" onClick={() => window.location.reload()}>
                                Refresh Now
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return authenticated ? ( // Logged In
            <AppBar position="static" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar>
                    <Grid container spacing={24}>
                        <Grid item xs={3} className={classes.zIndexLogo}>
                            <Link onClick={() => navSelectPage("dashboard")} to="/dashboard">
                                <img src={logo} alt="Field to Market" className={classes.appLogo} />
                            </Link>
                        </Grid>
                        <Grid item xs={9} className={classes.headline}>
                            {!isImpersonate && (
                                <div>
                                    <IconButton
                                        aria-owns={userMenuOpen ? "menu-appbar" : null}
                                        aria-haspopup="true"
                                        onClick={this.handleUserMenu}
                                        color="inherit"
                                        size="large">
                                        <AccountCircle />
                                    </IconButton>
                                    <Typography
                                        style={{ fontSize: 18 }}
                                        variant="display2"
                                        color="inherit"
                                        className={classes.inlineRight}>
                                        Hi, {user.first_name}
                                    </Typography>
                                </div>
                            )}
                            {isImpersonate && (
                                <Grid container className={classes.impersonateContainer}>
                                    <Grid item xs={12}>
                                        <IconButton
                                            aria-owns={userMenuOpen ? "menu-appbar" : null}
                                            aria-haspopup="true"
                                            onClick={this.handleUserMenu}
                                            color="inherit"
                                            size="large">
                                            <AccountCircle />
                                        </IconButton>
                                        <Typography
                                            variant="display2"
                                            style={{ fontSize: 18 }}
                                            color="inherit"
                                            className={classes.inlineRightImpersonate}>
                                            Hi, {user.first_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="display2"
                                            style={{ fontSize: 18 }}
                                            color="inherit"
                                            className={classNames(
                                                classes.inlineRightImpersonate,
                                                classes.impersonateColor
                                            )}>
                                            Entering data on behalf of {user._impersonateName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Menu
                        id="menu-appbar"
                        anchorEl={userMenuAnchor}
                        classes={{
                            paper: classes.list
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        open={userMenuOpen}
                        onClose={this.handleUserMenuClose}
                        keepMounted>
                        <ListItem title="User Options" button className={classes.topListItem}>
                            <ListItemText
                                primary="User Options"
                                classes={{
                                    primary: classes.topAlertText
                                }}
                            />
                        </ListItem>
                        {showUnused && (
                            <ListItem title="Alerts" button className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <NotificationsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Alerts (0)"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {showUnused && (
                            <ListItem title="Settings" button className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Settings"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {isRealUser && (
                            <ListItem
                                title="Update Profile"
                                button
                                component={Link}
                                to="/updateuser"
                                className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Update Profile"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {(isRealUser || isImpersonate) && (
                            <ListItem
                                title="Export Data"
                                button
                                component={Link}
                                to="/exportdata"
                                className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <ExportDataIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Export Data"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {(isRealUser || isImpersonate) && <ImportFieldsAction onClick={this.handleUserMenuClose} />}
                        {isRealUser && (
                            <ListItem
                                title="Delegate Access"
                                button
                                component={Link}
                                to="/delegate"
                                className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <GroupAddIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delegate Access"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {isRealUser && (
                            <ListItem
                                title="Research Database"
                                button
                                component={Link}
                                to="/research"
                                className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <TimelineIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Research Database"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        {isImpersonate && (
                            <ListItem
                                title="Restore Access"
                                onClick={this.restoreAccess}
                                button
                                className={classes.listItem}>
                                <ListItemIcon className={classes.alertText}>
                                    <GroupAddIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Restore Access"
                                    classes={{
                                        primary: classes.alertText
                                    }}
                                />
                            </ListItem>
                        )}
                        <ListItem title="Log Out" button onClick={this.logOut} className={classes.listItem}>
                            <ListItemIcon className={classes.alertText}>
                                <ExitIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Log Out"
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                        <ListItem title={"Version " + pack.version} button className={classes.listItem}>
                            <ListItemIcon className={classes.alertText}>
                                <Info />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Version " + pack.version}
                                classes={{
                                    primary: classes.alertText
                                }}
                            />
                        </ListItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        ) : (
            // Public
            <AppBar position="static" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar>
                    <Link to="/">
                        <img src={logo} alt="Field to Market" className={classes.appLogo} />
                    </Link>
                    <div className={classes.divWrapper}>
                        <Typography variant="caption" color="inherit">
                            Version {pack.version}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

Header = connect(state => ({ authState: state.auth, navState: state.nav }), {
    ...authActions,
    ...navActions,
    reload: reloadAll
})(Header);

export default withStyles(styles)(withRouter(Header));
